<script lang="ts" setup>
import { boardKey } from "@/components/board/injectKeys";
import { cardKey } from "@/components/card/injectKeys";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { injectStrict } from "@/utils/vue";

import { isActionVisible } from "../../utils";
import ActionMenuItem from "../ActionMenuItem/ActionMenuItem.vue";
import ChangeArt from "./ChangeArt.vue";

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);
</script>

<template>
  <ActionMenuItem
    v-if="isActionVisible('art', card, board)"
    name="art"
    :tooltip-text="$t('cardAction.art')"
    :aria-label="$t('label.actionMenu.art')"
    aria-controls="change-art-dropdown"
  >
    <SvgIcon name="action-menu/art" width="20" height="20" />
    <template #dropdown><ChangeArt id="change-art-dropdown" /></template>
  </ActionMenuItem>
</template>
