import { clamp, round } from "lodash-es";

import { captureMessage } from "@/error/sentry";
import { isEqual } from "@/math/coordinates";
import CardFlag from "@/model/CardFlag";
import { Board } from "@/model/board";
import { CardMeta } from "@/model/card";
import { relativeCoord } from "@/model/coordinates";
import { LinkedObjective } from "@/model/objective";
import { useAlmItemTypeStore } from "@/store/almItemType";
import { useBoardsStore } from "@/store/boards";
import { CardEvent, useCardStore } from "@/store/card";
import { useStickyTypeStore } from "@/store/stickyType";
import { useTeamStore } from "@/store/team";
import { firstValue } from "@/utils/general";

import { mapReactions, mapUserId } from "./mapper/mapBackendData";
import { ServerSticky } from "./serverModel";

export function changeSticky(
  board: Board,
  id?: string,
  data?: Partial<ServerSticky>,
) {
  if (id && data) {
    const card = board.cards[id];
    if (!card) {
      return;
    }
    if (data.a_center) {
      const pos = relativeCoord(data.a_center[0], 1 - data.a_center[1]);
      if (!isEqual(card.meta.pos, pos)) {
        useBoardsStore().setCardPos({
          id,
          boardId: board.id,
          ...pos,
        });
      }
    }

    const cardStore = useCardStore();
    const exist = card.data;
    if (data.text !== undefined && data.text !== exist.text) {
      cardStore.setText({ id, text: data.text });
    }
    if (data.type_id !== undefined && data.type_id !== exist.type.id) {
      cardStore.setType({ id, type: data.type_id });
    }
    if (
      !card.data.type.priorities &&
      data.WSJF_value !== undefined &&
      data.WSJF_value !== exist.priority
    ) {
      cardStore.setPriority({ id, priority: data.WSJF_value });
    }
    if (
      card.data.type.priorities &&
      data.step_value !== undefined &&
      data.step_value !== exist.priority
    ) {
      cardStore.setPriority({ id, priority: data.step_value });
    }
    if (data.story_points !== undefined && data.story_points !== exist.points) {
      cardStore.setPoints({ id, points: data.story_points });
    }
    if (data.status !== undefined && data.status !== exist.status?.name) {
      cardStore.setStatus(board, id, data.status, {
        statusClass: data.status_class,
      });
    }
    if (data.team_id !== undefined) {
      const teamId = data.team_id ? "" + data.team_id : null;
      if (teamId !== exist.teamId) {
        cardStore.setTeam({ id, teamId });
      }
    }
    if (data.art_id !== undefined) {
      const artId = data.art_id?.toString() ?? null;
      if (artId !== exist.artId) {
        cardStore.setArt({ id, artId });
      }
    }
    if (
      data.iteration_number !== undefined &&
      // yes null or undefined
      // eslint-disable-next-line eqeqeq
      data.iteration_number != exist.iterationId
    ) {
      cardStore.setIteration({
        id,
        iterationId: data.iteration_number,
      });
    }
    if (
      data.alm_issue_id !== undefined &&
      "" + data.alm_issue_id !== exist.almId
    ) {
      cardStore.setAlmId({
        id,
        almId: "" + data.alm_issue_id,
        almIssueUrl: data.alm_issue_url,
      });
    }
    if (
      data.alm_source !== undefined &&
      data.alm_source !== exist.almSourceId
    ) {
      cardStore.setAlmSource({ id, almSourceId: data.alm_source });
    }
    if (
      data.sticky_group !== undefined &&
      data.sticky_group !== exist.groupId
    ) {
      cardStore.setGroup({ id, groupId: data.sticky_group });
    }
    if (
      data.flag_type !== undefined &&
      data.flag_type !== exist.flagType.toString()
    ) {
      cardStore.setFlag({
        id,
        flagType: CardFlag.fromFlagString(data.flag_type),
      });
    }
    if (data.risk !== undefined && data.risk !== exist.risk) {
      cardStore.setRisk({ id, risk: data.risk });
    }
    //TODO can data.precond_team_id really be null? Verify and remove the null logic if possible
    if (
      data.precond_team_id !== undefined &&
      isTeamIdDifferent(data.precond_team_id, exist.precondTeam?.id)
    ) {
      cardStore.setPrecondTeam({
        id,
        teamId:
          data.precond_team_id !== null ? "" + data.precond_team_id : undefined,
      });
    }
    if (data.precond_team !== undefined && !exist.precondTeam) {
      cardStore.setPrecondTeam({ id, teamName: data.precond_team });
    }
    //TODO can data.depend_team_id really be null? Verify and remove the null logic if possible
    if (
      data.depend_team_id !== undefined &&
      isTeamIdDifferent(data.depend_team_id, exist.dependTeam?.id)
    ) {
      cardStore.setDependTeam({
        id,
        teamId:
          data.depend_team_id !== null ? "" + data.depend_team_id : undefined,
      });
    }
    if (data.depend_team !== undefined && !exist.dependTeam) {
      cardStore.setDependTeam({ id, teamName: data.depend_team });
    }
    if (data.objectives !== undefined) {
      cardStore.setObjectives(id, validateObjectives(data.objectives));
    }
    if (data.reactions !== undefined) {
      cardStore.setReactions(id, mapReactions(data.reactions));
    }
    if (data.assignee !== undefined) {
      cardStore.setAssignee(
        id,
        mapUserId(data.assignee?.pip_id, data.assignee?.alm_id),
      );
    }
    if (data.reporter !== undefined) {
      cardStore.setReporter(
        id,
        mapUserId(data.reporter?.pip_id, data.reporter?.alm_id),
      );
    }
  }
}

export function addSticky(
  board: Board,
  data: ServerSticky & Pick<CardMeta, "shouldAnimate">,
) {
  const pos = data.a_center || [0.5, 0.5];
  const type = useStickyTypeStore().findStickyType(data.type_id);
  const almSourceId = data.alm_source ?? board.almSources?.[0]?.id;
  const teamId = data.team_id ? data.team_id.toString() : null;
  const artId = data.art_id ? data.art_id.toString() : null;

  const event: CardEvent = {
    id: data._id,
    groupId: data.sticky_group,
    text: data.text || "",
    status: useAlmItemTypeStore().calcStatus(
      data.status,
      type,
      teamId,
      artId,
      almSourceId,
      data.status_class,
    ),
    points: data.story_points || 0,
    boardId: board.id,
    type,
    teamId,
    iterationId: data.iteration_number,
    pos: relativeCoord(clamp(pos[0], 0, 1), 1 - clamp(pos[1], 0, 1)),
    zIndex: data.stack_pos,
    priority: type.priorities
      ? type.priorities[data.step_value!]?.value ||
        firstValue(type.priorities).value
      : round(data.WSJF_value || 0, 2),
    precondTeam: useTeamStore().findTeam({
      id: "" + data.precond_team_id,
      name: data.precond_team,
    }),
    dependTeam: useTeamStore().findTeam({
      id: "" + data.depend_team_id,
      name: data.depend_team,
    }),
    flagType: CardFlag.fromFlagString(data.flag_type),
    almId: data.alm_issue_id === undefined ? undefined : "" + data.alm_issue_id,
    almSourceId,
    almIssueUrl: data.alm_issue_url,
    risk: data.risk,
    shouldAnimate: data.shouldAnimate,
    objectives: validateObjectives(data.objectives),
    reactions: data.reactions ? mapReactions(data.reactions) : undefined,
    artId,
    assignee: mapUserId(data.assignee?.pip_id, data.assignee?.alm_id),
    reporter: mapUserId(data.reporter?.pip_id, data.reporter?.alm_id),
  };
  useCardStore().add(event);
}

function validateObjectives(
  objectives: ServerSticky["objectives"],
): LinkedObjective[] {
  if (!objectives) {
    return [];
  }
  return objectives.flatMap((objective) => {
    if (objective.id && objective.board_id) {
      return { id: objective.id, boardId: objective.board_id };
    }
    void captureMessage("Received invalid objective", { info: { objective } });
    return [];
  });
}

function isTeamIdDifferent(
  numericTeamId: number | null,
  stringTeamId: string | undefined,
) {
  return numericTeamId === null
    ? stringTeamId !== undefined
    : "" + numericTeamId !== stringTeamId;
}
