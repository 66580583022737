import { random } from "lodash-es";

import { internalActions } from "@/action/internalActions";
import { startServerTicks } from "@/components/utils/serverTime";
import { addBreadcrumb, captureException } from "@/error/sentry";
import { BoardType, IdMap, almTypeInfo } from "@/model/baseTypes";
import { Board, BoardData, isFrontendBoard } from "@/model/board";
import { Category, FlexType } from "@/model/flexboard";
import { Link, LinkType } from "@/model/link";
import { SearchResult } from "@/model/search";
import { AlmStickyType, StickyType } from "@/model/stickyType";
import { TimerData } from "@/model/timer";
import { useAlmItemTypeStore } from "@/store/almItemType";
import { useArtStore } from "@/store/art";
import { useBoardStore } from "@/store/board";
import { useBoardsStore } from "@/store/boards";
import { useConnectionStore } from "@/store/connection";
import { useFlexStore } from "@/store/flex";
import { useLicenseStore } from "@/store/license";
import { useLoadingStore } from "@/store/loading";
import { useServerSettingsStore } from "@/store/serverSettings";
import { useSessionStore } from "@/store/session";
import { useStatisticsStore } from "@/store/statistics";
import { isZombieType, useStickyTypeStore } from "@/store/stickyType";
import { useTeamStore } from "@/store/team";
import Scheduler from "@/utils/async/Scheduler";

import {
  BackendSession,
  SearchStickiesQuery,
  Subscription,
} from "./BackendSession";
import { DataError } from "./DataError";
import { sender } from "./Sender";
import { mapAlmItemType, mapAlmItemTypes } from "./mapper/mapAlmItemType";
import {
  mapAlmStickyTypes,
  mapArts,
  mapBoardIterations,
  mapCategories,
  mapFlexBackgrounds,
  mapFlexTypes,
  mapLinkTypes,
  mapLinks,
  mapObjective,
  mapScale,
  mapSearchResults,
  mapSessions,
  mapSettings,
  mapStickyType,
  mapTeams,
} from "./mapper/mapBackendData";
import { mapBoard, mapFlexBoard } from "./mapper/mapBoard";
import { mapStickyChanges } from "./mapper/mapStickyChange";
import {
  ServerAlmItemTypeMap,
  ServerFlexBoard,
  ServerInfo,
  ServerStatusWithData,
} from "./serverModel";
import {
  createBoardSubscriptions,
  loadStickies,
  sessionSubscriptions,
  setTimers,
  updateLinks,
} from "./subscriptionHandlers";

let backendSess: BackendSession;

let boardLoadScheduler: Scheduler | undefined;

export function reload() {
  useStatisticsStore().current.reconnects++;
  setSessionId(useSessionStore().session.current.id)
    .then(() => {
      internalActions.switchBoard();
      void Promise.all([
        subscribeSession(),
        loadBoard(useBoardStore().currentBoard()).then(() =>
          loadOtherBoards({ useForce: true }),
        ),
      ]);
    })
    .catch(() => document.location.reload());
}
export function getServerInfo(useHttp?: boolean): Promise<ServerInfo> {
  return backendSess.getServerInfo(useHttp);
}
export function getStatusWithData(
  sizeMB: number,
  useHttp?: boolean,
): Promise<ServerStatusWithData> {
  return backendSess.getStatusWithData(sizeMB, useHttp);
}

export function setSession(session: BackendSession) {
  backendSess = session;
}

export function initSession(session: BackendSession): Promise<void> {
  setSession(session);
  boardLoadScheduler = new Scheduler();
  void startServerTicks();
  setInterval(() => {
    if (backendSess) {
      useStatisticsStore().addNewEntry(backendSess.subscriptionCount());
    }
  }, 5000);
  void backendSess
    .getFlexBackgrounds()
    .then((backgrounds) =>
      useFlexStore().setFlexBackgrounds(mapFlexBackgrounds(backgrounds)),
    );
  // session_list.update returns also sessions we should not see -> use getSessions
  void backendSess.roomSubscribe("session_list.update", loadSessions);
  return backendSess.getLicense().then((license) => {
    const valid = license.expiryDate - Date.now() / 1000;
    useLicenseStore().setLicense({
      validDays: Math.round(valid / (60 * 60 * 24)),
      plan: license.plan,
      tracking: license.tracking,
      type: license.type,
    });
    if (!useLicenseStore().license.usable) {
      return Promise.resolve();
    }
    sender.backendSess = backendSess;
    return useSessionStore().session.list.length > 0
      ? Promise.resolve()
      : loadSessions();
  });
}

async function loadSessions() {
  const sessions = await backendSess.getSessions();
  const iterations = await Promise.all(
    sessions.map((session) => backendSess.getIterations(session.session_id)),
  );
  useSessionStore().setSessions(mapSessions(sessions, iterations));
}

export function loggedIn(): boolean {
  return !!backendSess;
}

export function closeSession(connectionOpen?: boolean) {
  boardLoadScheduler?.cancelAll();
  if (backendSess && connectionOpen) {
    backendSess
      .unsubscribe("room", connectionOpen)
      .catch((err) => void captureException(err));
  }
  (backendSess as unknown) = null;
}

export function setSessionId(id: string): Promise<void[]> {
  return useLoadingStore().load("loading.setSession", doSetSessionId(id));
}

function doSetSessionId(id: string): Promise<void[]> {
  if (id.length !== 24) {
    void captureException(new Error(`Invalid session id ${id}`));
  }
  if (!boardLoadScheduler) {
    throw new Error(
      "Session is not correctly initialized: Board Load Scheduler is not undefined",
    );
  }
  boardLoadScheduler.cancelAll();
  backendSess.unsubscribe("session");
  return backendSess
    .setSessionId(id)
    .then((isAlm) => {
      useSessionStore().userSync = "unsyncedSession";
      if (!isAlm) {
        useConnectionStore().alm = true;
        return Promise.resolve();
      }
      if (getAlmInfo().isUserMapped) {
        void backendSess.hasMappedAlmUser().then((mapped) => {
          useSessionStore().userSync = mapped ? "synced" : "unsynced";
        });
      }
      return backendSess.getAlmStatus().then((status) => {
        useConnectionStore().alm = status === "running";
      });
    })
    .then(() => Promise.all(loadSession()));
}

export async function loadSessionAlmStatus() {
  if (isAlmSync()) {
    const status = await backendSess.getAlmStatus();
    useSessionStore().setSessionAlmStatus(status);
  }
}

function loadSession(): Array<Promise<void>> {
  return [
    backendSess
      .getSettings()
      .then((settings) =>
        useServerSettingsStore().$patch(mapSettings(settings)),
      ),
    backendSess
      .getTeams()
      .then((teams) => useTeamStore().setTeams({ teams: mapTeams(teams) })),
    backendSess.getArts().then((arts) => useArtStore().setArts(mapArts(arts))),
    backendSess.getEvents().then((events) => setTimers(events)),
  ];
}

export async function loadBoard(board: Board, recursive = false) {
  return useLoadingStore().load("loading.board", doLoadBoard(board, recursive));
}

async function doLoadBoard(board: Board, recursive = false): Promise<unknown> {
  void addBreadcrumb("load", {
    message: "Loading board",
    data: {
      id: board?.id,
      type: board?.type,
      team: board.type === "team" ? board.team.name : undefined,
    },
  });

  if (!board.id) {
    return Promise.reject(
      new DataError("Board with empty id", {
        ...board,
        stickyTypes: [],
        cards: [],
      }),
    );
  }

  if (isFrontendBoard(board.type)) {
    return subscribeBoard(board);
  }

  const scale = await backendSess.getScale(board.id);
  board.cardSize = mapScale(scale);

  await Promise.all([loadBoardObjectives(board), loadBoardIterations(board)]);

  board.loaded = await loadStickies(backendSess, board);

  if (!recursive) {
    await subscribeBoard(board);
  }
}

export function loadOtherBoards({ useForce = false } = {}) {
  const boards = useBoardsStore().artBoardsToLoad(useForce);
  load(0);

  function load(index: number) {
    if (!boardLoadScheduler) {
      throw new Error(
        "Session is not correctly initialized: Board Load Scheduler is not undefined",
      );
    }
    if (index < boards.length) {
      boardLoadScheduler.schedule(
        () => loadBoard(boards[index], true).then(() => load(index + 1)),
        random(500, 1500),
      );
    } else {
      updateLinks(backendSess);
    }
  }
}

export function getAlmInfo() {
  return almTypeInfo[backendSess.getAlmType() || ""];
}

export function isAlmSync(): boolean {
  return !!backendSess?.getAlmType();
}

export function getAlmConnectionId() {
  return backendSess.getAlmConnectionId();
}

export interface SessionData {
  boards: IdMap<Board>;
  stickyTypes: StickyType[];
  linkTypes: LinkType[];
  links: Link[];
  almStickyTypes: IdMap<AlmStickyType>;
  flexTypes: FlexType[];
}

export function loadSessionData(): Promise<SessionData> {
  const boards: IdMap<Board> = {};
  let stickyTypes: StickyType[];
  let linkTypes: LinkType[];
  let links: Link[];
  let almStickyTypes: IdMap<AlmStickyType>;
  let flexTypes: FlexType[];

  function allBoards(func: (b: Board) => Promise<void>): Promise<void[]> {
    return Promise.all(Object.values(boards).map((board) => func(board)));
  }

  function teamBoards(func: (b: Board) => Promise<void>): Promise<void[]> {
    return Promise.all(
      Object.values(boards).map((board) => {
        if (board.type === "team") {
          return func(board);
        }
      }),
    );
  }

  return Promise.all([
    loadLinkTypes().then((types) => (linkTypes = mapLinkTypes(types))),
    loadLinks().then((serverLinks) => (links = mapLinks(serverLinks))),
    loadAlmStickyTypes().then(
      (types) => (almStickyTypes = mapAlmStickyTypes(types)),
    ),
    loadStickyTypes().then((types) => {
      stickyTypes = types;
      return getFlexTypes().then((types) => {
        flexTypes = types;
        return loadBoards().then((serverBoards) => {
          serverBoards.forEach((serverBoard) => {
            boards[serverBoard.board_id] = mapBoard(
              serverBoard,
              flexTypes,
              backendSess.almSource.bind(backendSess),
            );
          });
          return Promise.all([
            loadAllBoardIterations(),
            loadAllBoardObjectives(),
          ]);
        });
      });
    }),
  ])
    .then(() => subscribeSession())
    .then(() => ({
      boards,
      stickyTypes,
      linkTypes,
      links,
      almStickyTypes,
      flexTypes,
    }));

  function loadAllBoardIterations() {
    return useLoadingStore().load(
      "loading.iterations",
      teamBoards((board) => loadBoardIterations(board)),
    );
  }

  function loadAllBoardObjectives() {
    return useLoadingStore().load(
      "loading.objectives",
      allBoards((board) => loadBoardObjectives(board)),
    );
  }
}

function loadBoardIterations(board: Board): Promise<void> {
  if (board.type !== "team") {
    return Promise.resolve();
  }
  return backendSess.getBoardIterations(board.id).then((boardIterations) => {
    board.iterations = mapBoardIterations(boardIterations, board.iterations);
  });
}

function loadBoardObjectives(board: Board): Promise<void> {
  if (board.type !== "team" && board.type !== "program") {
    return Promise.resolve();
  }
  return backendSess.getObjectives(board.id).then((rankedObjectives) => {
    board.objectives = rankedObjectives.objectives.map(mapObjective);
    board.stretchObjectives = rankedObjectives.stretches.map(mapObjective);
  });
}

function loadLinkTypes() {
  return useLoadingStore().load(
    "loading.linkTypes",
    backendSess.getLinkTypes(),
  );
}

function loadLinks() {
  return useLoadingStore().load("loading.links", backendSess.getLinks());
}

function loadAlmStickyTypes() {
  return useLoadingStore().load(
    "loading.almTypes",
    backendSess.getAlmStickyTypes(),
  );
}

function loadStickyTypes() {
  return useLoadingStore().load("loading.stickyTypes", getStickyTypes());
}

function loadBoards() {
  return useLoadingStore().load("loading.boards", backendSess.getBoards());
}

function subscribeSession() {
  return Promise.all(sessionSubscriptions(backendSess));
}

export function subscribeBoard(board: Board): Promise<Subscription[]> {
  return createBoardSubscriptions(backendSess, board);
}

export function removeBoardSubscriptions() {
  return backendSess?.unsubscribe?.("board");
}

type AlmItemTypesCache = Map<string, Promise<ServerAlmItemTypeMap>>;

export async function getStickyTypes(): Promise<StickyType[]> {
  const serverStickyTypes = await backendSess.getStickyTypes();
  const mappedServerStickyTypes: StickyType[] = [];
  const almItemTypesCache: AlmItemTypesCache = new Map();
  while (serverStickyTypes.length) {
    const mappedStickyTypesChunk = await Promise.all(
      serverStickyTypes.splice(0, 10).map(async (serverStickyType) => {
        const loaded = useStickyTypeStore().findStickyType(serverStickyType.id);
        if (!isZombieType(loaded)) {
          return loaded;
        }
        let almItemTypes: ServerAlmItemTypeMap = {};
        const shouldLoadAlmItemTypes =
          serverStickyType.functionality === "workitem";
        if (shouldLoadAlmItemTypes) {
          almItemTypes = await getAlmItemTypes(
            serverStickyType.alm_type,
            serverStickyType.origin_board_type,
            almItemTypesCache,
          );
          useAlmItemTypeStore().almItemTypes[serverStickyType.id] =
            mapAlmItemTypes(almItemTypes);
        }
        return mapStickyType(serverStickyType);
      }),
    );
    mappedServerStickyTypes.push(...mappedStickyTypesChunk);
  }
  return mappedServerStickyTypes;
}

async function getFlexTypes(sessionId?: string) {
  const types = await backendSess.getFlexTypes(sessionId);
  return mapFlexTypes(types);
}

async function getAlmItemTypes(
  almType: string | null,
  boardType: BoardType,
  cache: AlmItemTypesCache,
): Promise<ServerAlmItemTypeMap> {
  const boardKeys = getBoardKeys(boardType);
  const safeAlmType = almType || "";

  const cacheKey = `${safeAlmType};${boardType};${boardKeys?.join("+")}`;
  let almItemType = cache.get(cacheKey);
  if (!almItemType) {
    almItemType = backendSess.getAlmItemType(safeAlmType, boardType, boardKeys);
    cache.set(cacheKey, almItemType);
  }
  return await almItemType;
}

function getBoardKeys(boardType: BoardType): string[] {
  switch (boardType) {
    case "backlog":
      return useArtStore().arts.map((art) => art.id);
    case "team":
      return useTeamStore().teams.map((team) => team.id);
  }
  return [];
}

export async function loadAlmItemTypeOfCard(almId: string) {
  const almItemType = await backendSess.getAlmItemTypeForSticky(almId);
  return mapAlmItemType(almItemType);
}

export async function loadFlexBoards(sessionId?: string): Promise<{
  flexTypes: FlexType[];
  flexBoards: Array<BoardData<"flex">>;
}> {
  const flexTypes = await getFlexTypes(sessionId);
  const boards = await backendSess.getBoards(sessionId);
  return {
    flexTypes,
    flexBoards: boards
      .filter((board) => board.board_type === "flex")
      .map((board) => mapFlexBoard(board as ServerFlexBoard, flexTypes)),
  };
}

export function loadCategories(): Promise<Category[]> {
  return backendSess.getCategories().then(mapCategories);
}

export function searchStickies(
  query: SearchStickiesQuery,
): Promise<SearchResult[]> {
  return backendSess.searchStickies(query).then(mapSearchResults);
}

export async function createTimer(
  data: TimerData,
  target: { boardId?: string; artId?: string },
) {
  return await backendSess.createTimerEvent(data, target);
}

export async function updateTimer(id: string, data: Partial<TimerData>) {
  await backendSess.updateTimerEvent(id, data);
}

export function deleteEvent(id: string) {
  return backendSess.deleteEvent(id);
}

export async function getBoardIdsOfGroupedStickies(stickyId: string) {
  return backendSess
    .getBoardIdsOfGroupedStickies(stickyId)
    .then((ids) => ids.board_ids);
}

export function getStickyChanges(id: string) {
  return backendSess.getStickyChanges(id).then(mapStickyChanges);
}

export function duplicateFlex(boardId: string, sessionId: string) {
  return backendSess.duplicateFlex(boardId, sessionId);
}

export function getBoardDiff(id: string, from: Date, to: Date) {
  return backendSess.getBoardDiff(id, from, to).then(mapStickyChanges);
}

export async function hasMappedAlmUser(id: string) {
  return await backendSess.hasMappedAlmUser(id);
}

window.addEventListener("offline", () => {
  useConnectionStore().setOffline();
});

window.addEventListener("online", () => {
  useConnectionStore().setOnline();
});
