<script setup lang="ts">
import { computed, ref } from "vue";

import { toggleActions } from "@/action/toggleActions";
import ActionButton from "@/components/ActionButton/ActionButton.vue";
import SearchItem from "@/components/Search/SearchItem.vue";
import { useActivityStore } from "@/store/activity";
import { useBoardStore } from "@/store/board";
import { useSearchMenuStore } from "@/store/searchMenu";
import { useWorkModeStore } from "@/store/workMode";

import MenuItemDelimiter from "../MenuItemDelimiter.vue";
import ExitItem from "./components/ExitItem.vue";
import MetricsItem from "./components/MetricsItem.vue";
import WorkModeItem from "./components/WorkModeItem.vue";

const searchValue = ref("");

const searchMenuStore = useSearchMenuStore();

const showMetrics = computed(
  () =>
    useBoardStore().board?.type === "program" ||
    useBoardStore().board?.type === "team",
);
const showExecutionMode = computed(
  () =>
    useWorkModeStore().showExecutionMode &&
    !searchMenuStore.isSearchSidebarOpen,
);
const showBoardDiff = computed(
  () => useBoardStore().board?.type !== "objectives",
);
const isBoardDiff = computed(() => !!useActivityStore().board);
</script>

<template>
  <div>
    <template v-if="!searchMenuStore.isSearchSidebarOpen">
      <ExitItem />
      <MenuItemDelimiter />
    </template>
    <SearchItem v-model="searchValue" />
    <template v-if="showBoardDiff">
      <MenuItemDelimiter />
      <ActionButton :action="toggleActions.showDiff" :activated="isBoardDiff" />
    </template>
    <template v-if="showMetrics">
      <MenuItemDelimiter />
      <MetricsItem />
    </template>
    <template v-if="showExecutionMode">
      <MenuItemDelimiter />
      <WorkModeItem />
    </template>
  </div>
</template>
