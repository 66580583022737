<script lang="ts" setup>
import { boardKey } from "@/components/board/injectKeys";
import { cardKey } from "@/components/card/injectKeys";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { injectStrict } from "@/utils/vue";

import * as actions from "../../actions";
import { isActionVisible } from "../../utils";
import ActionMenuItem from "../ActionMenuItem/ActionMenuItem.vue";
import ChangeProject from "./ChangeProject.vue";

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);
</script>

<template>
  <ActionMenuItem
    v-if="
      isActionVisible('almSource', card, board) &&
      !actions.cardWasCreatedOnDifferentBoard(card, board) &&
      board.almSources.length > 1
    "
    name="project"
    :tooltip-text="$t('cardAction.project')"
    :aria-label="$t('label.actionMenu.project')"
    aria-controls="change-project-dropdown"
  >
    <SvgIcon name="action-menu/project" width="20" height="20" />
    <template #dropdown>
      <ChangeProject id="change-project-dropdown" trigger="action-menu" />
    </template>
  </ActionMenuItem>
</template>
