<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import IconButton from "@/components/ui/IconButton/IconButton.vue";
import { LinkableObjective } from "@/model/link";

const props = defineProps<{ linkableObjective: LinkableObjective }>();

const rowLabel = computed(() => {
  const key = props.linkableObjective.linked
    ? /*$t*/ "label.linkableObjectiveRow.linked"
    : /*$t*/ "label.linkableObjectiveRow";

  return useI18n().t(key, { text: props.linkableObjective.text });
});
</script>

<template>
  <div class="linkable-obj-row" :aria-label="rowLabel">
    <div>
      <div class="linkable-obj-text">{{ linkableObjective.text }}</div>
      <div class="linkable-obj-details">
        <span>{{ $t("linkModal.bv", { bv: linkableObjective.bv }) }}</span>
      </div>
    </div>
    <IconButton
      v-if="linkableObjective.linked"
      variant="filled"
      color="primary"
      icon="action-menu/unlink"
      :aria-label="
        $t('linkableObjectiveRow.removeLink', linkableObjective.text)
      "
      tabindex="0"
      :tooltip="$t('linkModal.removeLink')"
    />
    <IconButton
      v-else
      icon="action-menu/link"
      :aria-label="$t('linkableObjectiveRow.addLink', linkableObjective.text)"
      tabindex="0"
      :tooltip="$t('linkModal.addLink')"
    />
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/variables";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.linkable-obj-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
  padding: 6.5px 0;
  cursor: pointer;
  word-break: break-word;

  .linkable-obj-text {
    font-size: font.$size-normal;
  }

  .linkable-obj-details {
    display: flex;
    color: colors-old.$text-secondary-color;
    white-space: nowrap;
    align-items: center;
    margin-top: 2px;
    font-size: font.$size-small;

    div:not(:last-child)::after,
    span:not(:last-child)::after {
      content: "\00a0|\00a0";
      color: colors-old.$divider-color;
      padding: 0.125rem;
    }
  }
}
</style>
