<template>
  <div class="load-capacity-chart">
    <h2 class="title">
      {{ title }}
    </h2>
    <div class="subtitle" :class="{ 'no-capacity': !capacity }">
      {{ $t("general.percent", { value: loadPercentage.toFixed(0) }) }}
    </div>
    <div class="chart">
      <div class="bar-container">
        <LoadCapacityChartBar
          role="presentation"
          :value="loadHeight"
          :fill="loadColor"
          :stroke="!!capacity"
        />
        <div class="bar-value">
          {{ round(load, 1) }}
        </div>
        <div class="bar-title">{{ $t("loadCapacityChart.load") }}</div>
      </div>
      <div class="bar-container">
        <LoadCapacityChartBar
          role="presentation"
          :value="capacityHeight"
          :pattern="!capacity"
          :stroke="!!capacity"
        />
        <div class="bar-value">
          {{ round(capacity, 0) }}
        </div>
        <div class="bar-title">{{ $t("general.capacity") }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { round } from "lodash-es";
import { Options as Component, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { loadState } from "@/components/utils/capacity";
import { InfoLevel, infoLevelInfo } from "@/model/baseTypes";

import LoadCapacityChartBar from "./LoadCapacityChartBar.vue";

@Component({ components: { LoadCapacityChartBar } })
export default class LoadCapacityChart extends Vue {
  @Prop(Number) readonly capacity!: number;
  @Prop(Number) readonly load!: number;
  @Prop(Number) readonly warningThreshold!: number;
  @Prop(String) readonly title!: string;
  round = round;

  get loadPercentage(): number {
    if (!this.capacity) {
      return 0;
    }
    return (this.load / this.capacity) * 100;
  }

  get loadColor() {
    return infoLevelInfo[this.loadState].color;
  }

  get loadState(): InfoLevel {
    return loadState(this.load, this.capacity);
  }

  get loadHeight() {
    if (this.load > this.capacity || !this.capacity) {
      return 1;
    }
    return this.load / this.capacity;
  }

  get capacityHeight() {
    if (this.load < this.capacity || !this.capacity) {
      return 1;
    }
    return this.capacity / this.load;
  }
}
</script>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/a11y" as colors-a11y;
@use "@/styles/variables/colors";

.load-capacity-chart {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 48%;

  .title {
    font-size: 20px;
    margin-bottom: 8px;
  }

  .subtitle {
    margin-bottom: 20px;
    font-weight: font.$weight-extra-bold;
    font-size: 20px;
  }

  .bar-value {
    font-weight: font.$weight-bold;
    font-size: 21px;
  }

  .bar-title {
    font-size: 14px;
    color: colors-old.$text-secondary-color;
  }

  .chart {
    display: flex;
    justify-content: center;
    flex: 1 1 10px;
    gap: 10px;
  }

  .bar-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-width: 60px;
  }

  .no-capacity {
    color: colors-a11y.$bar-chart-grey;
  }

  .grey-stroke {
    stroke: colors-a11y.$bar-chart-grey;
  }
}
</style>
