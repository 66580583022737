<script
  setup
  lang="ts"
  generic="T extends { id: string | number }, E extends T | T[]"
>
import { isArray } from "lodash-es";
import { v4 as uuid } from "uuid";
import { computed } from "vue";

import BaseList from "@/components/ui/BaseList/BaseList.vue";
import BaseListFooter from "@/components/ui/BaseList/components/BaseListFooter/BaseListFooter.vue";
import BaseListItems from "@/components/ui/BaseList/components/BaseListItems/BaseListItems.vue";
import BaseListTitle from "@/components/ui/BaseList/components/BaseListTitle/BaseListTitle.vue";
import ListItem, {
  ListItemVariant,
} from "@/components/ui/ListItem/ListItem.vue";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { terminateEvent } from "@/utils/dom/dom";

const props = defineProps<{
  header?: string;
  values: T[];
  modelValue?: E | null;
  variant?: ListItemVariant;
  scrollable?: boolean;
  disabled?: boolean;
  ariaLabelledby?: string;
}>();

const emit = defineEmits<(e: "update:modelValue", value: E) => void>();

const isMulti = isArray(props.modelValue);
const isClearable = computed(() => isMulti && props.modelValue.length > 0);

const titleId = uuid();

function select(value: T, event: PointerEvent) {
  if (props.disabled) return;

  emit("update:modelValue", (isMulti ? newValue() : value) as E);

  function newValue() {
    const modelValues = props.modelValue as T[];
    terminateEvent(event);
    const pos = modelValues.findIndex((val) => val.id === value.id);
    return pos >= 0
      ? [...modelValues.slice(0, pos), ...modelValues.slice(pos + 1)]
      : [...modelValues, value];
  }
}

function clear() {
  if (props.disabled) return;
  emit("update:modelValue", [] as unknown as E);
}

function isActive(value: T) {
  return !isMulti && value.id === props.modelValue?.id;
}

function isSelected(value: T) {
  return isMulti && props.modelValue.some((val) => val.id === value.id);
}
</script>

<template>
  <BaseList>
    <BaseListTitle v-if="header" :id="titleId">{{ header }}</BaseListTitle>
    <BaseListItems
      :class="{ 'scrollable-container scrollable': scrollable }"
      :aria-labelledby="
        props.ariaLabelledby || (props.header && titleId) || undefined
      "
    >
      <ListItem
        v-for="value in values"
        :key="value.id"
        role="option"
        :active="isActive(value)"
        :variant="variant"
        :disabled="disabled"
        class="base-list-item"
        @click="select(value, $event)"
      >
        <slot :value="value" :active="isActive(value)" />
        <div class="check-container">
          <SvgIcon v-if="isSelected(value)" name="general/check" />
        </div>
      </ListItem>
    </BaseListItems>
    <template v-if="isMulti">
      <BaseListFooter :disabled="!isClearable || disabled" @click="clear" />
    </template>
  </BaseList>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/mixins/utils";

.check-container {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  color: colors-old.$primary-color;
}

.clear {
  font-size: 12px;
  color: colors-old.$alt-primary-color;
  justify-content: center;
}

.scrollable-container {
  position: relative;
  max-height: 200px;
  overflow-y: scroll;

  @include utils.hide-scrollbar;
}
</style>
