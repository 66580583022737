<script lang="ts" setup>
import ScreenReaderMessage from "@/components/a11y/ScreenReaderMessage.vue";
import BaseInput from "@/components/ui/BaseInput/BaseInput.vue";

defineOptions({
  inheritAttrs: false,
});

const modelValue = defineModel<string>({ required: true });

interface Props {
  iconPosition?: "before" | "after"; // Position of the search icon
  iconTitle?: string; // Used as label for the input, unless there's a visual label (attribute 'label')
  autofocus?: boolean;
  placeholder?: string;
  resultsCount?: number; // For screen readers
}

defineProps<Props>();
</script>

<template>
  <BaseInput
    v-model="modelValue"
    v-autofocus="!!autofocus"
    v-bind="$attrs"
    control-type="text"
    :icon-after="iconPosition === 'after' ? 'search/search' : undefined"
    :icon-after-title="iconTitle"
    :icon-before="iconPosition === 'before' ? 'search/search' : undefined"
    :icon-before-title="iconTitle"
    :placeholder="placeholder || $t('action.search')"
  />
  <ScreenReaderMessage v-if="typeof resultsCount === 'number'">
    {{ $t("searchResultsTs.results", { count: resultsCount }) }}
  </ScreenReaderMessage>
</template>
