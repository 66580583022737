import { noop } from "lodash-es";
import { nextTick } from "vue";

import { isAlmSync } from "@/backend/Backend";
import { sender } from "@/backend/Sender";
import CardCreationMenu from "@/components/card/CardCreationMenu.vue";
import { namedKey } from "@/components/utils/Shortcuts";
import { findFreePosition } from "@/components/utils/layout";
import { relativeToWindow, windowToRelative } from "@/math/coordinate-systems";
import { minus, plus, times } from "@/math/coordinates";
import { sendStickyNoteAction } from "@/mixins/EventBusUser";
import CardFlag from "@/model/CardFlag";
import { AlmSourceId, RiskType } from "@/model/baseTypes";
import { Board } from "@/model/board";
import { BoardCard, Card, Reaction } from "@/model/card";
import {
  RelativeCoordinate,
  WindowCoordinate,
  centerCoord,
  scrollCoord,
  windowCoord,
} from "@/model/coordinates";
import { StickyType } from "@/model/stickyType";
import { AuthUser } from "@/model/user";
import { useActivityStore } from "@/store/activity";
import { useAlmItemTypeStore } from "@/store/almItemType";
import { useBoardStore } from "@/store/board";
import { useBoardsStore } from "@/store/boards";
import { CardEvent, useCardStore } from "@/store/card";
import { useClientSettingsStore } from "@/store/clientSettings";
import { useContextMenuStore } from "@/store/contextMenu";
import { useSelectionStore } from "@/store/selection";
import { NO_TEAM_ID, useTeamStore } from "@/store/team";
import { useUserStore } from "@/store/user";
import {
  StickyCreateTrigger,
  stickyNoteCreated,
} from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { removeNonPrintable } from "@/utils/general";

import { action, defineActions, getActionTrigger } from "./actions";
import { boardActions } from "./boardActions";
import { linkActions } from "./linkActions";

export const cardActions = defineActions("card", {
  add: action(startAdd, { shortcut: namedKey("KeyN") }),
  togglePalette: action(noop, {
    icon: "menu-bottom/add-sticky",
    name: /*$t*/ "action.addSticky",
    shortcut: namedKey("KeyN"),
  }),
  copy: action(noop),
  paste: action(startAdd),
  pasteMultiple: action(addMultiple, {
    name: /*$t*/ "action.paste",
  }),
  pasteText: action(startAdd),
  duplicate: action(
    async (trigger?: StickyCreateTrigger) => {
      const cards = useBoardStore().selectedOrActiveCards;
      const cardSize = useBoardStore().currentBoard().cardSize;
      const step = times(cardSize, 0.4);
      if (cards.length === 1) {
        startAdd(
          cardCreateProps(cards[0], step, { findFreePos: true }),
          true,
          trigger,
        );
      } else {
        const ids = await addMultiple(
          cards.map((card) => cardCreateProps(card, step)),
        );
        useSelectionStore().duplicated(ids);
      }
    },
    {
      name: /*$t*/ "action.duplicate",
      shortcut: namedKey("KeyD", { modifiers: ["altCtrl"] }),
    },
  ),
  delete: action((id: string, boardId: string) => {
    if (useCardStore().delete({ id, boardId })) {
      sender.deleteCard(boardId, id);
    }
  }),

  mirror: action((id: string, teamId: string | null, board: Board) => {
    if (!teamId && board.type === "team") {
      cardActions.setTeam("internal", id, board.team.id);
    }
    return sender.mirror(id, board.id);
  }),

  move: action((id: string, teamId: string) => sender.move(id, teamId)),

  toProgram: action((id: string) =>
    sender.mirror(id, useBoardsStore().boardByType("program").id),
  ),

  toSolution: action((id: string) =>
    sender.mirror(id, useBoardsStore().boardByType("solution").id),
  ),

  toRisk: action((id: string, teamId: string) => {
    const boardId = useBoardsStore().boardByType("risk", {
      artId: useTeamStore().team?.current?.artId,
    }).id;
    const card = useCardStore().cards[id];
    sender.toRisk(id, boardId, card.text, card.type.id, teamId);
  }),

  toggleReaction: action(async (cardId: string, reaction: Reaction) => {
    if (useCardStore().hasCurrentUserReaction(cardId, reaction)) {
      await sender.removeReaction(cardId, reaction);
    } else {
      await sender.addReaction(cardId, reaction);
    }
  }),

  startAlter: action((id: string) =>
    sender.startAlterCard(useBoardStore().currentBoard().id, id),
  ),

  stopAlter: action((id: string) => {
    const card = useBoardStore().currentBoard().cards[id];
    // could have just been deleted
    // don't stop alter when card is selected
    if (card && !useBoardStore().board?.selected[id]) {
      sender.stopAlterCard(useBoardStore().currentBoard().id, id, {
        type: card.data.type,
        x: card.meta.pos.x,
        y: card.meta.pos.y,
      });
    }
  }),

  setAlmSource: action((id: string, almSourceId: AlmSourceId | null) => {
    useCardStore().setAlmSource({ id, almSourceId });
    sender.alterCard(useBoardStore().currentBoard().id, id, { almSourceId });
  }),

  setPriority: action((id: string, priority: number, priorities?: boolean) => {
    const { use, priority: prio } = useCardStore().setPriority({
      id,
      priority,
      priorities,
    });
    alterCard(
      useBoardStore().currentBoard().id,
      id,
      { id, priority: prio },
      use,
    );
  }),

  setPoints: action((id: string, points: number) => {
    useCardStore().setPoints({ id, points });
    alterCard(useBoardStore().currentBoard().id, id, { points });
  }),

  setFlag: action((id: string, flagType: CardFlag) => {
    useCardStore().setFlag({ id, flagType });
    alterCard(useBoardStore().currentBoard().id, id, { flagType });
  }),

  setRisk: action((id: string, risk: RiskType) => {
    useCardStore().setRisk({ id, risk });
    alterCard(useBoardStore().currentBoard().id, id, { risk });
  }),

  setText: action(
    (id: string, text: string) => {
      useCardStore().setText({ id, text });
      sender.alterCard(useBoardStore().currentBoard().id, id, { text });
    },
    {
      history: {
        merge: true,
        saveState: (id) => ({
          id,
          text: useCardStore().cards[id].text,
        }),
      },
    },
  ),

  setIteration: action((id: string, iterationId: number | null) => {
    if (useCardStore().setIteration({ id, iterationId })) {
      sender.alterCard(useBoardStore().currentBoard().id, id, { iterationId });
    }
  }),

  setTeam: action((cardId: string, teamId: string | null) => {
    useCardStore().setTeam({ id: cardId, teamId });
    alterCard(useBoardStore().currentBoard().id, cardId, { teamId });
  }),

  setTeamAction: action((id: string, teamId: string) => {
    if (teamId === NO_TEAM_ID) {
      cardActions.setTeam("internal", id, null);
      cardActions.setIteration("internal", id, null);
    } else {
      cardActions.setTeam("internal", id, teamId);
    }
  }),

  setDepend: action((id: string, teamId: string) => {
    const { board, card } = useCardStore().setDependAction({ id, teamId });
    sender.mirror(id, board.id, {
      flagType: card.flagType,
      precondTeam: card.precondTeam,
      dependTeam: card.dependTeam,
    });
    alterCard(useBoardStore().currentBoard().id, id, {
      flagType: card.flagType,
      precondTeam: card.precondTeam,
      dependTeam: card.dependTeam,
    });
  }),

  setArt: action((id: string, artId: string | null) => {
    useCardStore().setArt({ id, artId });
    alterCard(useBoardStore().currentBoard().id, id, { artId });
  }),

  setAssignee: action((id: string, assignee: AuthUser | null) => {
    useCardStore().setAssignee(id, assignee);
    alterCard(useBoardStore().currentBoard().id, id, { assignee });
  }),

  setType: action((id: string, type: string, boardId?: string) => {
    const { board, props, priorities } = useCardStore().setType({
      id,
      type,
      boardId,
    });
    sender.alterCard(board.id, id, props, priorities);
  }),

  setStatus: action(
    (board: Board, cardId: string, statusName: string, transition?: string) => {
      const status = useCardStore().setStatus(board, cardId, statusName, {
        transition,
      });
      alterCard(board.id, cardId, { status, transition });
    },
  ),

  // the actual moving is done continuously by mouse dragging
  // we need this here to track the action and to save the state for undo
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPosition: action((_: string[]) => {}, {
    history: {
      saveState: (ids) =>
        ids.map((id) => ({
          id,
          pos: { ...useBoardStore().currentBoard().cards[id].meta.pos },
        })),
    },
  }),

  toggleActivity: action((id: string) => useActivityStore().toggleCard(id)),
});

export interface CardCreateProps {
  type: StickyType;
  pos: WindowCoordinate;
  text?: string;
  almSourceId?: AlmSourceId;
  onClose?: () => void;
}

async function startAdd(
  props: Partial<CardCreateProps> = {},
  focus = true,
  trigger?: StickyCreateTrigger, // for analytics
) {
  if (!useUserStore().isAllowed("edit")) return;

  const defaultStickyType = getDefaultStickyNoteType();
  props.type ??= defaultStickyType;

  if (!props.pos) {
    const activeElement = document.activeElement as HTMLElement;

    // If the user is focused on a board element, create the new sticky at that location
    if (activeElement?.closest(".board")) {
      const { left, top, width, height } =
        activeElement.getBoundingClientRect();
      const f = activeElement.classList.contains("sticky-note") ? 1.5 : 2; // Avoid creating a sticky note on top of another one

      props.pos = windowCoord(left + width / f, top + height / f);
    } else {
      // Otherwise, create the new sticky in the center of the board
      props.pos = centerCoord();
    }
  }

  const actionTrigger = trigger ?? getActionTrigger(startAdd);

  if (props.type && isAlmSourceOk(props)) {
    trackCreateStickyNoteEvent(props.type, !!defaultStickyType, actionTrigger);
    return add(props as CardCreateProps, focus);
  }

  useContextMenuStore().open(
    CardCreationMenu,
    { position: props.pos, card: props },
    {
      select: (type: StickyType, almSourceId?: AlmSourceId) => {
        trackCreateStickyNoteEvent(type, !!defaultStickyType, actionTrigger);
        add({ ...props, type, almSourceId } as CardCreateProps, focus);
      },
      close: () => props.onClose?.(),
    },
  );
}

function trackCreateStickyNoteEvent(
  type: StickyType,
  defaultStickyType: boolean,
  trigger?: StickyCreateTrigger,
) {
  trackEvent(
    stickyNoteCreated(
      type.functionality,
      type.origin,
      trigger,
      defaultStickyType,
    ),
  );
}

function isAlmSourceOk(props: Partial<CardCreateProps>) {
  const { type, pos } = props;
  if (!pos || !type) return false;
  const originBoard = useBoardsStore().stickyTypeOriginBoard(type, pos);
  return props.almSourceId !== undefined || originBoard.almSources.length <= 1;
}

async function add(props: CardCreateProps, focus = true) {
  const board = useBoardStore().currentBoard();
  props = sanitize(props);
  const originBoard = useBoardsStore().stickyTypeOriginBoard(
    props.type,
    props.pos,
  );
  const almSourceId = props.almSourceId ?? originBoard.almSources[0]?.id;
  const status = useAlmItemTypeStore().calcStatus(
    undefined,
    props.type,
    board.type === "team" ? board.team.id : undefined,
    board.artId,
    almSourceId,
  );
  const pos = windowToRelative(props.pos);
  const ev: CardEvent = {
    id: "",
    boardId: board.id,
    pos,
    text: props.text,
    type: props.type,
    status,
    priority: 0,
    iterationId: null,
    teamId: null,
    zIndex: 0,
    flagType: CardFlag.emptyFlag(),
    objectives: [],
    almSourceId,
    artId: board.artId || null,
    ...useBoardStore().positionalCardProperties(pos),
  };
  ev.id = await sender.addCard(board.id, { ...ev, ...ev.pos });
  useCardStore().add(ev);
  // Make sure the new card matches the current faded state (eg. when a card is pinned)
  linkActions.updateCardLinkedMarks("internal");

  if (focus) {
    await nextTick();
    sendStickyNoteAction(ev.id, {
      action: "enlarge",
      focus: true,
      trigger: "after-create",
    });
  }
  return ev.id;
}

function sanitize(event: CardCreateProps): CardCreateProps {
  const newlines = !isAlmSync();
  if (event.text && !newlines) {
    event.text = removeNonPrintable(event.text);
  }
  return event;
}

async function addMultiple(cards: CardCreateProps[]) {
  boardActions.clearCardSelection("internal");
  return await Promise.all(
    cards.map(async (card) => {
      const id = await add(card, false);
      useBoardStore().selectCard(id);
      return id;
    }),
  );
}

function cardCreateProps(
  card: BoardCard,
  step: RelativeCoordinate,
  options?: { findFreePos?: boolean },
): CardCreateProps {
  return {
    text: card.data.text,
    type: card.data.type,
    pos: minus(
      relativeToWindow(
        options?.findFreePos
          ? findFreePosition(card.meta.pos, step)
          : plus(card.meta.pos, step),
      ),
      scrollCoord(),
    ),
    almSourceId: card.data.almSourceId ?? undefined,
  };
}

function getDefaultStickyNoteType() {
  const id = useClientSettingsStore().defaultStickyTypeId;
  return useBoardStore().creatableStickyTypes.find((type) => type.id === id);
}

/**
 * If the card is currently locked by the user (editing or selected),
 * send alterCard. Otherwise, send stopAlterCard.
 *
 * This ensures that the changes are persisted, but the card isn't unlocked accidentally
 */
export function alterCard(
  boardId: string,
  id: string,
  props: Partial<Card & RelativeCoordinate>,
  priorities?: boolean,
) {
  if (useBoardStore().isStickyIdSelectedOrActive(id)) {
    sender.alterCard(boardId, id, props, priorities);
  } else {
    sender.stopAlterCard(boardId, id, props);
  }
}
