<script setup lang="ts">
import { round } from "lodash-es";
import { computed, getCurrentInstance, ref } from "vue";
import { useI18n } from "vue-i18n";

import { boardActions } from "@/action/boardActions";
import PieChart from "@/components/PieChart/PieChart.vue";
import { iterationStatusDistribution } from "@/components/StatusDistribution/StatusDistribution";
import StatusDistribution from "@/components/StatusDistribution/StatusDistribution.vue";
import CardsSkipButton from "@/components/a11y/CardsSkipButton.vue";
import BoardNumberInput from "@/components/input/BoardNumberInput.vue";
import KebabMenu from "@/components/menu/BoardContextMenu/KebabMenu.vue";
import {
  CapacityAllocation,
  calcCapacityAllocation,
} from "@/components/modal/metrics/metrics";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import { loadState } from "@/components/utils/capacity";
import { fakeZoom } from "@/model/Settings";
import { Iteration } from "@/model/session";
import { useBoardStore } from "@/store/board";
import { useBoardsStore } from "@/store/boards";
import { useSessionStore } from "@/store/session";
import { useWorkModeStore } from "@/store/workMode";
import { useZoomStore } from "@/store/zoom";
import { plusDays } from "@/utils/date";
import { formatShortDate } from "@/utils/dateFormat";

import FluidBoard from "../FluidBoard";
import IterationFlag from "./IterationFlag.vue";

const props = defineProps<{
  index: number;
  readOnly?: boolean;
}>();
const emit = defineEmits<{ metrics: [] }>();

const parent = getCurrentInstance()!.parent as unknown as FluidBoard;

const showMetrics = ref(false);
const capacityAllocation = ref<CapacityAllocation>({ total: 0, values: [] });

const { t } = useI18n();

const flagType = computed(() => {
  return loadState(teamIteration.value.load, teamIteration.value.velocity);
});

const zoom = computed(() => useZoomStore().factor);
const board = computed(() => useBoardsStore().boardByType("team"));
const cards = computed(() =>
  Object.values(board.value.cards).map((boardCard) => boardCard.data),
);
const isExecutionMode = computed(() => useWorkModeStore().isExecutionMode);
const distribution = computed(() =>
  iterationStatusDistribution(cards.value, props.index),
);
const iteration = computed(() => useSessionStore().iterations[props.index]);
const teamIteration = computed(
  () => useBoardsStore().boardByType("team").iterations[props.index],
);
const iterationNow = computed(() =>
  useSessionStore().iterationProgress(new Date(), iteration.value),
);
const isCurrentIteration = computed(
  () =>
    iterationNow.value.iterationsPassed > 0 &&
    iterationNow.value.iterationsPassed < 1,
);

function metrics(show: boolean) {
  showMetrics.value = false;
  if (show) {
    capacityAllocation.value = calcCapacityAllocation(
      [board.value],
      props.index,
    );
    if (capacityAllocation.value.total > 0) {
      showMetrics.value = true;
    }
  }
}

function changeVelocity(v: number) {
  boardActions.setVelocity(
    "board",
    useBoardStore().currentBoard().id,
    props.index,
    v,
  );
}

function dates(iter: Iteration) {
  return t("date.range", {
    from: formatShortDate(iter.start),
    to: formatShortDate(plusDays(iter.end, -1)),
  });
}
</script>

<!-- eslint-disable vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions-->
<template>
  <section
    class="team-iteration back-color"
    :class="flagType"
    :aria-label="$t('general.namedIteration', { name: iteration.name })"
    tabindex="0"
    @click="$el?.blur"
  >
    <!-- eslint-enable vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions-->
    <div class="head">
      <header class="row back-color">
        <h2 :title="iteration.name" class="iteration-name">
          {{ iteration.name }}
        </h2>
        <span class="iteration-date">
          {{ dates(iteration) }}
        </span>
      </header>
      <CardsSkipButton />
      <span class="row back-color">
        <div class="iteration-info">
          <IterationFlag :type="flagType" class="flag-icon" />
          <div class="metric-wrap">
            <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
            <label :for="`capacity-${iteration.id}`" class="metric-title">
              {{ $t("general.capacity") }}
            </label>
            <span class="metric-input-wrap">
              <BoardNumberInput
                :id="`capacity-${iteration.id}`"
                :readonly="props.readOnly"
                class="metric-input"
                :value="teamIteration.velocity"
                :maxlength="3"
                @input="changeVelocity"
                @dblclick.stop
              />
            </span>
          </div>
          <div class="metric-wrap">
            <span class="metric-title" aria-hidden="true">
              {{ $t("loadCapacityChart.load") }}
            </span>
            <div class="screen-reader-only">
              {{
                $t("label.iteration.load", {
                  value: round(teamIteration.load, 1),
                })
              }}
            </div>
            <span class="metric-input-wrap">
              <BoardNumberInput
                class="metric-input"
                readonly
                :maxlength="4"
                :value="round(teamIteration.load, 1)"
                tabindex="-1"
                aria-hidden="true"
              />
            </span>
          </div>
        </div>
        <div class="actions">
          <IconButton
            icon="menu-top/metrics"
            class="stat-icon iteration-button"
            :tooltip="$t('label.iteration.metrics', { name: iteration.name })"
            @click="emit('metrics')"
            @pointerenter="metrics(true)"
            @pointerleave="metrics(false)"
          />
          <KebabMenu
            :board="parent"
            mode="iteration"
            class="iteration-button"
            :tooltip="$t('label.iteration.kebabMenu', { name: iteration.name })"
          />
        </div>
      </span>
    </div>
    <div
      v-if="showMetrics"
      class="load-stat"
      :style="{ zIndex: board.maxZ + 1 }"
    >
      <PieChart
        :value="capacityAllocation.values"
        :total="capacityAllocation.total"
        :zoom="fakeZoom / zoom"
        :size="1200"
      />
    </div>
    <div
      v-if="isExecutionMode && isCurrentIteration"
      class="time-line"
      :style="{ width: iterationNow.iterationsPassed * 100 + '%' }"
    >
      <svg
        :aria-label="
          $t('label.iteration.progress', {
            value: round(iterationNow.iterationsPassed * 100, 1),
          })
        "
      >
        <line x1="0" y1="0" x2="0" y2="100%" />
      </svg>
    </div>
    <StatusDistribution
      v-if="isExecutionMode"
      class="iteration-distribution"
      :value="distribution"
      :iteration="iteration"
      source-item-type="iteration"
    />
  </section>
</template>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/board";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "sass:color";
@use "@/styles/time-line" as *;
@use "@/styles/mixins/a11y";

.team-iteration {
  @include a11y.board-section;

  &.default,
  &.default .back-color {
    background-color: colors-old.$back-color;
  }

  &.ok,
  &.ok .back-color {
    background-color: color.scale(
      colors-old.$iteration-success-color,
      $lightness: 95%
    );
  }

  &.warn,
  &.warn .back-color {
    background-color: color.scale(
      colors-old.$iteration-warning-color,
      $lightness: 95%
    );
  }

  &.error,
  &.error .back-color {
    background-color: color.scale(
      colors-old.$iteration-error-color,
      $lightness: 95%
    );
  }

  .time-line {
    top: 0;
    left: 0;
  }

  .head {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;
    gap: board.len(6px);

    .row {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .iteration-name {
        font-size: board.len(14px);
        font-weight: font.$weight-bold;
        word-break: break-word;
      }

      .iteration-date {
        font-size: board.len(10px);
        font-weight: font.$weight-semi-bold;
        color: colors-old.$text-secondary-color;
        white-space: nowrap;
      }

      .iteration-info {
        display: flex;
        align-items: center;
        gap: board.len(6px);
      }

      .flag-icon {
        width: board.len(20px);
        height: board.len(20px);
      }

      .metric-wrap {
        display: flex;
        align-items: center;
        gap: board.len(6px);

        .metric-title {
          color: colors-old.$text-secondary-color;
          font-size: board.len(10px);
          font-weight: font.$weight-semi-bold;
        }

        .metric-input-wrap {
          display: inline-flex;
          width: board.len(28px);
          height: board.len(20px);

          .metric-input {
            text-align: center;
            font-size: board.len(10px);
          }
        }

        .screen-reader-only {
          @include a11y.screen-reader-only;

          margin-right: board.len(-6px);
        }
      }

      .stat-icon {
        font-size: inherit;

        > svg {
          width: 0.5em;
          height: 0.5em;
        }
      }

      .iteration-button:focus-visible {
        outline: board.len(2px) solid colors-old.$objective-highlight-color;
      }

      .actions {
        line-height: 0.4;
      }
    }
  }

  .load-stat {
    pointer-events: none;
    position: absolute;
    top: 1.5em;
    right: 1.4em;
  }

  .iteration-distribution {
    height: board.len(10px);
    position: absolute;
    left: board.len(12px);
    right: board.len(12px);
    top: 0;

    .bar-chart-wrap {
      padding-top: board.len(4px);

      .stacked-bar-chart {
        .empty-bar rect {
          fill: colors-old.$progress-empty-fill-color;
        }

        .bordered {
          border-width: board.len(1px);
        }
      }
    }
  }
}
</style>
