<!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events vuejs-accessibility/no-static-element-interactions -->
<template>
  <article
    :id="`objective-card-${objective.id}`"
    ref="objectiveCard"
    class="objective-card"
    tabindex="-1"
    :data-testid="`objective-card-${objective.id}`"
    :aria-label="$t('label.objective', { text: objective.text, index })"
    @mouseenter="setIsFocused(true)"
    @mouseleave="setIsFocused(false)"
  >
    <!-- eslint-enable vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions-->
    <div
      class="objective-inner-container"
      :class="{ focused: isFocused && !isDragging, dragging: isDragging }"
    >
      <div class="objective-title">
        <div
          v-if="isFocused && !isDragging && !readonly"
          class="objective-drag-icon"
        >
          <SvgIcon name="arrow/up-down" width="20" height="20" />
        </div>
        <div v-else class="objective-index">{{ index }}</div>
        <MaxLengthTextarea
          spellcheck="false"
          :placeholder="$t('objectives.title.placeholder')"
          :model-value="objective.text"
          :maxlength="260"
          :is-readonly="readonly"
          bordered
          @update:model-value="setTitle"
        />
        <DropdownMenu v-if="!readonly" :is-aria-menu="true">
          <template #trigger="{ isOpen }">
            <IconButton
              data-dropdown-trigger
              icon="general/vertical-more"
              :aria-label="$t('label.objective.menu')"
              :aria-expanded="isOpen"
              aria-controls="objective-card-dropdown-menu"
              aria-haspopup="menu"
            />
          </template>
          <div
            id="objective-card-dropdown-menu"
            v-keyboard-nav
            v-focus-trap
            role="menu"
          >
            <ListItem
              v-if="type === 'committed'"
              class="objective-menu-item"
              @click="markAsUncommitted"
            >
              <BaseButton
                variant="ghost"
                color="grey"
                icon-before="objective/uncommitted"
              >
                {{ $t("objective.markAsUncommitted") }}
              </BaseButton>
            </ListItem>
            <ListItem
              v-else-if="type === 'uncommitted'"
              class="objective-menu-item"
              @click="markAsCommitted"
            >
              <BaseButton
                variant="ghost"
                color="grey"
                icon-before="objective/committed"
              >
                {{ $t("objective.markAsCommitted") }}
              </BaseButton>
            </ListItem>
            <ListItem
              v-if="canMoveUp"
              class="objective-menu-item"
              @click="moveUp"
            >
              <BaseButton variant="ghost" color="grey" icon-before="arrow/up">
                {{ $t("objective.moveUp") }}
              </BaseButton>
            </ListItem>
            <ListItem
              v-if="canMoveDown"
              class="objective-menu-item"
              @click="moveDown"
            >
              <BaseButton variant="ghost" color="grey" icon-before="arrow/down">
                {{ $t("objective.moveDown") }}
              </BaseButton>
            </ListItem>
            <ListItem class="objective-menu-item" @click="remove()">
              <BaseButton
                variant="ghost"
                color="grey"
                icon-before="action-menu/delete"
              >
                {{ $t("general.delete") }}
              </BaseButton>
            </ListItem>
          </div>
        </DropdownMenu>
      </div>
      <div class="objective-description">
        <ExpandableTextarea
          :expand-description="expandDescriptions"
          :model-value="objective.description"
          spellcheck="false"
          :is-readonly="readonly"
          @update:model-value="setDescription"
        />
      </div>

      <div class="objective-details">
        <div class="objective-values">
          <div class="objective-value-title">
            <span aria-hidden="true">
              {{ $t("objectives.businessValue") }}
            </span>
            <div class="screen-reader-only">
              {{ $t("label.objective.bv", { value: objective.bv }) }}
            </div>
          </div>
          <button
            class="value-container"
            :aria-label="$t('label.objective.bvButton')"
            :aria-expanded="showBusinessValueSelector"
            :aria-controls="`business-value-${objective.id}`"
            :class="{ focused: showBusinessValueSelector }"
            :disabled="readonly"
            @click.stop="showBusinessValueSelector = !showBusinessValueSelector"
            @keydown.escape.stop="showBusinessValueSelector = false"
          >
            <span class="value">{{ objective.bv }}</span>
            <PointsSelect
              :id="`business-value-${objective.id}`"
              :show="showBusinessValueSelector"
              :current="objective.bv"
              @input="setBusinessValue"
            />
          </button>
          <div class="objective-value-title actual-value">
            <span aria-hidden="true">{{ $t("objectives.actualValue") }}</span>
            <div class="screen-reader-only">
              {{ $t("label.objective.av", { value: objective.av }) }}
            </div>
          </div>
          <button
            class="value-container"
            :class="{ focused: showActualValueSelector }"
            :aria-label="$t('label.objective.avButton')"
            :aria-expanded="showActualValueSelector"
            :aria-controls="`actual-value-${objective.id}`"
            :disabled="readonly"
            @click.stop="showActualValueSelector = !showActualValueSelector"
            @keydown.escape.stop="showActualValueSelector = false"
          >
            <span class="value">{{ actualValue }}</span>
            <PointsSelect
              :id="`actual-value-${objective.id}`"
              :show="showActualValueSelector && !readonly"
              :current="objective.av"
              @input="setActualValue"
            />
          </button>
        </div>
      </div>
      <div class="objective-expanded-content">
        <div class="objective-content-header">
          <button
            ref="linkedStickiesToggle"
            class="linked-stickies-count"
            :disabled="linkedCardTree.cards.length === 0"
            :aria-expanded="isLinkedStickiesExpanded"
            @click.stop="toggleExpandLinkedStickies"
          >
            <SvgIcon name="action-menu/link" width="20" height="20" />
            <span style="margin-left: 5px">
              {{
                $t("objectivesModal.linkedStickiesNumber", linkedCardTree.count)
              }}
            </span>
            <SvgIcon
              v-if="linkedCardTree.cards.length > 0"
              name="arrow/up"
              width="20"
              height="20"
              class="arrow-icon"
              :class="{ expanded: isLinkedStickiesExpanded }"
            />
          </button>

          <StatusDistribution
            v-if="hasStatusDistribution"
            :value="getStatusDistribution()"
            source-item-type="objective"
          />
        </div>

        <RevealHeightTransition wipe>
          <div
            v-if="isLinkedStickiesExpanded && isExpendable"
            class="reveal-container"
          >
            <div class="linked-stickies-container">
              <!-- eslint-disable-next-line vuejs-accessibility/mouse-events-have-key-events vuejs-accessibility/no-static-element-interactions -->
              <div
                v-for="sticky in linkedCardTree.cards"
                :id="'sticky-' + sticky.id"
                :key="sticky.id"
                :class="{ current: isStickyHighlighted(sticky.id) }"
                @mouseenter.stop="setHighlightedSticky(sticky.id)"
                @mouseleave.stop="setHighlightedSticky(null)"
              >
                <CardTree :linked-card-ids="sticky.childIds" show-status>
                  <LinkableCardRow
                    :linkable-card="sticky"
                    reset-button
                    show-status
                    :show-actions="!readonly"
                    @set-linked-state="removeLink(sticky)"
                    @click.stop
                  />
                </CardTree>
              </div>
            </div>
          </div>
        </RevealHeightTransition>
      </div>
    </div>
  </article>
</template>

<script lang="ts">
import { nextTick } from "vue";
import { Options as Component, Vue } from "vue-class-component";
import { Prop, Ref, Watch } from "vue-property-decorator";

import { linkActions } from "@/action/linkActions";
import { objectiveActions } from "@/action/objectiveActions";
import CardTree from "@/components/CardRow/CardTree.vue";
import LinkableCardRow from "@/components/CardRow/LinkableCardRow.vue";
import { objectiveStatusDistribution } from "@/components/StatusDistribution/StatusDistribution";
import StatusDistribution from "@/components/StatusDistribution/StatusDistribution.vue";
import ExpandableTextarea from "@/components/input/ExpandableTextarea.vue";
import MaxLengthTextarea from "@/components/input/MaxLengthTextarea.vue";
import PointsSelect from "@/components/input/PointsSelect.vue";
import RevealHeightTransition from "@/components/transitions/RevealHeightTransition.vue";
import DropdownMenu from "@/components/ui/DropdownMenu/DropdownMenu.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import ListItem from "@/components/ui/ListItem/ListItem.vue";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { BoardWithObjectives } from "@/model/board";
import { LinkableCard } from "@/model/link";
import { Objective } from "@/model/objective";
import { useBoardStore } from "@/store/board";
import { useObjectiveStore } from "@/store/objective";
import { useUserStore } from "@/store/user";
import {
  LinkRemoveTrigger,
  objectiveActualValueSet,
  objectiveBusinessValueSet,
} from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

import BaseButton from "../ui/BaseButton/BaseButton.vue";

type ObjectiveType = "committed" | "uncommitted";

@Component({
  components: {
    SvgIcon,
    IconButton,
    StatusDistribution,
    PointsSelect,
    DropdownMenu,
    ListItem,
    LinkableCardRow,
    CardTree,
    MaxLengthTextarea,
    ExpandableTextarea,
    RevealHeightTransition,
    BaseButton,
  },
})
export default class ObjectiveCard extends Vue {
  @Prop({ type: Object, required: true }) readonly objective!: Objective;
  @Prop({ type: String, required: true }) readonly type!: ObjectiveType;
  @Prop({ type: Number, required: true }) readonly index!: number;
  @Prop({ type: Object, required: true }) readonly board!: BoardWithObjectives;
  @Prop({ type: Boolean, default: false }) readonly isDragging!: boolean;
  @Prop({ type: Boolean, default: false })
  readonly expandDescriptions!: boolean;
  @Prop({ type: Boolean, default: true })
  readonly isExpendable!: boolean;
  @Prop({ type: Boolean, default: true })
  readonly hasStatusDistribution!: boolean;
  @Prop({ type: Boolean, default: false }) readonly canMoveUp!: boolean;
  @Prop({ type: Boolean, default: false }) readonly canMoveDown!: boolean;

  @Ref("linkedStickiesToggle") readonly linkedStickiesToggle!: HTMLElement;
  @Ref("objectiveCard") readonly objectiveCardEl!: HTMLElement;

  highlightedStickyId: null | string = null;
  isFocused = false;
  isLinkedStickiesExpanded = false;
  showActualValueSelector = false;
  showBusinessValueSelector = false;

  get teamId() {
    return this.board.type === "team" ? this.board.team.id : undefined;
  }

  @Watch("isDragging")
  resetIsFocused() {
    this.setIsFocused(false);
  }

  /**
   * Close the linked stickies expandable when the last linked sticky is unlinked
   */
  checkIfShouldBeExpanded() {
    if (
      this.isLinkedStickiesExpanded &&
      this.linkedCardTree.cards.length === 0
    ) {
      this.toggleExpandLinkedStickies();
    }
  }

  isStickyHighlighted(stickyId: string) {
    return !!this.highlightedStickyId && stickyId === this.highlightedStickyId;
  }

  setHighlightedSticky(stickyId: string | null) {
    this.highlightedStickyId = stickyId;
  }

  setTitle(title: string) {
    this.objective.text = title;
    objectiveActions.setTitle("mainMenu", this.board.id, this.objective);
  }

  setDescription(description: string) {
    this.objective.description = description;
    objectiveActions.setDescription("mainMenu", this.board.id, this.objective);
  }

  setIsFocused(isFocused: boolean) {
    this.isFocused = isFocused;
  }

  setActualValue(value?: number) {
    this.showActualValueSelector = false;
    if (value !== undefined) {
      trackEvent(
        objectiveActualValueSet({
          hadPreviousValue: this.objective.bv > 0,
          hasNewValue: true,
        }),
      );

      this.objective.av = value;
      objectiveActions.setAv("mainMenu", this.board.id, this.objective);
    }
  }

  setBusinessValue(value?: number) {
    this.showBusinessValueSelector = false;
    if (value !== undefined) {
      trackEvent(
        objectiveBusinessValueSet({
          hadPreviousValue: this.objective.bv > 0,
          hasNewValue: true,
          location: "objectives-modal",
        }),
      );

      this.objective.bv = value;
      objectiveActions.setBv("mainMenu", this.board.id, this.objective);
    }
  }

  toggleExpandLinkedStickies() {
    this.isLinkedStickiesExpanded = !this.isLinkedStickiesExpanded;
  }

  getStatusDistribution() {
    return objectiveStatusDistribution(this.linkedCardTree);
  }

  async markAsUncommitted() {
    objectiveActions.move("mainMenu", this.board.id, this.objective.id, {
      stretch: true,
      rank: this.board.stretchObjectives.length,
    });

    // move focus to the objective after it's rerendered in the new state
    await nextTick();
    document.getElementById(`objective-card-${this.objective.id}`)?.focus();
  }

  async markAsCommitted() {
    objectiveActions.move("mainMenu", this.board.id, this.objective.id, {
      stretch: false,
      rank: this.board.objectives.length,
    });

    // move focus to the objective after it's rerendered in the new state
    await nextTick();
    document.getElementById(`objective-card-${this.objective.id}`)?.focus();
  }

  get title() {
    return this.objective.text;
  }

  get actualValue() {
    return this.objective.av ? this.objective.av : 0;
  }

  remove() {
    objectiveActions.remove("mainMenu", this.board.id, this.objective.id);

    // Notify the parent component that the objective has been removed
    this.$emit("removed");
  }

  get linkedCardTree() {
    return useObjectiveStore().linkedCardTree(this.objective, {
      teamId: this.teamId,
    });
  }

  get readonly() {
    return !useUserStore().isAllowed("edit");
  }

  async removeLink(sticky: LinkableCard) {
    const currentBoard = useBoardStore().currentBoard();
    const trigger: LinkRemoveTrigger =
      currentBoard.type === "objectives"
        ? "art-objectives-board"
        : "team-objectives-modal";

    if (this.readonly) return;

    await linkActions.removeObjective(
      "board",
      sticky.id,
      this.board.id,
      this.objective.id,
      trigger,
    );

    this.checkIfShouldBeExpanded();

    // Move focus to the linked stickies toggle button or,
    // if there are no linked stickies, focus on the objective card
    if (this.linkedCardTree?.cards?.length) {
      this.linkedStickiesToggle?.focus();
    } else {
      this.objectiveCardEl?.focus();
    }
  }

  /**
   * Move the objective up in the list
   */
  moveUp() {
    objectiveActions.move("contextMenu", this.board.id, this.objective.id, {
      stretch: this.type === "uncommitted",
      rank: this.index - 2, // (local index is 1-based, API index is 0-based)
    });
  }

  /**
   * Move the objective down in the list
   */
  moveDown() {
    objectiveActions.move("contextMenu", this.board.id, this.objective.id, {
      stretch: this.type === "uncommitted",
      rank: this.index, // (local index is 1-based, API index is 0-based)
    });
  }
}
</script>
<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/mixins/utils";
@use "@/styles/variables";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/mixins/a11y";

.objective-card {
  margin-bottom: 12px;
  background-color: colors-old.$back-color;
  border-radius: variables.$objective-card-border-radius;

  .objective-title {
    display: flex;
    margin-bottom: 4px;
    align-items: center;

    base-textarea {
      width: auto;
    }

    .objective-index {
      font-size: font.$size-normal;
      font-weight: font.$weight-semi-bold;
      margin-right: 4px;
    }

    .objective-drag-icon {
      margin: 0 0 0 -8px;
    }
  }

  .objective-inner-container {
    border: 1px solid colors-old.$objective-border-color;
    padding: 10px 16px 16px;
    border-radius: variables.$objective-card-border-radius;

    &.focused {
      border: 1px solid colors-old.$objective-border-color-hovered;
      cursor: pointer;
    }

    &.dragging {
      cursor: grabbing;
    }
  }

  .objective-details {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .objective-description {
    padding-left: 0.8125rem;
    margin-bottom: 0.75rem;

    :deep(.description-textarea) {
      position: static;
      font-weight: font.$weight-normal;
      color: colors-old.$text-primary-color;
    }
  }

  .objective-values {
    display: flex;
    align-items: center;
    padding-left: 21px;

    .objective-value-title {
      font-size: font.$size-small;
      font-weight: font.$weight-normal;
      margin-right: 8px;

      &.actual-value {
        margin-left: 20px;
      }
    }

    .value-container {
      position: relative;
      border: 1px solid colors-old.$objective-border-color;
      border-radius: 6px;
      width: 28px;
      height: 28px;
      line-height: 28px;
      display: flex;
      justify-content: center;
      text-align: center;
      outline: revert;

      &:hover:not(:disabled) {
        background-color: colors-old.$background-hover-menu-color;
      }

      .value {
        font-size: font.$size-small;
      }

      &.focused {
        border: 1px solid colors-old.$menu-item-focus-color;
      }

      .points-select {
        top: 100%;
        left: 0;
        margin-top: 2px;
      }
    }
  }

  .list-item > * {
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .objective-expanded-content {
    border-top: 1px solid colors-old.$objective-border-color;
    margin: 16px 21px 0;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 200px;

    .objective-content-header {
      display: flex;
      justify-content: space-between;

      .linked-stickies-count {
        font-size: font.$size-small;
        color: colors-old.$text-secondary-color;
        font-weight: font.$weight-semi-bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        outline: revert;

        &:not(:disabled) {
          cursor: pointer;
        }

        .arrow-icon {
          transform: rotate(180deg);
          transition: transform 0.4s ease;

          &.expanded {
            transform: none;
          }
        }
      }

      .status-distribution {
        height: 1rem;
        width: 30%;
      }
    }
  }

  .objective-content {
    @include utils.hide-scrollbar;
  }

  &.objective-drag-state > .objective-inner-container {
    visibility: hidden;
  }

  .linked-stickies-container {
    max-height: 160px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .screen-reader-only {
    @include a11y.screen-reader-only;
  }
}
</style>

<style lang="scss">
.objective-card {
  .status-distribution .bordered {
    border-width: 2px;
  }
}

.objective-menu-item {
  padding: 0;
}
</style>
