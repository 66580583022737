import { defineStore } from "pinia";

import { IdMap } from "@/model/baseTypes";
import { Board, BoardWithObjectives } from "@/model/board";
import { BoardCard, Card } from "@/model/card";
import {
  Link,
  LinkType,
  LinkableItem,
  Linking,
  LinkingTarget,
  ObjectiveLink,
} from "@/model/link";

import { useBoardStore } from "./board";
import { useBoardsStore } from "./boards";
import { useCardStore } from "./card";
import { calcLinkState } from "./helpers/linkState";
import { useObjectiveStore } from "./objective";
import { useSessionStore } from "./session";
import { useTeamStore } from "./team";

export interface LinkedCards {
  link: Link;
  from: BoardCard;
  to: BoardCard;
}

export const useLinkStore = defineStore("link", {
  state: () => {
    return {
      links: [] as Link[],
      linksById: {} as Record<string, Link>,
      linksByFrom: {} as Record<string, Link[]>,
      linksByTo: {} as Record<string, Link[]>,
      markingObjectiveLinkedCards: false,
      markingCardLinkedCards: null as BoardCard | null,
      linking: { from: null, to: null } as Linking,
      linkTypes: [] as LinkType[],
      linkGroups: {} as Record<string, IdMap<BoardCard>>,
    };
  },
  getters: {
    linksByCard:
      (state) =>
      (lnkId: string): Link[] => {
        return [
          ...(state.linksByFrom[lnkId] || []),
          ...(state.linksByTo[lnkId] || []),
        ];
      },
    isLinkedFrom: (state) => (id: LinkingTarget["id"]) => {
      return state.linking.from?.id === id;
    },
    isPinned: (state) => (cardId: string) => {
      return state.markingCardLinkedCards?.data.id === cardId;
    },
    isMarkingLinks: (state) => {
      return (
        state.markingObjectiveLinkedCards || !!state.markingCardLinkedCards
      );
    },
    cardsByLink:
      (state) =>
      (linkTargetId: string): Card[] => {
        const groups = state.linkGroups[linkTargetId];
        if (groups) {
          const res = [];
          for (const id in groups) {
            res.push(groups[id].data);
          }
          return res;
        }
        const card = useCardStore().cards[linkTargetId];
        if (!card) {
          // can happen because of REN-8846
          return [];
        }
        return [card];
      },
    boardCardByLink:
      (state) =>
      (linkTargetId: string, board: Board): BoardCard | undefined => {
        const groups = state.linkGroups[linkTargetId];
        return groups ? groups[board.id] : board.cards[linkTargetId];
      },
    getLinkableItems:
      (state) =>
      (card: Card): LinkableItem[] => {
        const cardLinks = card.links
          .flatMap((link) => {
            if (!link.to) {
              return [];
            }
            const linkedCard = getCardByLinkId(getLinkTargetId(card, link));
            if (!linkedCard) {
              return [];
            }
            return {
              kind: "sticky" as const,
              linked: true,
              id: linkedCard.id,
              text: linkedCard.text,
              type: linkedCard.type,
              flag: linkedCard.flagType,
              iteration: useSessionStore().getIterationById(
                linkedCard.iterationId,
              ),
              team: linkedCard.teamId
                ? useTeamStore().findTeam({ id: linkedCard.teamId })
                : undefined,
              artId: linkedCard.artId ?? undefined,
              almId: linkedCard.almId,
              status: linkedCard.status,
              points: linkedCard.points,
              precondTeam: linkedCard.precondTeam ?? undefined,
              dependTeam: linkedCard.dependTeam ?? undefined,
            };
          })
          .reverse();
        const objectiveLinks = card.objectives
          .flatMap((objective) => {
            const board = useBoardsStore().boards[
              objective.boardId
            ] as BoardWithObjectives;
            const boardObjective = [
              ...(board?.objectives ?? []),
              ...(board?.stretchObjectives ?? []),
            ].find(({ id }) => objective.id === id);
            if (!boardObjective) {
              return [];
            }

            return {
              kind: "objective" as const,
              linked: true,
              id: objective.id,
              text: boardObjective.text,
              description: boardObjective.description,
              boardId: objective.boardId,
              type: useObjectiveStore().objectiveTypeById(objective.id, {
                boardId: objective.boardId,
              }),
            };
          })
          .reverse();
        return [...cardLinks, ...objectiveLinks];

        function getCardByLinkId(linkId: string) {
          const groups = state.linkGroups[linkId];
          if (groups && Object.keys(groups).length > 0) {
            return Object.entries(groups)[0][1].data;
          }
          const card = useCardStore().cards[linkId];
          if (!card) {
            // can happen because of REN-8846
            return;
          }
          return card;
        }
      },
    linksOnBoard() {
      const links = new Array<LinkedCards>();
      const board = useBoardStore().board;
      if (!board) {
        return [];
      }
      Object.values(board.cards).flatMap((from) => {
        for (const link of from.data.links) {
          if (link.to && linkId(from.data) === link.from) {
            const to = this.boardCardByLink(link.to, board);
            if (to) {
              links.push({ link, from, to });
            }
          }
        }
      });
      return links;
    },
    riskyLinkCount() {
      const counts = { risky: 0, critical: 0 };
      this.linksOnBoard.forEach((linked) => {
        if (linked.link.state === "warn") {
          counts.risky++;
        }
        if (linked.link.state === "error") {
          counts.critical++;
        }
      });
      return counts;
    },
    hasRiskyLinks() {
      const counts: { risky: number; critical: number } = this.riskyLinkCount;
      return counts.risky > 0 || counts.critical > 0;
    },
  },
  actions: {
    setLinks(links: Link[]) {
      this.links = [];
      for (const link of links) {
        this.addLink(link);
      }
    },
    addLink(link: Link) {
      this.links.push(link);
      this.linksById[link.id] = link;
      add(this.linksByFrom, link.from, link);
      add(this.linksByTo, link.to, link);

      function add(map: Record<string, Link[]>, id: string, link: Link) {
        if (!(id in map)) {
          map[id] = [];
        }
        map[id].push(link);
      }
    },
    addToLinkGroup(groupId: string, boardId: string, boardCard: BoardCard) {
      if (!this.linkGroups[groupId]) this.linkGroups[groupId] = {};
      this.linkGroups[groupId][boardId] = boardCard;
    },
    removeFromLinkGroup(card: Card, boardId: string) {
      if (card.groupId && this.linkGroups[card.groupId]) {
        delete this.linkGroups[card.groupId][boardId];
      }
    },
    removeLink(index: number) {
      const link = this.links[index];
      this.links.splice(index, 1);
      delete this.linksById[link.id];
      remove(this.linksByFrom, link.from, link);
      remove(this.linksByTo, link.to, link);
      return link;

      function remove(map: Record<string, Link[]>, id: string, link: Link) {
        const i = map[id].findIndex((l) => l.id === link.id);
        if (i >= 0) {
          map[id].splice(i, 1);
        }
      }
    },

    add(link: Link) {
      if (this.linksById[link.id]) {
        return;
      }
      this.setLinkState(link);
      this.addLink(link);
      this.updateLinks(link);
    },

    remove(linkId: string) {
      const linkIndex = this.links.findIndex((link) => link.id === linkId);
      if (linkIndex < 0) {
        return;
      }
      const link = this.removeLink(linkIndex);
      this.updateLinks(link);
    },
    addObjectiveLink(
      cardId: Card["id"],
      linkInfo: { boardId: string; objectiveId: string },
    ) {
      const card = useCardStore().cards[cardId];
      if (!card) {
        return;
      }
      if (
        card.objectives.every(
          (cardObjective) =>
            cardObjective.boardId !== linkInfo.boardId ||
            cardObjective.id !== linkInfo.objectiveId,
        )
      ) {
        card.objectives.push({
          id: linkInfo.objectiveId,
          boardId: linkInfo.boardId,
        });
      }
    },
    removeObjectiveLink(
      cardId: Card["id"],
      linkInfo: Pick<ObjectiveLink, "objectiveId" | "boardId">,
    ) {
      const card = useCardStore().cards[cardId];
      if (!card) {
        return;
      }
      card.objectives = card.objectives.filter(
        (cardObjective) =>
          cardObjective.boardId !== linkInfo.boardId ||
          cardObjective.id !== linkInfo.objectiveId,
      );
    },
    findLink(
      linkInfo: { id: Card["id"]; toId: Card["id"] } | { linkId: Link["id"] },
    ): Link | undefined {
      if ("linkId" in linkInfo) {
        return this.linksById[linkInfo.linkId];
      }
      // linkInfo.id and linkInfo.toId are always card ids, not groupIds
      const card = useCardStore().cards[linkInfo.id];
      const toCard = useCardStore().cards[linkInfo.toId];
      return this.links.find(
        (link) =>
          (link.from === linkId(card) || link.from === linkId(toCard)) &&
          (link.to === linkId(card) || link.to === linkId(toCard)),
      );
    },
    setLinkState(link: Link) {
      const fromCards = this.cardsByLink(link.from);
      const toCards = this.cardsByLink(link.to);
      if (fromCards.length && toCards.length) {
        link.state = calcLinkState(fromCards[0], toCards[0]);
      }
    },
    updateLinks(link: Link) {
      this.cardsByLink(link.from).forEach((c) => {
        c.links = this.linksByCard(link.from);
      });
      this.cardsByLink(link.to).forEach((c) => {
        c.links = this.linksByCard(link.to);
      });
    },
    setCardLinkStates(card: Card) {
      card.links.forEach((link) => this.setLinkState(link));
    },

    // breadth first search from to given cards following all links
    linkedCardDistances(card: BoardCard): IdMap<number> {
      const boardCardByLink = this.boardCardByLink;

      const queue = [{ card, distance: 0 }];
      const linked: IdMap<number> = {};
      while (queue.length > 0) {
        const [item] = queue.splice(0, 1);
        if (!(item.card.data.id in linked)) {
          linked[item.card.data.id] = item.distance;
          item.card.data.links.forEach((link) => {
            addToQueue(link.from, item.distance + 1);
            addToQueue(link.to, item.distance + 1);
          });
        }
      }
      return linked;

      function addToQueue(linkTargetId: string, distance: number) {
        const card = boardCardByLink(
          linkTargetId,
          useBoardStore().currentBoard(),
        );
        if (card && !(card.data.id in linked)) {
          queue.push({ card, distance });
        }
      }
    },

    markObjectiveLinkedCards(objectiveId: string) {
      this.markingObjectiveLinkedCards = true;
      Object.values(useBoardStore().currentBoard().cards).forEach(
        (boardCard) => {
          const isLinkedCard = boardCard.data.objectives.some(
            (objective) => objective.id === objectiveId,
          );
          boardCard.meta.mark = isLinkedCard ? "normal" : "fade-out";
        },
      );
    },

    setLinkingTarget(target: LinkingTarget) {
      this.linking.to = target;
    },
    resetLinkingTarget() {
      this.linking.to = null;
    },
    removeAllMarks() {
      Object.values(useBoardStore().currentBoard().cards).forEach((boardCard) =>
        makeLinksUnmarked(boardCard),
      );
      this.markingObjectiveLinkedCards = false;
      this.markingCardLinkedCards = null;
    },
  },
});

export function linkId(
  card: Pick<Card, "id" | "groupId">,
): NonNullable<Card["groupId"] | Card["id"]> {
  return card.groupId || card.id;
}

function makeLinksUnmarked(card: BoardCard) {
  card.meta.mark = "normal";
  card.meta.isHighlighted = false;
  card.meta.isRelatedToHighlighted = false;
}

export function linkBetween(
  card: Card,
  other?: Pick<Card, "id" | "groupId"> | null,
) {
  return (
    other &&
    card.id !== other.id &&
    card.links.find((l) => l.from === linkId(other) || l.to === linkId(other))
  );
}

export function getLinkTargetId(card: Card, link: Link): string {
  return linkId(card) === link.from ? link.to : link.from;
}
