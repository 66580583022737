<script setup lang="ts">
import { computed } from "vue";

import SelectStickyType from "@/components/SelectStickyType/SelectStickyType.vue";
import DropdownMenu from "@/components/ui/DropdownMenu/DropdownMenu.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import InfoMarker from "@/components/ui/InfoMarker/InfoMarker.vue";
import { useBoardStore } from "@/store/board";
import { useSearchMenuStore } from "@/store/searchMenu";

import StickyTypeItemTooltip from "../tooltips/StickyTypeItemTooltip.vue";
import { trackFilterOpen } from "../utils";

const store = useSearchMenuStore();
const stickyTypes = useBoardStore().possibleStickyTypes;

const selection = computed({
  get: () => store.selectedStickyTypes,
  set: (values: Array<{ id: string }>) =>
    (store.selectedStickyTypeIds = new Set(values.map((val) => val.id))),
});
</script>

<template>
  <DropdownMenu
    class="search-select select-sticky-type"
    width="sm"
    stay-open-on-content-click
    is-aria-menu
    @open="trackFilterOpen('sticky-type')"
  >
    <template #trigger="{ isOpen }">
      <IconButton
        icon="search/sticky-type"
        data-dropdown-trigger
        :activated="isOpen"
        :aria-label="
          $t('label.search.filterButton', { value: $t('general.stickyTypes') })
        "
      >
        <template #tooltip>
          <StickyTypeItemTooltip :types="selection" />
        </template>
      </IconButton>
      <InfoMarker v-if="selection.length" :top="4" :right="4" />
    </template>
    <SelectStickyType v-model="selection" :sticky-types="stickyTypes" />
  </DropdownMenu>
</template>
