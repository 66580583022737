import { boardActions } from "@/action/boardActions";
import { navigationActions } from "@/action/navigationActions";
import { toggleActions } from "@/action/toggleActions";
import {
  registerActionShortcut,
  registerShortcut,
} from "@/components/utils/Shortcuts";
import { useActivityStore } from "@/store/activity";
import { useBoardStore } from "@/store/board";
import { useDrawStore } from "@/store/draw";
import { useLinkStore } from "@/store/link";
import { useModalStore } from "@/store/modal";
import { usePointerStore } from "@/store/pointer";
import { useSelectionStore } from "@/store/selection";
import { useTimerStore } from "@/store/timer";

export function registerGlobalActions() {
  registerActionShortcut("", toggleActions.showStats, { prevent: false });
  registerActionShortcut("", toggleActions.showStatGraphs, { prevent: false });
  registerShortcut(
    "",
    "Escape",
    () => {
      if (useModalStore().isModalOpen()) {
        useModalStore().hide();
      } else if (useDrawStore().active) {
        useDrawStore().setActive(false);
      } else if (useActivityStore().active) {
        useActivityStore().inactivate();
      } else if (useTimerStore().active) {
        useTimerStore().toggleTimer();
      } else {
        if (useBoardStore().selectedStickyIds.length > 0) {
          boardActions.clearCardSelection("keyboard");
        } else {
          useSelectionStore().selecting = "no";
        }

        // Clear pinned sticky
        if (useLinkStore().isMarkingLinks) {
          useLinkStore().removeAllMarks();
        }
      }
      usePointerStore().activatePointer(false);
    },
    { prevent: false },
  );
  registerActionShortcut("", navigationActions.goBack);
  registerShortcut("", "Backspace", () => window.history.back());
}
