<script setup lang="ts">
import { computed } from "vue";

import DropdownMenu from "@/components/ui/DropdownMenu/DropdownMenu.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import InfoMarker from "@/components/ui/InfoMarker/InfoMarker.vue";
import SelectNamedValue from "@/components/ui/SelectNamedValue/SelectNamedValue.vue";
import { useSearchMenuStore } from "@/store/searchMenu";

import TeamItemTooltip from "../tooltips/TeamItemTooltip.vue";
import { trackFilterOpen } from "../utils";

const store = useSearchMenuStore();

const selection = computed({
  get: () => store.selectedTeams,
  set: (values: Array<{ id: string }>) =>
    (store.selectedTeamIds = new Set(values.map((val) => val.id))),
});
</script>

<template>
  <DropdownMenu
    class="search-select"
    width="sm"
    stay-open-on-content-click
    is-aria-menu
    @open="trackFilterOpen('team')"
  >
    <template #trigger="{ isOpen }">
      <IconButton
        icon="action-menu/team"
        data-dropdown-trigger
        :activated="isOpen"
        :aria-label="
          $t('label.search.filterButton', { value: $t('general.teams') })
        "
      >
        <template #tooltip>
          <TeamItemTooltip :teams="selection" />
        </template>
      </IconButton>
      <InfoMarker v-if="selection.length" :top="4" :right="4" />
    </template>
    <SelectNamedValue
      v-model="selection"
      :values="store.allTeams"
      :header="$t('general.teams')"
      scrollable
    />
  </DropdownMenu>
</template>
