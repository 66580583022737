import { Board, isSolutionBoard } from "@/model/board";
import { BoardCard } from "@/model/card";
import { DependencyTeamFilter } from "@/model/search";
import { isDependency } from "@/model/stickyType";
import { useBoardStore } from "@/store/board";
import { getLinkTargetId, linkBetween, useLinkStore } from "@/store/link";
import { useSearchMenuStore } from "@/store/searchMenu";
import { noUser } from "@/store/user";
import { toCssColor, white } from "@/utils/color";
import { matches } from "@/utils/list";

export class CardMatcher {
  constructor(
    private searchMenuStore: ReturnType<typeof useSearchMenuStore>,
    private card: BoardCard,
  ) {}

  get textMatches(): boolean {
    // Use same matching algo as in the backend piplanningserver/piserver/repos/mongo/sticky.py
    // -> Match ALM id to full query string
    // -> Match any word in the query string to any word in the text
    const searchText = this.searchMenuStore.text.toLowerCase();
    const searchWords = searchText.split(/\s+/).filter(Boolean);
    // filter(Boolean) to handle multiple spaces in the search query, since str.includes('') always returns true

    return (
      searchText.length === 0 ||
      (this.card.data.almId || "").toLowerCase().includes(searchText) ||
      searchWords.some((word) =>
        this.card.data.text.toLowerCase().includes(word),
      )
    );
  }

  get idMatches(): boolean {
    return (
      !this.searchMenuStore.id || this.searchMenuStore.id === this.card.data.id
    );
  }

  get statusClassMatches(): boolean {
    return matches(
      this.searchMenuStore.statusClasses,
      this.card.data.status?.statusClass,
    );
  }

  get flagMatches(): boolean {
    return (
      this.searchMenuStore.flags.length === 0 ||
      this.searchMenuStore.flags.some((flag) =>
        flag.equals(this.card.data.flagType),
      )
    );
  }

  get iterationMatches(): boolean {
    return matches(
      this.searchMenuStore.selectedIterations,
      this.card.data.iterationId,
      "id",
    );
  }

  get assigneeMatches(): boolean {
    return matches(
      this.searchMenuStore.selectedAssignees,
      this.card.data.assignee?.id || noUser(toCssColor(white)).id,
      "id",
    );
  }

  get artMatches(): boolean {
    if (
      !isSolutionBoard(useBoardStore().currentBoard().type) ||
      this.applyDependencySearch
    ) {
      return true;
    }
    return matches(
      this.searchMenuStore.selectedArts,
      this.card.data.artId,
      "id",
    );
  }

  get teamMatches(): [boolean, DependencyTeamFilter | null] {
    if (
      this.applyDependencySearch &&
      this.searchMenuStore.effectiveTeamIds.size
    ) {
      const dependTeamMatch = this.searchMenuStore.isTeamSelected(
        this.card.data.dependTeam?.id,
      );
      const precondTeamMatch = this.searchMenuStore.isTeamSelected(
        this.card.data.precondTeam?.id,
      );

      switch (this.searchMenuStore.selectedDependencyTeamFilter) {
        case "Incoming":
          return [precondTeamMatch, "Incoming"];
        case "Outgoing":
          return [dependTeamMatch, "Outgoing"];
        case "Mutual":
          return [dependTeamMatch && precondTeamMatch, "Mutual"];
        case "IncomingAndOutgoing":
          return [
            dependTeamMatch || precondTeamMatch,
            precondTeamMatch ? "Incoming" : "Outgoing",
          ];
      }
    }
    if (useBoardStore().currentBoard().type === "solution") {
      return [true, null];
    }
    return [
      matches(this.searchMenuStore.selectedTeams, this.card.data.teamId, "id"),
      null,
    ];
  }

  get applyDependencySearch() {
    return (
      this.searchMenuStore.isDependencySearch && isDependency(this.card.data)
    );
  }

  typeMatches(board: Board): boolean {
    const typeMatch = matches(
      this.searchMenuStore.selectedStickyTypes,
      this.card.data.type.id,
      "id",
    );

    const selectedDependencyLink = this.searchMenuStore.selectedDependencyLink;

    if (
      typeMatch &&
      isDependency(this.card.data) &&
      selectedDependencyLink.size > 0
    ) {
      const anyLinks: boolean = Object.values(board.cards).some((boardCard) =>
        linkBetween(boardCard.data, this.card.data),
      );

      return (
        (selectedDependencyLink.has("Linked") && anyLinks) ||
        (selectedDependencyLink.has("Not Linked") && !anyLinks)
      );
    }

    return typeMatch;
  }

  explicitLinkMatch(board: Board): boolean {
    return this.card.data.links.some(
      (link) =>
        useSearchMenuStore().matchesLinkStates(link) &&
        useLinkStore().boardCardByLink(
          getLinkTargetId(this.card.data, link),
          board,
        ),
    );
  }
}
