<script setup lang="ts">
import BaseSwitch from "@/components/input/BaseSwitch.vue";
import PercentageInput from "@/components/input/PercentageInput.vue";
import { useClientSettingsStore } from "@/store/clientSettings";

import SettingsFontDropdown from "./SettingsFontDropdown.vue";

const settings = useClientSettingsStore();
</script>

<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <div class="settings-page">
    <h2 class="title">{{ $t("settings.generalTitle") }}</h2>
    <div class="row">
      <div class="left">
        <label for="settings-mouse-wheel" class="subtitle">
          {{ $t("settings.mouseWheelTitle") }}
        </label>
        <p class="description">
          {{ $t("settings.mouseWheelDescription") }}
        </p>
      </div>
      <div class="right">
        <BaseSwitch
          v-model="settings.wheelZoom"
          input-id="settings-mouse-wheel"
          input-class="allow-shortcuts"
        />
      </div>
    </div>
    <div class="row">
      <div class="left">
        <label for="settings-high-contrast" class="subtitle">
          {{ $t("settings.highContrastTitle") }}
        </label>
      </div>
      <div class="right">
        <BaseSwitch
          v-model="settings.highContrastMode"
          input-id="settings-high-contrast"
          input-class="allow-shortcuts"
        />
      </div>
    </div>
    <h2 class="title">{{ $t("settings.stickyNotesTitle") }}</h2>
    <div class="row">
      <div class="left">
        <p class="subtitle">{{ $t("settings.fontType") }}</p>
        <p class="description">
          {{ $t("settings.stickyNotesDescription") }}
        </p>
      </div>
      <div class="right">
        <SettingsFontDropdown
          v-model="settings.stickyFont"
          :trigger-label="$t('label.settings.fontTrigger')"
        />
      </div>
    </div>
    <div class="row">
      <div class="left">
        <label for="settings-text-scale" class="subtitle">
          {{ $t("settings.scaleUpStickyFont") }}
        </label>
        <p class="description">
          {{ $t("settings.fontScaleDescription") }}
        </p>
      </div>
      <div class="right">
        <BaseSwitch
          v-model="settings.textScale"
          input-id="settings-text-scale"
          input-class="allow-shortcuts"
        />
      </div>
    </div>
    <h2 class="title">{{ $t("settings.boardsTitle") }}</h2>
    <div class="row">
      <div class="left">
        <label for="settings-load-warn" class="subtitle">
          {{ $t("settings.loadWarningTitle") }}
        </label>
        <p class="description">
          {{ $t("settings.loadWarningDescription") }}
        </p>
      </div>
      <div class="right screenshot">
        <PercentageInput
          v-model.number="settings.loadWarn"
          input-id="settings-load-warn"
          input-class="allow-shortcuts"
          :min-value="50"
          :label="$t('label.settings.loadWarning')"
        />
      </div>
    </div>
    <div class="row">
      <div class="left">
        <label for="settings-overload" class="subtitle">
          {{ $t("settings.overloadOnProgramBoard") }}
        </label>
        <p class="description">
          {{ $t("settings.markIterationsWithOverload") }}
        </p>
      </div>
      <div class="right">
        <BaseSwitch
          v-model="settings.overload"
          input-id="settings-overload"
          input-class="allow-shortcuts"
        />
      </div>
    </div>
    <div class="row">
      <div class="left">
        <label for="settings-permanent-team-links" class="subtitle">
          {{ $t("settings.linkLinesSubtitle") }}
        </label>
        <p class="description">
          {{ $t("settings.linkLinesDescription") }}
        </p>
      </div>
      <div class="right">
        <BaseSwitch
          v-model="settings.permanentTeamLinks"
          input-id="settings-permanent-team-links"
          input-class="allow-shortcuts"
        />
      </div>
    </div>
    <div class="row">
      <div class="left">
        <label for="settings-permanent-program-links" class="subtitle">
          {{ $t("settings.linkProgramLinesSubtitle") }}
        </label>
        <p class="description">
          {{ $t("settings.linkLinesDescription") }}
        </p>
      </div>
      <div class="right">
        <BaseSwitch
          v-model="settings.permanentProgramLinks"
          input-id="settings-permanent-program-links"
          input-class="allow-shortcuts"
        />
      </div>
    </div>
    <div class="row">
      <div class="left">
        <label for="settings-lineup-distance" class="subtitle">
          {{ $t("settings.lineupDistanceTitle") }}
        </label>
        <p class="description">
          {{ $t("settings.lineupDistanceDescription") }}
        </p>
      </div>
      <div class="right screenshot">
        <PercentageInput
          v-model.number="settings.initialLineupDistance"
          input-id="settings-lineup-distance"
          input-class="allow-shortcuts"
          :min-value="50"
          :max-value="150"
          :label="$t('label.settings.lineupDistance')"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.settings-page {
  .title {
    font-weight: font.$weight-extra-bold;
    font-size: 20px;
    margin-bottom: -0.5em;

    &:not(:first-child) {
      margin-top: 2em;
    }
  }

  .row {
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:first-child) {
      margin-top: 1.5em;
    }
  }

  .subtitle {
    font-size: 16px;
  }

  .description {
    color: colors-old.$text-secondary-color;
    font-weight: font.$weight-normal;
    margin-top: 0.25em;
  }

  h3 {
    text-align: left;
  }

  .text {
    width: 10%;

    input {
      text-align: right;
    }
  }

  .right {
    padding: 0.5em 0;
    float: right;
    margin-left: 10px;

    &.screenshot {
      padding: 0;

      input {
        text-align: right;
        padding: 0;
        width: 5em;
      }
    }
  }
}
</style>
