import { defineStore } from "pinia";

import { CardCreateProps, cardActions } from "@/action/cardActions";
import { linkActions } from "@/action/linkActions";
import { selectionActions } from "@/action/selectionActions";
import {
  alignCards,
  distributeCards,
  lineupCards,
} from "@/components/utils/layout";
import { ExecutionMode, IdMap } from "@/model/baseTypes";
import { Board } from "@/model/board";
import { Card } from "@/model/card";
import { Team } from "@/model/session";
import { i18n } from "@/translations/i18n";

import { useBoardStore } from "./board";
import { useBoardsStore } from "./boards";
import { useCardStore } from "./card";
import { useClientSettingsStore } from "./clientSettings";
import {
  moveCards,
  resetCardPositions,
  saveCardPositions,
} from "./helpers/selection";

export interface Action<T> {
  name: string;
  execute: (mode: ExecutionMode, state: T) => void;
  saveState: () => T;
  resetToState: (state: T) => void;
}

export interface HistoryEntry<T> {
  stateBeforeAction: T;
  action: Action<T>;
}

interface CardLinking {
  id: string;
  links: Card["links"];
  objectives: Card["objectives"];
}

interface MirroringState {
  id: string;
  teamId: string | null;
  mirroredId: string | undefined;
}

export const useSelectionStore = defineStore("selection", {
  state: () => {
    return {
      selecting: "no" satisfies "no" | "hover" | "click",
      singleCard: false,
      historyPerBoard: {} as IdMap<Array<HistoryEntry<any>>>,
    };
  },
  getters: {
    history: (state) => {
      const boardId = useBoardStore().currentBoard().id;
      if (!(boardId in state.historyPerBoard)) {
        state.historyPerBoard[boardId] = [];
      }
      return state.historyPerBoard[boardId];
    },
    lastHistoryEntry(): HistoryEntry<any> | undefined {
      return this.history[this.history.length - 1];
    },
    mirrorTargets() {
      const cards = useBoardStore().selectedStickyIds.map(
        (id) => useCardStore().cards[id],
      );
      return useBoardsStore().mirrorTargetBoards(cards);
    },
  },
  actions: {
    toggleSelectOnClick() {
      this.selecting = this.selecting === "click" ? "no" : "click";
    },
    undo() {
      const [historyEntry] = this.history.splice(-1);
      historyEntry.action.resetToState(historyEntry.stateBeforeAction);
    },
    execute<T>(mode: ExecutionMode, action: Action<T>) {
      if (mode !== "confirm") {
        this.history.push({ stateBeforeAction: action.saveState(), action });
      }
      action.execute(mode, this.lastHistoryEntry!.stateBeforeAction);
    },
    alignHorizontal(mode: ExecutionMode = "normal") {
      this.execute(mode, {
        name: i18n.global.t(selectionActions.alignHorizontal.data.name),
        execute() {
          const cards = useBoardStore().selectedStickies;
          moveCards(alignCards(cards, "horizontal"), mode);
        },
        saveState: saveCardPositions,
        resetToState: resetCardPositions,
      });
    },
    alignVertical(mode: ExecutionMode = "normal") {
      this.execute(mode, {
        name: i18n.global.t(selectionActions.alignVertical.data.name),
        execute() {
          const cards = useBoardStore().selectedStickies;
          moveCards(alignCards(cards, "vertical"), mode);
        },
        saveState: saveCardPositions,
        resetToState: resetCardPositions,
      });
    },
    distributeHorizontal(mode: ExecutionMode = "normal") {
      this.execute(mode, {
        name: i18n.global.t(selectionActions.distributeHorizontal.data.name),
        execute() {
          const cards = useBoardStore().selectedStickies;
          moveCards(
            distributeCards(alignCards(cards, "horizontal"), "horizontal"),
            mode,
          );
        },
        saveState: saveCardPositions,
        resetToState: resetCardPositions,
      });
    },
    distributeVertical(mode: ExecutionMode = "normal") {
      this.execute(mode, {
        name: i18n.global.t(selectionActions.distributeVertical.data.name),
        execute() {
          const cards = useBoardStore().selectedStickies;
          moveCards(
            distributeCards(alignCards(cards, "vertical"), "vertical"),
            mode,
          );
        },
        saveState: saveCardPositions,
        resetToState: resetCardPositions,
      });
    },
    lineup(mode: ExecutionMode) {
      this.execute(mode, {
        name: i18n.global.t(selectionActions.lineup.data.name),
        execute() {
          moveCards(
            lineupCards(
              useBoardStore().selectedStickies,
              useBoardStore().currentBoard().cardSize,
              0.01 * useClientSettingsStore().initialLineupDistance,
              (pos) => useBoardStore().boardLocation(pos).bounds,
            ),
            mode,
          );
        },
        saveState: saveCardPositions,
        resetToState: resetCardPositions,
      });
    },
    addLinkingToHistory() {
      const stateToBeSaved = this.getCurrentLinkingStateForSelectedCards();
      this.execute("normal", {
        name: i18n.global.t(selectionActions.link.data.name),
        execute() {
          // linking is done in LinkModal
        },
        saveState: () => stateToBeSaved,
        resetToState(cardStates) {
          for (const cardState of cardStates) {
            const card = useCardStore().cards[cardState.id];
            card.links.forEach((newLink) => {
              if (
                !cardState.links.some((oldLink) => oldLink.id === newLink.id)
              ) {
                linkActions.remove(
                  "internal",
                  { linkId: newLink.id },
                  "linking-modal",
                );
              }
            });
            cardState.links.forEach((oldLink) => {
              if (!card.links.some((newLink) => oldLink.id === newLink.id)) {
                linkActions.add(
                  "internal",
                  { id: oldLink.from, toId: oldLink.to },
                  "linking-modal",
                );
              }
            });
            card.objectives.forEach((newObj) => {
              if (
                !cardState.objectives.some((oldObj) => oldObj.id === newObj.id)
              ) {
                linkActions.removeObjective(
                  "internal",
                  card.id,
                  useBoardStore().currentBoard().id,
                  newObj.id,
                  "linking-modal",
                );
              }
            });
            cardState.objectives.forEach((oldObj) => {
              if (!card.objectives.some((newObj) => oldObj.id === newObj.id)) {
                linkActions.addObjective(
                  "internal",
                  card.id,
                  useBoardStore().currentBoard().id,
                  oldObj.id,
                  "linking-modal",
                );
              }
            });
          }
        },
      });
    },
    getCurrentLinkingStateForSelectedCards(): CardLinking[] {
      return useBoardStore().selectedStickies.map((card) => ({
        id: card.data.id,
        links: [...card.data.links],
        objectives: [...card.data.objectives],
      }));
    },
    mirror(board: Board) {
      this.execute("normal", {
        name: i18n.global.t(selectionActions.mirror.data.name),
        execute(mode: ExecutionMode, cardStates) {
          cardStates.forEach((cardState) => {
            cardActions
              .mirror("internal", cardState.id, cardState.teamId, board)
              .then((mirroredId) => {
                cardState.mirroredId = mirroredId;
              });
          });
        },
        saveState: () =>
          useBoardStore().selectedStickies.map(
            (card): MirroringState => ({
              id: card.data.id,
              teamId: card.data.teamId,
              mirroredId: undefined,
            }),
          ),
        resetToState(cardStates) {
          cardStates.forEach((cardState) => {
            if (cardState.mirroredId) {
              cardActions.delete("internal", cardState.mirroredId, board.id);
            }
            if (!cardState.teamId) {
              cardActions.setTeam("internal", cardState.id, null);
            }
          });
        },
      });
    },
    setTeam(team: Team) {
      this.execute("normal", {
        name: i18n.global.t(selectionActions.setTeam.data.name),
        execute() {
          useBoardStore().selectedStickyIds.forEach((id) => {
            cardActions.setTeamAction("internal", id, team.id);
          });
        },
        saveState: () =>
          useBoardStore().selectedStickies.map((card) => ({
            id: card.data.id,
            teamId: card.data.teamId,
            iterationId: card.data.iterationId,
          })),
        resetToState(cardStates) {
          cardStates.forEach((cardState) => {
            cardActions.setTeam("internal", cardState.id, cardState.teamId);
            cardActions.setIteration(
              "internal",
              cardState.id,
              cardState.iterationId,
            );
          });
        },
      });
    },
    paste(cards: CardCreateProps[]) {
      this.execute("normal", {
        name: i18n.global.t(cardActions.pasteMultiple.data.name),
        async execute(mode, state) {
          state.push(...(await cardActions.pasteMultiple("keyboard", cards)));
        },
        saveState: () => new Array<string>(),
        resetToState(cardStates) {
          cardStates.forEach((cardState) => {
            cardActions.delete(
              "internal",
              cardState,
              useBoardStore().currentBoard().id,
            );
          });
        },
      });
    },
    duplicated(cardIds: string[]) {
      this.execute("normal", {
        name: i18n.global.t(cardActions.duplicate.data.name),
        execute() {
          //cards are already duplicated, just register the action for undo
        },
        saveState: () => cardIds,
        resetToState(cardStates) {
          cardStates.forEach((cardState) => {
            cardActions.delete(
              "internal",
              cardState,
              useBoardStore().currentBoard().id,
            );
          });
        },
      });
    },
  },
});
