<script lang="ts" setup>
import { computed, nextTick, ref } from "vue";

import { cardKey, cardMetaKey } from "@/components/card/injectKeys";
import { useBoardStore } from "@/store/board";
import { useZoomStore } from "@/store/zoom";
import { injectStrict } from "@/utils/vue";

import StickyNoteTextDisplay from "./components/StickyNoteTextDisplay.vue";
import StickyNoteTextInput from "./components/StickyNoteTextInput.vue";
import StickyNoteTextWrapper from "./components/StickyNoteTextWrapper.vue";

defineProps<{ notOnBoard?: boolean }>();

const focusAndStartEditing = async () => {
  if (isReadonly) return;

  isEditing.value = true;
  await nextTick();
  textareaRef.value?.focus();
};
defineExpose({ focus: focusAndStartEditing });

const emit = defineEmits(["deactivate-note"]);

const card = injectStrict(cardKey);
const { isReadonly } = injectStrict(cardMetaKey);

const textareaRef = ref<typeof StickyNoteTextInput>();
const textDisplayRef = ref<typeof StickyNoteTextDisplay>();
const containerRef = ref<typeof StickyNoteTextWrapper>();

const isEditing = ref(false);

const textAsButton = computed(
  () => !isReadonly && useBoardStore().activeCardId === card.id,
);

const renderedEl = computed(() => {
  return isEditing.value
    ? textareaRef.value?.element()
    : textDisplayRef.value?.element();
});

const handleBlur = async () => {
  // zooming removes the StickyNote from the DOM and causes a blur event, ignore it
  if (useZoomStore().zooming) return;

  isEditing.value = false;
  await nextTick();
  containerRef.value?.recalculateFontSize(card.text);
};
</script>

<template>
  <StickyNoteTextWrapper
    ref="containerRef"
    :not-on-board="notOnBoard"
    :rendered-el="renderedEl"
    :is-editing="isEditing"
  >
    <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
    <template #default="{ fontSize, lines }">
      <StickyNoteTextInput
        v-if="isEditing"
        ref="textareaRef"
        :font-size="fontSize"
        @deactivate-note="emit('deactivate-note')"
        @blur="handleBlur"
      />
      <StickyNoteTextDisplay
        v-else
        ref="textDisplayRef"
        :font-size="fontSize"
        :lines="lines"
        :role="textAsButton ? 'button' : 'presentation'"
        :tabindex="textAsButton ? 0 : undefined"
        @keydown.enter.stop.prevent="focusAndStartEditing"
        @keydown.space.stop.prevent="focusAndStartEditing"
      />
    </template>
  </StickyNoteTextWrapper>
</template>
