import SwitchTeamboardModal from "@/components/modal/SwitchTeamboardModal.vue";
import FlexModal from "@/components/modal/flex/FlexModal.vue";
import { namedKey } from "@/components/utils/Shortcuts";
import { BoardType } from "@/model/baseTypes";
import { Art, Team } from "@/model/session";
import { currentRoute, goToBoard, navigateBack } from "@/router/navigation";
import { useBoardStore } from "@/store/board";
import { useBoardsStore } from "@/store/boards";
import { useFlexStore } from "@/store/flex";
import { useModalStore } from "@/store/modal";
import { useTeamStore } from "@/store/team";
import { boardChanged } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { nextElement } from "@/utils/list";

import { action, defineActions } from "./actions";

export const navigationActions = defineActions("navigation", {
  goToBacklog: action(trackedGoToBoard.bind(null, "backlog"), {
    name: /*$t*/ "action.backlogBoard",
    shortcut: namedKey("1", { hideKey: true }),
    icon: "board/backlog",
  }),
  goToProgram: action(trackedGoToBoard.bind(null, "program"), {
    name: /*$t*/ "action.programBoard",
    shortcut: namedKey("2", { hideKey: true }),
    icon: "board/program",
  }),
  goToSolutionBacklog: action(trackedGoToBoard.bind(null, "solution_backlog"), {
    name: /*$t*/ "action.solutionBacklogBoard",
    icon: "board/solution-backlog",
  }),
  goToSolution: action(trackedGoToBoard.bind(null, "solution"), {
    name: /*$t*/ "action.solutionBoard",
    icon: "board/solution",
  }),
  goToRisk: action(trackedGoToBoard.bind(null, "risk"), {
    name: /*$t*/ "action.riskBoard",
    shortcut: namedKey("3", { hideKey: true }),
    icon: "board/risk",
  }),
  goToTeam: action(
    (trigger: "sidebar-menu" | "keyboard-shortcut") => {
      if (currentRoute().params.board === "team") {
        useModalStore().open(SwitchTeamboardModal);
      } else {
        trackedGoToBoard("team", trigger);
      }
    },
    {
      name: /*$t*/ "action.teamBoard",
      shortcut: namedKey("4", { hideKey: true }),
      icon: "board/team",
    },
  ),
  goToNextTeam: action(
    () => goToBoard({ type: "team", team: findNextTeam(1) }),
    {
      shortcut: namedKey("Digit4", { modifiers: ["altCtrl"] }),
    },
  ),
  goToPreviousTeam: action(
    () => goToBoard({ type: "team", team: findNextTeam(-1) }),
    {
      shortcut: namedKey("Digit4", {
        modifiers: ["altCtrl", "shift"],
      }),
    },
  ),

  goToCanvas: action(() => useModalStore().open(FlexModal), {
    name: /*$t*/ "action.canvases",
    shortcut: namedKey("5", { hideKey: true }),
    icon: "board/canvas",
  }),
  goToNextCanvas: action(
    () => {
      if (useFlexStore().flexBoards.length > 0) {
        goToBoard({ type: "flex", name: findNextFlex(1).name });
      }
    },
    {
      shortcut: namedKey("Digit5", {
        modifiers: ["altCtrl"],
      }),
    },
  ),
  goToPreviousCanvas: action(
    () => {
      if (useFlexStore().flexBoards.length > 0) {
        goToBoard({ type: "flex", name: findNextFlex(-1).name });
      }
    },
    {
      shortcut: namedKey("Digit5", {
        modifiers: ["altCtrl", "shift"],
      }),
    },
  ),

  goToObjectives: action(() => goToBoard("objectives"), {
    name: /*$t*/ "action.objectivesBoard",
    shortcut: namedKey("6", { hideKey: true }),
    icon: "board/objectives",
  }),
  goBack: action(navigateBack, {
    name: /*$t*/ "userAction.mainMenu",
    shortcut: namedKey("KeyQ"),
  }),
  changeArt: action((art: Art) =>
    goToBoard({ type: useBoardStore().currentBoard().type, artId: art.id }),
  ),
  changeTeam: action((team: Team) =>
    goToBoard({ type: useBoardStore().currentBoard().type, team }),
  ),
  selectTeam: action((team: Team) => useTeamStore().selectTeam(team)),
});

function findNextTeam(step: number) {
  return nextElement(
    useTeamStore().teamsInCurrentArt,
    (team) => team.id === useTeamStore().team.current.id,
    step,
  );
}

function findNextFlex(step: number) {
  return nextElement(
    useFlexStore().flexBoards,
    (board) => board.id === useBoardsStore().boardByType("flex").id,
    step,
  );
}

function trackedGoToBoard(
  targetBoard: BoardType,
  trigger?: "sidebar-menu" | "keyboard-shortcut",
) {
  const currentBoard = useBoardStore().currentBoard().type;
  const t = trigger || "keyboard-shortcut";

  trackEvent(boardChanged(currentBoard, targetBoard, t));
  goToBoard(targetBoard);
}
