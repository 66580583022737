import { onUnmounted } from "vue";

import { EventType } from "@/mixins/EventBusUser";
import { ServerTime } from "@/model/timer";
import { SearchQuery } from "@/router/types";

import EventBus from "./EventBus";

export function useEventBus() {
  const callbacks = new Array<{
    name: EventType;
    action: (e: unknown) => void;
  }>();

  onUnmounted(() => {
    for (const callback of callbacks) {
      EventBus.off(callback.name, callback.action);
    }
  });

  function onBoardSwitch(callback: () => void) {
    onEvent("boardSwitch", callback);
  }

  function onSearch(callback: (search: SearchQuery) => void) {
    onEvent("search", callback);
  }

  function onServerTick(callback: (serverTime: ServerTime) => void) {
    onEvent("serverTick", callback);
  }

  function onEvent(name: EventType, action: (e?: any) => void) {
    callbacks.push({ name, action });
    EventBus.on(name, action);
  }

  return { onBoardSwitch, onSearch, onServerTick };
}
