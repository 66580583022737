<script setup lang="ts">
import { debounce } from "lodash-es";
import { computed, ref, watch } from "vue";

import { searchStickies } from "@/backend/Backend";
import { SearchStickiesQuery } from "@/backend/BackendSession";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { keyboardNavigation } from "@/directives/keyboardNavigation";
import { SearchResult } from "@/model/search";
import { goToBoard } from "@/router/navigation";
import { useBoardStore } from "@/store/board";
import { useBoardsStore } from "@/store/boards";
import SinglePendingPromise from "@/utils/async/SinglePendingPromise";

import GroupedByTypeSearchResults from "./GroupedByTypeSearchResults.vue";
import {
  StickyInfoKey,
  groupSearchResultsByArt,
  sortSearchResults,
} from "./SearchResult";

// Unique instance of the directive so we can render multiple
// components with this directive at once
const vKeyboardNav = keyboardNavigation();

const props = defineProps<{ query: SearchStickiesQuery }>();

const results = ref<SearchResult[]>([]);
const searching = ref(false);

const backendSearchPromise = new SinglePendingPromise();
const backendSearch = debounce(
  (query: SearchStickiesQuery, action: (results: SearchResult[]) => void) => {
    backendSearchPromise.execute(
      () => searchStickies(query),
      (results) => action(results),
    );
  },
  500,
  { leading: true },
);

watch(() => props.query, search, { immediate: true });

const resultsByArt = computed(() => {
  const searchResults = groupSearchResultsByArt(results.value);
  const currentArtIndex = searchResults.findIndex(
    (item) => item.artId === currentArtId.value,
  );
  if (currentArtIndex > -1) {
    searchResults.splice(currentArtIndex, 1);
  }
  return searchResults;
});

const currentArtSearchResult = computed(() => {
  const searchResults = groupSearchResultsByArt(results.value);
  return searchResults.find((item) => item.artId === currentArtId.value);
});

const currentBoardId = computed(() => useBoardStore().currentBoard().id);
const currentArtId = computed(
  () => useBoardsStore().boardById(currentBoardId.value).artId,
);
const showCurrentArt = computed(() => {
  if (!currentArtSearchResult.value) {
    return false;
  }
  const anotherBoardIndex = currentArtSearchResult.value.boards.findIndex(
    (item) => item.boardId !== currentBoardId.value,
  );
  return anotherBoardIndex > -1;
});
const showOtherBoardsSearchResults = computed(
  () =>
    !searching.value && (showCurrentArt.value || resultsByArt.value.length > 0),
);

function search() {
  backendSearchPromise.cancel();
  searching.value = true;
  backendSearch(props.query, (backendResults) => {
    backendResults = backendResults.map((item) => ({
      ...item,
      artId: useBoardsStore().boardById(item.boardId).artId,
    }));
    results.value = sortSearchResults(
      backendResults.filter(
        (result) => result.boardId !== currentBoardId.value,
      ),
    );
    searching.value = false;
  });
}

function isCurrentBoard(boardId: string): boolean {
  return currentBoardId.value === boardId;
}

function select(item: StickyInfoKey) {
  goToBoard(useBoardsStore().boardById(item.boardId!), { searchId: item.id });
}
</script>

<template>
  <div
    v-if="showOtherBoardsSearchResults"
    v-keyboard-nav.soft-focus.ignore-trigger="{
      selector: '.result-card',
    }"
    role="listbox"
    :aria-label="$t('label.search.otherBoardResults')"
  >
    <div class="message">
      {{ $t("searchResults.resultsBoards") }}
    </div>
    <template v-if="showCurrentArt">
      <div v-if="currentArtSearchResult?.artName.trim()" class="art-container">
        <SvgIcon
          class="art-icon"
          name="action-menu/art"
          width="20"
          height="20"
        />
        <div class="art-name">{{ currentArtSearchResult.artName }}</div>
      </div>
      <div
        v-for="board in currentArtSearchResult?.boards"
        :key="board.boardId"
        role="group"
      >
        <template v-if="!isCurrentBoard(board.boardId)">
          <div class="action location">
            <div class="header">{{ board.boardName }}</div>
            <div>
              {{ board.count }}
            </div>
          </div>
          <GroupedByTypeSearchResults
            :items="board.items"
            :board-id="board.boardId"
            @select="select"
          />
        </template>
      </div>
    </template>

    <div v-for="art in resultsByArt" :key="art.artId">
      <div v-if="art.artName.trim()" class="art-container">
        <SvgIcon
          class="art-icon"
          name="action-menu/art"
          width="20"
          height="20"
        />
        <div class="art-name">{{ art.artName }}</div>
      </div>

      <div v-for="board in art.boards" :key="board.boardId" role="group">
        <div class="action location">
          <div class="header">{{ board.boardName }}</div>
          <div>
            {{ board.count }}
          </div>
        </div>
        <GroupedByTypeSearchResults
          :items="board.items"
          :board-id="board.boardId"
          @select="select"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

#search-results {
  .message {
    font-size: font.$size-normal;
    font-weight: font.$weight-bold;
    color: colors-old.$text-secondary-color;
    margin-top: 20px;
    padding: 16px;
  }

  .art-container {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-left: 16px;
    margin-top: 20px;

    .art-icon {
      color: colors-old.$text-secondary-color;
    }

    .art-name {
      font-size: font.$size-normal;
      font-weight: font.$weight-bold;
    }
  }

  .location {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 16px 0;

    & div:nth-child(1) {
      font-weight: font.$weight-bold;
      font-size: font.$size-small;
      color: colors-old.$text-secondary-color;
    }

    & div:nth-child(2) {
      font-weight: font.$weight-medium;
      font-size: font.$size-small;
      color: colors-old.$text-secondary-color;
    }
  }
}
</style>
