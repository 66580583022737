import { noop } from "lodash-es";

import CardFlag from "@/model/CardFlag";
import { ArtId, BoardType, StatusClass } from "@/model/baseTypes";
import { Board } from "@/model/board";
import { Reaction } from "@/model/card";
import { Iteration, SessionAlmStatus, Team } from "@/model/session";
import { Shape } from "@/model/shape";
import { StickyType } from "@/model/stickyType";
import { TimerData } from "@/model/timer";
import { asyncNoop } from "@/utils/general";

import { EventInfo } from "./EventInfo";
import {
  ServerAlmItemType,
  ServerAlmItemTypeMap,
  ServerAlmSource,
  ServerAlmStickyTypeData,
  ServerAlmType,
  ServerArt,
  ServerBacklogSyncState,
  ServerBoard,
  ServerBoardIteration,
  ServerCategory,
  ServerEvent,
  ServerFlexBackground,
  ServerFlexBoard,
  ServerFlexType,
  ServerInfo,
  ServerIteration,
  ServerIterationSyncState,
  ServerLicense,
  ServerLink,
  ServerLinkType,
  ServerMirrorResponse,
  ServerObjective,
  ServerObjectives,
  ServerSearchResult,
  ServerSession,
  ServerSettings,
  ServerStatusWithData,
  ServerStickiesUpdate,
  ServerSticky,
  ServerStickyChange,
  ServerStickyGroupBoardIds,
  ServerStickyType,
  ServerTeam,
} from "./serverModel";

export type SubscriptionLevel = "room" | "session" | "board";

export interface SearchStickiesQuery {
  text?: string;
  teams?: Team[];
  types?: StickyType[];
  flags?: CardFlag[];
  statusClasses?: StatusClass[];
  boards?: Board[];
  iterations?: Iteration[];
}

export interface Subscription {
  drain(): Promise<void>;

  unsubscribe(): void;

  getID(): string | number;

  getSubject(): string;
}

export function createDummySubscription(path: string) {
  return {
    drain: asyncNoop,
    unsubscribe: noop,
    getSubject: () => path,
    getID: () => "<dummy>",
  };
}

export type SubscriptionHandler<TArgs, TKwargs = unknown> = (
  args: TArgs,
  kwargs: TKwargs,
  event: EventInfo,
) => void;

export interface BackendSession {
  unsubscribe(
    level: SubscriptionLevel,
    connectionOpen?: boolean,
  ): Promise<unknown>;

  setSessionId(id: string): Promise<boolean>;

  almSource(
    boardType: ServerBoard["board_type"],
    {
      teamId,
      artId,
    }: { teamId?: ServerBoard["user_id"]; artId: ServerBoard["art_id"] },
  ): ServerAlmSource | undefined;

  subscriptionCount(): number;

  getAlmType(): ServerAlmType;

  getAlmConnectionId(): string | undefined;

  getLicense(): Promise<ServerLicense>;

  getServerInfo(useHttp?: boolean): Promise<ServerInfo>;

  getStatusWithData(
    sizeMB: number,
    useHttp?: boolean,
  ): Promise<ServerStatusWithData>;

  getSessions(): Promise<ServerSession[]>;

  getAlmStatus(): Promise<SessionAlmStatus>;

  hasMappedAlmUser(id?: string): Promise<boolean>;

  getSettings(): Promise<ServerSettings>;

  getTeams(): Promise<ServerTeam[]>;

  getArts(): Promise<ServerArt[]>;

  getIterations(sessionId: string): Promise<ServerIteration[]>;

  getLinks(): Promise<ServerLink[]>;

  getLinkTypes(): Promise<ServerLinkType[]>;

  getBoards(sessionId?: string): Promise<Array<ServerBoard | ServerFlexBoard>>;

  addBoard(type: string, name: string): Promise<string>;

  updateBoard(id: string, name: string): Promise<void>;

  deleteBoard(id: string): Promise<void>;

  almSubscribe<TArgs>(
    path: string,
    handler: SubscriptionHandler<TArgs>,
  ): Promise<Subscription> | Subscription;

  roomSubscribe<TArgs>(
    path: string,
    handler: SubscriptionHandler<TArgs>,
  ): Promise<Subscription> | Subscription;

  getScale(boardId: string): Promise<number>;

  getBoardStickies(
    boardId: string,
    since?: number,
  ): Promise<ServerSticky[] | ServerStickiesUpdate>;

  getStickies(stickyIds: string[]): Promise<ServerSticky[]>;

  getBoardIterations(boardId: string): Promise<ServerBoardIteration[]>;

  getStickyTypes(): Promise<ServerStickyType[]>;

  getAlmItemType(
    almType: string,
    boardType: BoardType,
    boardKeys: string[],
  ): Promise<ServerAlmItemTypeMap>;

  getAlmItemTypeForSticky(stickyAlmId: string): Promise<ServerAlmItemType>;

  getObjectives(boardId: string): Promise<ServerObjectives>;

  newSticky(boardId: string, props: Partial<ServerSticky>): Promise<string>;

  mirror(
    cardId: string,
    toBoardId: string,
    props?: Partial<ServerSticky>,
  ): Promise<ServerMirrorResponse>;

  move(cardId: string, teamId: string): void;

  toRisk(
    cardId: string,
    boardId: string,
    text: string,
    typeId: string,
    teamId: string,
  ): void;

  addLink(cardId: string, toId: string): Promise<ServerLink>;

  addObjectiveLink(
    cardId: ServerSticky["_id"],
    boardId: ServerBoard["board_id"],
    objectiveId: ServerObjective["objective_id"],
  ): Promise<void>;

  deleteLink(id: string): Promise<void>;

  deleteObjectiveLink(
    cardId: ServerSticky["_id"],
    boardId: ServerBoard["board_id"],
    objectiveId: ServerObjective["objective_id"],
  ): Promise<void>;

  syncIteration(
    teamId: string,
    iterationId: number,
  ): Promise<ServerIterationSyncState>;

  syncBacklog(artId?: ArtId): Promise<ServerBacklogSyncState>;

  sessionSubscribe<TArgs>(
    path: string,
    handler: SubscriptionHandler<TArgs>,
  ): Promise<Subscription> | Subscription;

  boardSubscribe<TArgs>(
    boardId: string,
    path: string,
    level: SubscriptionLevel,
    handler: SubscriptionHandler<TArgs>,
  ): Promise<Subscription> | Subscription;

  boardPublish(boardId: string, path: string, value: unknown): void;

  getFlexBackgrounds(): Promise<ServerFlexBackground[]>;

  getFlexTypes(sessionId?: string): Promise<ServerFlexType[]>;

  getCategories(): Promise<ServerCategory[]>;

  addCategory(name: string): Promise<string>;

  deleteCategory(id: string): Promise<void>;

  updateCategory(
    id: string,
    update: { name?: string; position?: number },
  ): Promise<void>;

  addBoardToCategory(boardId: string, categoryId: string): Promise<void>;

  removeBoardFromCategory(boardId: string, categoryId: string): Promise<void>;

  searchStickies(query: SearchStickiesQuery): Promise<ServerSearchResult[]>;

  getEvents(): Promise<ServerEvent[]>;

  createTimerEvent(
    data: Partial<TimerData>,
    target: { boardId?: string; artId?: string },
  ): Promise<string>;

  updateTimerEvent(id: string, data: Partial<TimerData>): Promise<void>;

  deleteEvent(id: string): Promise<void>;

  addReaction(cardId: string, reaction: Reaction): Promise<void>;

  removeReaction(cardId: string, reaction: Reaction): Promise<void>;

  addShape(boardId: string, shape: Shape): Promise<string>;

  editShape(boardId: string, shape: Shape): Promise<void>;

  removeShape(boardId: string, shapeId: string): Promise<void>;

  getAlmStickyTypes(): Promise<ServerAlmStickyTypeData[]>;

  getBoardIdsOfGroupedStickies(id: string): Promise<ServerStickyGroupBoardIds>;

  getStickyChanges(id: string): Promise<ServerStickyChange[]>;

  duplicateFlex(boardId: string, sessionId: string): Promise<void>;

  getBoardDiff(id: string, from: Date, to: Date): Promise<ServerStickyChange[]>;
}
