import { Card } from "@/model/card";
import { LinkableObjective2 } from "@/model/link";
import { Objective } from "@/model/objective";
import { useBoardsStore } from "@/store/boards";
import { useObjectiveStore } from "@/store/objective";

import {
  GroupedLinkableItems,
  LinkableItemGroup,
  LinkableItemGroups,
  SearchContext,
} from "../types";

export function groupedLinkableObjectives(
  targetCards: Card[],
  searchContext: SearchContext,
): GroupedLinkableItems {
  return getGroupedObjectives();

  function getGroupedObjectives(): GroupedLinkableItems {
    const groupedObjectives = getGroupObjectivesByBoardGroups(
      searchContext.query,
    );

    return {
      currentLevel: extractCurrentLevel(groupedObjectives),
      otherLevels: searchContext.query ? groupedObjectives : {},
    };
  }

  function extractCurrentLevel(
    groupedObjectives: LinkableItemGroups,
  ): LinkableItemGroup {
    if (
      searchContext.level === "none" ||
      searchContext.level === "solution-train"
    ) {
      return { level: searchContext.level, items: [] };
    }
    const currentLevelItems = groupedObjectives[searchContext.id]?.items ?? [];
    delete groupedObjectives[searchContext.id];
    return { level: searchContext.level, items: currentLevelItems };
  }

  function getGroupObjectivesByBoardGroups(query: string): LinkableItemGroups {
    const boards = Object.values(useBoardsStore().boards);
    const objectiveGroups: LinkableItemGroups = {};

    boards.forEach((board) => {
      const isTeam = board.type === "team";
      const isProgram = board.type === "program";
      if (isTeam || isProgram) {
        const objectives = [...board.objectives, ...board.stretchObjectives]
          .map((objective) => makeObjectiveLink(objective, board.id))
          .filter(
            (objective) =>
              query.length === 0 ||
              objective.text.toLowerCase().includes(query) ||
              objective.description.toLowerCase().includes(query),
          );
        if (objectives.length !== 0) {
          objectiveGroups[isTeam ? board.team.id : board.artId ?? "NO-ART"] = {
            level: isTeam ? "team" : "art",
            items: objectives,
          };
        }
      }
    });
    return objectiveGroups;
  }

  function makeObjectiveLink(
    objective: Objective,
    boardId: string,
  ): LinkableObjective2 {
    const linked = targetCards.every((card) =>
      card.objectives.some(({ id }) => objective.id === id),
    );
    return {
      kind: "objective",
      linked,
      id: objective.id,
      text: objective.text,
      boardId: boardId,
      description: objective.description,
      type: useObjectiveStore().objectiveTypeById(objective.id, {
        boardId,
      }),
    };
  }
}
