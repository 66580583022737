<script setup lang="ts">
import { computed, nextTick, onMounted, ref } from "vue";

import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";

import { TreeNodeData } from "../types";

const props = defineProps<{
  node: TreeNodeData;
  selectedNode: string | null;
  indentation: number;
  isFirstNode: boolean;
  isLastNode: boolean;
}>();

const emit = defineEmits<(e: "selectNode", node: TreeNodeData) => void>();

const isExpanded = ref(false);
const hasChildren = computed(() => props.node.children.length > 0);
const isSelected = computed(() => props.selectedNode === props.node.id);
const isDisabled = computed(() => props.node.disabled);

const treeNodeRef = ref<HTMLElement | null>(null);

const toggleExpand = () => {
  isExpanded.value = !isExpanded.value;
};

const selectNode = () => {
  if (!isDisabled.value) {
    emit("selectNode", props.node);
  }
};

const focusNode = () => {
  nextTick(() => {
    treeNodeRef.value?.focus();
  });
};

const handleKeydown = (event: KeyboardEvent) => {
  if (!treeNodeRef.value) return;

  const currentNode = event.target as HTMLElement;
  const focusableNodes = Array.from(
    document.querySelectorAll('[role="treeitem"]'),
  ) as HTMLElement[];
  const currentIndex = focusableNodes.indexOf(currentNode);

  switch (event.key) {
    case "ArrowRight":
      if (hasChildren.value && !isExpanded.value) {
        toggleExpand();
      } else if (hasChildren.value && isExpanded.value) {
        focusableNodes[currentIndex + 1]?.focus();
      }
      break;

    case "ArrowLeft":
      if (hasChildren.value && isExpanded.value) {
        toggleExpand();
      } else if (currentIndex > 0) {
        focusableNodes[currentIndex - 1]?.focus();
      }
      break;

    case "ArrowUp":
      if (currentIndex > 0) {
        focusableNodes[currentIndex - 1]?.focus();
      }
      break;

    case "ArrowDown":
      if (currentIndex < focusableNodes.length - 1) {
        focusableNodes[currentIndex + 1]?.focus();
      }
      break;

    case "Enter":
    case " ":
      event.preventDefault();
      selectNode();
      break;

    case "Home":
      focusableNodes[0]?.focus();
      break;

    case "End":
      focusableNodes[focusableNodes.length - 1]?.focus();
      break;
  }
};

onMounted(() => {
  // Set focus on the selected node or the first node
  if (isSelected.value || props.isFirstNode) {
    focusNode();
  }
});
</script>

<template>
  <div
    ref="treeNodeRef"
    :class="[
      'tree-node',
      {
        'tree-node-selected': isSelected,
      },
    ]"
    role="treeitem"
    v-bind="hasChildren && { 'aria-expanded': isExpanded }"
    :aria-selected="isSelected"
    :aria-disabled="isDisabled"
    :data-testid="`tree-node-${node.label}`"
    tabindex="0"
    @click="selectNode"
    @keydown="handleKeydown"
  >
    <div
      class="tree-node-content"
      :style="{ paddingLeft: `${indentation}rem` }"
    >
      <button
        v-if="hasChildren"
        class="tree-node-expand-button"
        :aria-label="isExpanded ? 'Collapse' : 'Expand'"
        :data-testid="`expand-button-${node.label}`"
        tabindex="-1"
        @click.stop="toggleExpand"
      >
        <SvgIcon
          :name="isExpanded ? 'thin/chevron-down' : 'thin/chevron-right'"
          class="collapse-icon"
          :class="{ 'icon-selected': isSelected }"
          width="20"
          height="20"
        />
      </button>

      <SvgIcon
        v-if="node.icon"
        :name="node.icon"
        class="tree-node-icon"
        :class="{ 'icon-selected': isSelected }"
        role="presentation"
        width="20"
        height="20"
      />

      <span class="tree-node-label">
        {{ node.label }}
      </span>
    </div>
  </div>

  <!-- Children Nodes -->
  <div v-if="hasChildren && isExpanded" role="group">
    <TreeNode
      v-for="(child, index) in node.children"
      :key="child.id"
      :node="child"
      :selected-node="selectedNode"
      :indentation="indentation + 2"
      :is-first-node="index === 0"
      :is-last-node="index === node.children.length - 1"
      @select-node="emit('selectNode', $event)"
    />
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;

.tree-node {
  cursor: pointer;
  width: 100%;

  &-selected {
    color: colors-old.$primary-color;
    border-radius: 4px;
    background-color: rgba(
      colors-old.$primary-color,
      colors-old.$background-color-transparency
    );
  }

  &:not(.tree-node-selected):hover {
    background-color: colors-old.$light-background-color;
    border-radius: 4px;
  }

  .tree-node-content {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    gap: 8px;
    width: 100%;
    box-sizing: border-box;
  }

  .tree-node-expand-button {
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;

    .collapse-icon {
      color: colors-old.$text-secondary-color;

      &.icon-selected {
        color: colors-old.$primary-color;
      }
    }
  }

  .tree-node-icon {
    color: colors-old.$text-secondary-color;

    &.icon-selected {
      color: colors-old.$primary-color;
    }
  }

  .tree-node-label {
    font-size: font.$size-normal;
    font-weight: font.$weight-medium;
    flex-grow: 1;
  }
}
</style>
