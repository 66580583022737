<script setup lang="ts">
import { computed } from "vue";

import SearchResultsItem from "@/components/Search/SearchResultsItem.vue";
import { Card } from "@/model/card";
import { useSessionStore } from "@/store/session";
import { useTeamStore } from "@/store/team";

const props = defineProps<{
  card: Card;
  showTeam?: boolean;
  showStatus?: boolean;
}>();

const cardContent = computed(() => {
  const team = props.card.teamId
    ? useTeamStore().findTeam({ id: props.card.teamId })
    : undefined;
  return {
    id: props.card.id,
    text: props.card.text,
    type: props.card.type,
    flag: props.card.flagType,
    almId: props.card.almId,
    status: props.card.status,
    team,
    iteration: useSessionStore().getIterationById(props.card.iterationId),
  };
});
</script>

<template>
  <SearchResultsItem
    class="search-results-item"
    :item="cardContent"
    :show-status="showStatus"
    :show-team="showTeam"
  />
</template>

<style lang="scss" scoped>
.search-results-item {
  padding: 0.625rem 0 !important;
}
</style>
