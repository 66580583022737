<script setup lang="ts">
import { ref } from "vue";

import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { Icon } from "@/model/icon";

import BaseModal from "../BaseModal.vue";
import OrganizationTree from "./components/OrganizationTree.vue";
import SearchInputSelfFocusing from "./components/SearchInputSelfFocusing.vue";
import SearchResults from "./components/SearchResults.vue";
import { Level, OrganizationTreeNodeData, SearchContext } from "./types";

defineProps<{ cardIds: string[]; setReturnFocus?: string }>();

const searchContext = ref<SearchContext>({ query: "", level: "none" });

const isOrganisationTreeCollapsed = ref(false);
const organisationNodeIcon = ref<Icon>("general/side-panel");
const searchLabel = ref<string | null>(null);

const levelIconMap: Record<Level, Icon> = {
  "solution-train": "action-menu/solution-train",
  art: "action-menu/art",
  team: "action-menu/team",
  none: "general/side-panel",
};

const handleOrganisationNodeSelect = (node: OrganizationTreeNodeData) => {
  searchContext.value = { ...searchContext.value, ...node.levelContext };
  searchLabel.value = node.label;
  organisationNodeIcon.value = levelIconMap[node.levelContext.level];
};
</script>

<template>
  <BaseModal
    :title="$t('linkModal.header')"
    :description="$t('linkModal.description')"
    :set-return-focus="setReturnFocus"
    class="modal-container"
    width="900px"
  >
    <template #header-content>
      <SearchInputSelfFocusing
        v-model="searchContext.query"
        v-autofocus
        wrapper-class="link-modal-search-input"
        :title="$t('label.linkModal.searchTitle')"
      />
    </template>
    <div class="sticky-link-modal-container">
      <div
        class="organisation-tree-container"
        :class="{ collapsed: isOrganisationTreeCollapsed }"
      >
        <div class="organisation-tree-header">
          <div
            v-show="!isOrganisationTreeCollapsed"
            class="organisation-tree-text"
          >
            {{ $t("linkModal.organization") }}
          </div>
          <button
            data-testid="collapse-button"
            class="collapse-icon-container"
            @click="isOrganisationTreeCollapsed = !isOrganisationTreeCollapsed"
          >
            <SvgIcon
              :name="
                isOrganisationTreeCollapsed
                  ? organisationNodeIcon
                  : 'general/side-panel'
              "
              class="collapse-icon"
              width="16"
              height="18"
            />
          </button>
        </div>
        <div
          v-show="!isOrganisationTreeCollapsed"
          class="organisation-tree-content"
        >
          <OrganizationTree @select="handleOrganisationNodeSelect" />
        </div>
      </div>
      <div
        class="search-results-container"
        :class="{ 'search-results-expanded': isOrganisationTreeCollapsed }"
      >
        <div class="search-results-header">
          <div class="search-results-text">
            {{ searchLabel ?? $t("linkModal.searchResults") }}
          </div>
          <div class="search-results-filter"></div>
        </div>
        <div class="search-results-content">
          <SearchResults :card-ids="cardIds" :search-context="searchContext" />
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/mixins/utils";
@use "@/styles/mixins/typography";

$organisation-tree-width: 300px;

.modal-container :deep(.modal .body.scrollable) {
  padding: unset;
  max-height: unset;
  height: 550px;
}

.link-modal-search-input {
  padding-right: 24px;
  margin-bottom: 16px;
  width: $organisation-tree-width;
}

.sticky-link-modal-container {
  display: flex;
  flex-grow: 1;
  height: 100%;

  .organisation-tree-container {
    display: flex;
    flex-direction: column;
    width: $organisation-tree-width;
    transition: width 0.3s ease;
    border-right: 1px solid colors-old.$divider-color;
    overflow-x: hidden;

    .organisation-tree-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 9px 9px 9px 24px;
      transition: padding 0.3s ease;
      border-bottom: 1px solid colors-old.$divider-color;

      .organisation-tree-text {
        @include typography.medium("bold");
      }

      .collapse-icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 32px;
        width: 32px;

        .collapse-icon {
          color: colors-old.$text-secondary-color;
        }

        &:hover {
          background-color: colors-old.$hover-input-border-color;
          border-radius: 6px;

          .collapse-icon {
            color: unset;
          }
        }
      }
    }

    .organisation-tree-content {
      margin-top: 8px;
      padding: 4px;
      width: $organisation-tree-width;
      overflow-y: auto;

      @include utils.hide-scrollbar;
    }

    &.collapsed {
      width: 56px;

      .organisation-tree-header {
        justify-content: center;
        padding: 12px;
        border-bottom: unset;
      }
    }
  }

  .search-results-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 600px;
    transition: width 0.3s ease;
    overflow-x: hidden;

    &.search-results-expanded {
      width: calc(100% - 56px);
    }

    .search-results-header {
      flex-shrink: 0;
      padding: 16px 16px 16px 24px;
      border-bottom: 1px solid colors-old.$divider-color;

      .search-results-text {
        @include typography.medium("bold");
      }
    }

    .search-results-content {
      flex-grow: 1;
      overflow-y: auto;

      @include utils.hide-scrollbar;
    }
  }
}
</style>
