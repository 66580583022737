import { defineStore } from "pinia";

import CardFlag from "@/model/CardFlag";
import {
  InfoLevel,
  StatusClass,
  statusClassNames,
  statusClasses,
} from "@/model/baseTypes";
import { BoardCard } from "@/model/card";
import { statusClassColors } from "@/model/colors";
import { Link } from "@/model/link";
import { DependencyTeamFilter, DependencyTeamItem } from "@/model/search";
import { Art, Iteration, Team } from "@/model/session";
import { StickyType, isDependency } from "@/model/stickyType";
import { AuthUser } from "@/model/user";
import { i18n } from "@/translations/i18n";
import { toCssColor, white } from "@/utils/color";

import { NO_ART_ID, useArtStore } from "./art";
import { useBoardStore } from "./board";
import { useSessionStore } from "./session";
import { NO_TEAM_ID, useTeamStore } from "./team";
import { noUser, useUserStore } from "./user";

export const dependencyLinks = ["Linked", "Not Linked"];
export type DependencyLink = (typeof dependencyLinks)[number];

interface DependencyLinkItem {
  id: DependencyLink;
  name: string;
}

export const dependencyLinkItems: DependencyLinkItem[] = [
  { id: "Linked", name: /*$t*/ "dependency.linked" },
  { id: "Not Linked", name: /*$t*/ "dependency.notLinked" },
];

export const statusClassItems = statusClasses.map((statusClass) => ({
  id: statusClass,
  name: statusClassNames[statusClass],
  color: statusClassColors[statusClass],
}));

export interface State {
  text: string;
  id?: string;
  isSearchSidebarOpen: boolean; // Whether the search sidebar is open
  // (see anyFilterCriteria for whether there are any active filters / search terms)
  selectedStickyTypeIds: Set<string>;
  selectedDependencyLink: Set<DependencyLink>;
  selectedDependencyTeamFilter: DependencyTeamFilter;
  selectedLinkStates: Set<InfoLevel>;
  selectedAssigneeIds: Set<string>;
  selectedTeamIds: Set<string>;
  selectedArtIds: Set<string>;
  selectedIterationIds: Set<number>;
  selectedFlagStrings: Set<string>;
  selectedStatusClasses: Set<StatusClass>;
}

export const useSearchMenuStore = defineStore("searchMenu", {
  state: (): State => {
    return {
      text: "",
      isSearchSidebarOpen: false,
      selectedStickyTypeIds: new Set(),
      selectedLinkStates: new Set(),
      selectedAssigneeIds: new Set(),
      selectedTeamIds: new Set(),
      selectedArtIds: new Set(),
      selectedIterationIds: new Set(),
      selectedFlagStrings: new Set<string>(),
      selectedStatusClasses: new Set(),
      selectedDependencyLink: new Set(),
      selectedDependencyTeamFilter: "IncomingAndOutgoing",
    };
  },
  getters: {
    selectedRiskyLinks(state): InfoLevel[] {
      return Array.from(state.selectedLinkStates);
    },
    selectedStickyTypes(state): StickyType[] {
      return useBoardStore().possibleStickyTypes.filter((type) =>
        state.selectedStickyTypeIds.has(type.id),
      );
    },
    selectedDependencyLinks(state): DependencyLink[] {
      return Array.from(state.selectedDependencyLink);
    },
    selectedDependencyLinkItems(state) {
      return dependencyLinkItems.filter((item) =>
        state.selectedDependencyLink.has(item.id),
      );
    },
    isDependencySearch(state): boolean {
      return useBoardStore().possibleStickyTypes.some(
        (stickyType) =>
          stickyType.functionality === "dependency" &&
          state.selectedStickyTypeIds.has(stickyType.id),
      );
    },
    allAssignees(_state): AuthUser[] {
      const res: Record<string, AuthUser> = {};
      const cards = useBoardStore().currentBoard().cards;
      for (const id in cards) {
        const a = cards[id].data.assignee;
        if (a) {
          res[a.id] = a;
        }
      }
      // Add the current user to the list of assignees
      // (so they can confirm they have no cards assigned)
      const currentUser = useUserStore().currentUser;
      if (currentUser) {
        res[currentUser.id] ??= currentUser;
      }

      return [...Object.values(res), noUser(toCssColor(white))];
    },
    selectedAssignees(state): AuthUser[] {
      return this.allAssignees.filter((assignee) =>
        state.selectedAssigneeIds.has(assignee.id),
      );
    },
    allTeams(_state): Team[] {
      return [...useTeamStore().teamsInCurrentArt, useTeamStore().noTeam];
    },
    selectedTeams(state): Team[] {
      return this.allTeams.filter((team) => state.selectedTeamIds.has(team.id));
    },
    effectiveTeamIds(): Set<string> {
      return useBoardStore().currentBoard().type === "solution"
        ? this.teamIdsOfSelectedArts
        : this.selectedTeamIds;
    },
    teamIdsOfSelectedArts(state): Set<string> {
      return new Set(
        useTeamStore()
          .teams.filter((team) => state.selectedArtIds.has(team.artId || ""))
          .map((team) => team.id),
      );
    },
    isTeamSelected() {
      return (teamId: Team["id"] | undefined) =>
        this.effectiveTeamIds.has(teamId || NO_TEAM_ID);
    },
    allArts(_state): Art[] {
      return [...useArtStore().arts, useArtStore().noArt];
    },
    selectedArts(state): Art[] {
      return this.allArts.filter((art) => state.selectedArtIds.has(art.id));
    },
    isArtSelected() {
      return (artId: Art["id"] | undefined) =>
        this.selectedArtIds.has(artId || NO_ART_ID);
    },
    flags(state) {
      return Array.from(state.selectedFlagStrings).map(CardFlag.fromFlagString);
    },
    statusClasses(state) {
      return Array.from(state.selectedStatusClasses);
    },
    selectedStatusClassItems(state) {
      return statusClassItems.filter((item) =>
        state.selectedStatusClasses.has(item.id),
      );
    },
    allIterations(): Iteration[] {
      return useSessionStore().iterations;
    },
    selectedIterations(state): Iteration[] {
      return this.allIterations.filter((iteration) =>
        state.selectedIterationIds.has(iteration.id),
      );
    },
    anyFilterCriteria(state) {
      return (
        state.text !== "" ||
        !!state.id ||
        state.selectedStickyTypeIds.size !== 0 ||
        state.selectedLinkStates.size !== 0 ||
        state.selectedAssigneeIds.size !== 0 ||
        state.selectedTeamIds.size !== 0 ||
        state.selectedArtIds.size !== 0 ||
        state.selectedIterationIds.size !== 0 ||
        state.selectedFlagStrings.size !== 0 ||
        state.selectedStatusClasses.size !== 0
      );
    },
    dependencyFilterItems(state): DependencyTeamItem[] {
      return [
        {
          id: "IncomingAndOutgoing",
          name: i18n.global.t("dependencyTeamFilter.incomingAndOutgoing"),
        },
        {
          id: "Incoming",
          name: i18n.global.t("dependencyTeamFilter.incoming"),
        },
        {
          id: "Outgoing",
          name: i18n.global.t("dependencyTeamFilter.outgoing"),
        },
        ...(state.selectedTeamIds.size < 2
          ? []
          : [
              {
                id: "Mutual" as DependencyTeamFilter,
                name: i18n.global.t("dependencyTeamFilter.mutual"),
              },
            ]),
      ];
    },
    selectedDependencyFilterItem(state): DependencyTeamItem {
      return this.dependencyFilterItems.find(
        (item) => item.id === state.selectedDependencyTeamFilter,
      )!;
    },
    showDependencyTeamFilter(state): boolean {
      return this.isDependencySearch && state.selectedTeamIds.size > 0;
    },
    matchesLinkStates:
      (state) =>
      (link: Link, emptyMatch = false) => {
        const states = state.selectedLinkStates;
        return (
          (emptyMatch && states.size === 0) ||
          Array.from(states).some(matchesLinkState)
        );

        function matchesLinkState(linkState: InfoLevel) {
          switch (linkState) {
            case "default":
              return true;
            case "warn":
              return link.state === "warn";
            case "error":
              return link.state === "error";
          }
        }
      },
    showCardLinks() {
      return (card: BoardCard) => {
        const acid = useBoardStore().activeCardId;
        const showForSelectedCard = acid === card.data.id;

        return (
          (isDependency(card.data) && this.isDependencySearch) ||
          showForSelectedCard ||
          card.meta.dragging ||
          card.meta.isHighlighted ||
          card.meta.isRelatedToHighlighted
        );
      };
    },
  },
  actions: {
    expandSearch() {
      this.isSearchSidebarOpen = true;
    },
    collapseSearch() {
      this.isSearchSidebarOpen = false;
    },
    toggleSearch() {
      this.isSearchSidebarOpen ? this.collapseSearch() : this.expandSearch();
    },
    resetCriteria() {
      this.text = "";
      this.id = undefined;
      this.selectedStickyTypeIds = new Set();
      this.selectedLinkStates = new Set();
      this.selectedAssigneeIds = new Set();
      this.selectedTeamIds = new Set();
      this.selectedArtIds = new Set();
      this.selectedIterationIds = new Set();
      this.clearFlags();
      this.selectedStatusClasses = new Set();
      this.selectedDependencyLink = new Set();
      this.selectedDependencyTeamFilter = "IncomingAndOutgoing";
    },
    toggleFlag(flag: CardFlag) {
      const flagString = flag.toString();
      if (this.selectedFlagStrings.has(flagString)) {
        this.selectedFlagStrings.delete(flagString);
      } else {
        this.selectedFlagStrings.add(flagString);
      }
      this.selectedFlagStrings = new Set(this.selectedFlagStrings);
    },
    clearFlags() {
      this.selectedFlagStrings = new Set();
    },
  },
});
