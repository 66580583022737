<script setup lang="ts">
import { v4 as uuid } from "uuid";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import TreeView from "@/components/TreeView/TreeView.vue";
import { TreeNodeData } from "@/components/TreeView/types";
import { useArtStore } from "@/store/art";
import { useTeamStore } from "@/store/team";

import { OrganizationTreeNodeData } from "../types";

const { t } = useI18n();

const emit = defineEmits<{ select: [node: OrganizationTreeNodeData] }>();

const artsList = computed(() => useArtStore().arts);

const getTeamsInArt = (artId: string) => useTeamStore().teamsInArt(artId);

const handleSelect = (node: TreeNodeData) => {
  emit("select", node as OrganizationTreeNodeData);
};

const nodes = computed<OrganizationTreeNodeData[]>(() => {
  if (artsList.value.length > 1) {
    return [
      {
        id: uuid(),
        label: t("treeView.solutionTrain"),
        icon: "thin/solution-train",
        disabled: false,
        levelContext: {
          level: "solution-train",
        },
        children: artsList.value.map((art) => ({
          id: uuid(),
          label: art.name,
          icon: "thin/art",
          disabled: false,
          levelContext: {
            level: "art",
            id: art.id,
          },
          children: getTeamsInArt(art.id).map((team) => ({
            id: uuid(),
            label: team.name,
            children: [],
            icon: "thin/team",
            disabled: false,
            levelContext: {
              level: "team",
              id: team.id,
            },
          })),
        })),
      },
    ];
  } else {
    // No ART or Single ART
    const isNoArt = useArtStore().currentArt.id === "";
    const artLabel = isNoArt ? t("treeView.art") : artsList.value[0].name;
    const artId = isNoArt ? "" : artsList.value[0].id;

    return [
      {
        id: uuid(),
        label: artLabel,
        icon: "thin/art",
        disabled: false,
        levelContext: {
          level: "art",
          id: artId,
        },
        children: getTeamsInArt(artId).map((team) => ({
          id: uuid(),
          label: team.name,
          children: [],
          icon: "thin/team",
          disabled: false,
          levelContext: {
            level: "team",
            id: team.id,
          },
        })),
      },
    ];
  }
});
</script>

<template>
  <div class="tree-view-container">
    <TreeView :nodes="nodes" @select="handleSelect" />
  </div>
</template>
