import { AxiosError } from "axios";
import { clamp } from "lodash-es";
import { pageview } from "vue-gtag";
import {
  NavigationGuard,
  NavigationGuardNext,
  RouteLocation,
  RouteLocationNormalized,
} from "vue-router";

import { boardActions } from "@/action/boardActions";
import { internalActions } from "@/action/internalActions";
import {
  getStickyTypes,
  loadBoard,
  loadFlexBoards,
  loadOtherBoards,
  loadSessionData,
  loggedIn,
  setSessionId,
} from "@/backend/Backend";
import { ErrorResponse, UserResponse } from "@/backend/serverModel";
import { handleError } from "@/error/errorHandler";
import { addBreadcrumb, captureMessage } from "@/error/sentry";
import { sendBoardSwitch, sendSearch } from "@/mixins/EventBusUser";
import { BoardType, isBoardType } from "@/model/baseTypes";
import { Board } from "@/model/board";
import { Session, Team } from "@/model/session";
import { technicalUser } from "@/model/user";
import AuthService from "@/services/auth.service";
import { useBoardStore } from "@/store/board";
import { useBoardsStore } from "@/store/boards";
import { useFlexStore } from "@/store/flex";
import { useLoadingStore } from "@/store/loading";
import { useSessionStore } from "@/store/session";
import { useStickyTypeStore } from "@/store/stickyType";
import { useTeamStore } from "@/store/team";
import { useZoomStore } from "@/store/zoom";
import { i18n } from "@/translations/i18n";
import { normalizeUrlParam } from "@/utils/text/linkParser";

import { getRouter } from ".";
import { login } from "./login";
import { setSessionData } from "./sessionData";
import type { Params, Query, QueryImpl } from "./types";
import { currentTeam } from "./types";
import { companyParam } from "./utils";

const firstTeam = "*";

interface PQ {
  teamChange: Change<Team>;
  params: Params;
  query: Query;
}

interface Change<T> {
  action: "none" | "select" | "set";
  target?: T;
}

let scrollTimeout = 0;
// We use this to make sure when the user tries to go to a board, the necessary data including boards are loaded before
let loadingSessionData = Promise.resolve();

export function afterEach(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
) {
  if (
    to.params.board !== from.params.board ||
    to.name !== from.name ||
    to.params.name !== from.params.name
  ) {
    const boardTitle = to.params.board ? useBoardsStore().boardTitle() : "";
    const place = boardTitle || routeName(to);
    document.title = "piplanning - " + place;
    const page =
      to.params.board && useBoardStore().board
        ? boardType(useBoardStore().board!)
        : (to.name || "unknown").toString();
    pageview({ page_title: page });
  }
}

function boardType(board: Board) {
  if (board.type === "flex") {
    return useFlexStore().currentFlexBoard?.flexType.background + " canvas";
  }
  return board.type + " board";
}

export const beforeEach: NavigationGuard = (to, from, next) => {
  // Check if authentication is needed for next route
  if (to.matched.some((route) => route.meta.needsAuth === false)) {
    return next();
  }

  // Check if authenticated before redirecting to next route.
  // If not - authenticate in the background.
  if (!loggedIn()) {
    useLoadingStore().start();
    return loginAndRedirect(to, next);
  }

  if (
    (to.name === "team" && !useSessionStore().session.selected) ||
    (to.name === "app" && !to.params.session)
  ) {
    return next("/page/session");
  }
  if (to.path.startsWith("/page")) {
    return next();
  }

  // this is a workaround for https://pm.mps-cg.com/browse/REN-7624 and
  // https://github.com/vuejs/vue-router/issues/2725, not sure if it's 100% ok
  const [toSession, toTeam, toBoard, toName] = [
    normalizeUrlParam(to.params.session),
    normalizeUrlParam(to.params.team),
    normalizeUrlParam(to.params.board),
    normalizeUrlParam(to.params.name),
  ];
  to.params.session = toSession;
  to.params.team = toTeam;
  to.params.board = toBoard;
  to.params.name = toName;

  if (!isBoardType(to.params.board)) {
    return replaceRoute({ params: { board: "team" } });
  }

  if (to.params.team === currentTeam) {
    return replaceRoute({
      params: { team: useTeamStore().currentTeam.name || firstTeam },
    });
  }
  const sessionChange = sessionToChange(to.params.session);
  if (sessionChange.action === "select") {
    return next({ name: "session" });
  }
  if (sessionChange.target) {
    void addBreadcrumb("navigation", {
      message: "Changing session",
      data: {
        id: sessionChange.target.id,
        name: sessionChange.target.name,
      },
    });
    useSessionStore().setSession(
      sessionChange.target,
      setSessionId(sessionChange.target.id)
        .then(() => {
          loadingSessionData = useLoadingStore().load(
            "loading.session",
            loadSessionData().then(setSessionData),
          );
          return Promise.all([
            loadingSessionData,
            getStickyTypes().then(
              (stickyTypes) => (useStickyTypeStore().stickyTypes = stickyTypes),
            ),
            loadFlexBoards().then(({ flexTypes, flexBoards }) => {
              useFlexStore().setFlexModel(flexTypes, flexBoards);
            }),
          ])
            .then(nameParam)
            .then((pq) => {
              teamAndBoardParams(true, pq);
              pq.params.session = useSessionStore().uniqueSessionName();
              app(pq);
            });
        })
        .catch((fail) => next(handleError(fail))),
    );
    return;
  }

  loadingSessionData.then(() => {
    nameParam()
      .then((pq) => app(teamAndBoardParams(false, pq)))
      .catch((fail) => next(handleError(fail)));
  });

  function nameParam(): Promise<PQ> {
    const pq: PQ = {
      teamChange: teamToChange(toTeam),
      params: {},
      query: {},
    };
    if (
      pq.teamChange.action !== "select" &&
      to.params.board === "flex" &&
      (!to.params.name || to.params.name !== currentFlexBoardName())
    ) {
      if (to.params.name || !currentFlexBoardName()) {
        const board =
          useFlexStore().flexBoardByName(toName) ||
          useFlexStore().flexBoards[0];
        if (board) {
          void addBreadcrumb("navigation", {
            message: "Selecting flexboard",
            data: { id: board.id, name: board.name },
          });
          //TODO remove/add subscriptions from old/new flex board?
          useFlexStore().currentFlexBoard = board;
          useBoardsStore().addBoard(board);
          internalActions.switchBoard(board);
          sendBoardSwitch();
          return loadBoard(board).then(() => {
            pq.params.name = board.name;
            return pq;
          });
        } else {
          to.params.board = "team";
        }
      }
    }
    return Promise.resolve(pq);
  }

  function teamAndBoardParams(newSession: boolean, pq: PQ): PQ {
    void addBreadcrumb("navigation", {
      message: "Setting board",
      data: {
        action: pq.teamChange.action,
        switchedSession: newSession,
        id: pq.teamChange.target?.id,
        name: pq.teamChange.target?.name,
      },
    });
    if (pq.teamChange.action !== "select") {
      let boardReload = false;
      if (newSession || pq.teamChange.action === "set") {
        if (newSession || to.params.team || !useTeamStore().currentTeam.name) {
          if (pq.teamChange.target) {
            // when the session is switched but the team stays the same, target is null
            boardActions.switchTeam("internal", pq.teamChange.target);
          }
          boardReload = true;
        }
        pq.params.team = useTeamStore().currentTeam.name;
      }
      if (newSession || to.params.board !== useBoardStore().board?.type) {
        const board = useBoardsStore().boardByType(
          to.params.board as BoardType,
        );
        internalActions.switchBoard(board);
        boardReload = true;
      }
      if (boardReload) {
        const currentBoard = useBoardStore().currentBoard();
        const boardType = currentBoard.type;
        const teamName =
          boardType === "team" ? currentBoard.team?.name : undefined;

        void addBreadcrumb("xhr", {
          message: "Reloading board",
          data: {
            id: useBoardStore().boardId(),
            name: currentFlexBoardName(),
            type: useBoardStore().currentBoard().type,
            team: teamName,
          },
        });
        sendBoardSwitch();
        loadBoard(useBoardStore().currentBoard())
          .then(useLoadingStore().end)
          .then(() => loadOtherBoards())
          .then(() => sendSearchIfNeeded(from.query, to.query))
          .catch((fail) => next(handleError(fail)));
      }
      applyZoomIfNeeded(pq, to.query);
      scrollWindowIfNeeded(pq, to.query, newSession);
      sendSearchIfNeeded(from.query, to.query);
    }
    return pq;
  }

  function applyZoomIfNeeded(pq: PQ, query: QueryImpl) {
    const newZoom = clamp(parseFloat(query.zoom!) || 1, 1, 10);
    if (newZoom !== useZoomStore().factor) {
      useZoomStore().setZoomFactor(newZoom);
      pq.query.zoom = "" + newZoom;
    }
  }

  function scrollWindowIfNeeded(pq: PQ, query: QueryImpl, force: boolean) {
    const scrollX = +(query.scrollX || 68);
    const scrollY = +(query.scrollY || 0);
    window.clearTimeout(scrollTimeout);
    if (force || scrollX !== window.scrollX || scrollY !== window.scrollY) {
      const timeout = window.setTimeout(
        () => window.scrollTo(scrollX, scrollY),
        50,
      );
      // if it's an initial page load (=newSession)
      // scroll to the given position even if we get
      // another scroll position shortly afterwards
      scrollTimeout = force ? 0 : timeout;
      pq.query.scrollX = "" + scrollX;
      pq.query.scrollY = "" + scrollY;
    }
  }

  function sendSearchIfNeeded(fromQuery: QueryImpl, toQuery: QueryImpl) {
    if (
      toQuery.searchText !== fromQuery.searchText ||
      toQuery.searchFlag !== fromQuery.searchFlag ||
      toQuery.searchAssignee !== fromQuery.searchAssignee ||
      toQuery.searchTeam !== fromQuery.searchTeam ||
      toQuery.searchArt !== fromQuery.searchArt ||
      toQuery.searchIteration !== fromQuery.searchIteration ||
      toQuery.searchType !== fromQuery.searchType ||
      toQuery.searchDepLink !== fromQuery.searchDepLink ||
      toQuery.searchStatusClass !== fromQuery.searchStatusClass ||
      toQuery.searchLink !== fromQuery.searchLink ||
      toQuery.searchPos !== fromQuery.searchPos ||
      toQuery.searchId !== fromQuery.searchId
    ) {
      sendSearchEvent(toQuery);
    }
  }

  function app(pq: PQ) {
    if (pq.teamChange.action === "select") {
      next({ name: "team" });
    } else {
      handleParamAndQueryChanges(pq);
    }
  }

  function handleParamAndQueryChanges(pq: PQ) {
    const paramChanged = Object.keys(pq.params).some(
      (p) => pq.params[p as keyof Params] !== to.params[p],
    );
    const queryChanged = Object.keys(pq.query).some(
      (q) => pq.query[q as keyof Query] !== to.query[q],
    );

    if (paramChanged || queryChanged) {
      replaceRoute(pq);
    } else {
      next();
    }
  }

  function replaceRoute(pq: { params?: object; query?: object }) {
    next({
      replace: true,
      name: to.name || undefined,
      params: { ...to.params, ...pq.params },
      query: { ...to.query, ...pq.query },
    });
  }
};

const loginAndRedirect = async (
  route: RouteLocation,
  next: NavigationGuardNext,
) => {
  const router = getRouter();
  const resolvedRoute = router.resolve({
    path: route.path,
    query: route.query,
  });

  let response;

  try {
    response = await useLoadingStore().load(
      "loading.checkAuth",
      AuthService.isAuthenticated(resolvedRoute.href),
    );
  } catch (authError: any) {
    if (
      ["TOKEN_EXPIRED", "INVALID_TOKEN"].includes(
        authError.response?.data?.error.code,
      )
    ) {
      try {
        response = await AuthService.refreshToken({ path: "#" + route.path });
      } catch (refreshError: any) {
        return redirectAfterError(refreshError, next);
      }
    } else {
      return redirectAfterError(authError, next);
    }
  }

  if (response.success) {
    const location = await loginWithUser(response);
    return next(location);
  }

  void captureMessage("Login unsuccessful with non-error status code", {
    response: { ...response },
    route: { ...route },
  });

  return next({ name: "login" });

  function loginWithUser(response: UserResponse) {
    const user = technicalUser(
      response.user_id,
      response.company,
      response.role,
    );
    return useLoadingStore().load(
      "loading.login",
      login(user, { ...route.query, path: route.path }),
    );
  }

  function redirectAfterError(
    error: AxiosError<ErrorResponse>,
    next: NavigationGuardNext,
  ) {
    const redirectUrl = error.response?.data?.redirectUrl;
    if (redirectUrl && !companyParam()) {
      return window.location.assign(redirectUrl);
    }
    next({ name: "login" });
  }
};

function routeName(route: RouteLocation) {
  const selectedSession = useSessionStore().session.selected;
  const title = route.meta?.title as string;
  return (
    (title ? i18n.global.t(title.toString()) : "") +
    (selectedSession ? " - " + selectedSession.name : "")
  );
}

function teamToChange(teamName: string): Change<Team> {
  if (teamName !== useTeamStore().currentTeam?.name) {
    const team = useTeamStore().teams.find(
      (t) => teamName === firstTeam || teamName === t.name,
    );
    if (team) {
      return { action: "set", target: team };
    }
    return { action: "select" };
  }
  return { action: "none" };
}

function sessionToChange(sessionName: string): Change<Session> {
  if (useSessionStore().currentlyDifferentSession(sessionName)) {
    const namedSession = useSessionStore().findByNameOrId(sessionName);
    if (namedSession) {
      return { action: "set", target: namedSession };
    }
    const indexedSession = useSessionStore().findByIndexedName(sessionName);
    if (indexedSession) {
      return indexedSession.id === useSessionStore().session.current?.id
        ? { action: "none" }
        : { action: "set", target: indexedSession };
    }
    return { action: "select" };
  }
  return { action: "none" };
}

function currentFlexBoardName() {
  const currentBoard = useBoardStore().board;
  return currentBoard?.type === "flex" ? currentBoard.name : undefined;
}

function sendSearchEvent(q: QueryImpl) {
  sendSearch({
    text: q.searchText,
    flag: q.searchFlag,
    assignee: q.searchAssignee,
    team: q.searchTeam,
    art: q.searchArt,
    iteration: q.searchIteration,
    type: q.searchType,
    depLink: q.searchDepLink,
    link: q.searchLink,
    statusClass: q.searchStatusClass,
    pos: q.searchPos,
    id: q.searchId,
  });
}
