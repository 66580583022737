<script lang="ts" setup>
import { computed } from "vue";

import { boardKey } from "@/components/board/injectKeys";
import { cardKey } from "@/components/card/injectKeys";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { injectStrict } from "@/utils/vue";

import { isActionVisible } from "../../utils";
import ActionMenuItem from "../ActionMenuItem/ActionMenuItem.vue";
import DependOnTeam from "./DependOnTeam.vue";

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);

const isVisible = computed(() => isActionVisible("depend", card, board.value));
</script>

<template>
  <ActionMenuItem
    v-if="isVisible"
    name="depend-on-team"
    :tooltip-text="$t('cardAction.depend')"
    :aria-label="$t('label.actionMenu.depend')"
    aria-controls="depend-on-team-dropdown"
  >
    <SvgIcon name="action-menu/team" width="20" height="20" />
    <template #dropdown>
      <DependOnTeam id="depend-on-team-dropdown" trigger="action-menu" />
    </template>
  </ActionMenuItem>
</template>
