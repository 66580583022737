import { clamp } from "lodash-es";

import { sender } from "@/backend/Sender";
import { namedKey } from "@/components/utils/Shortcuts";
import {
  PositionedCard,
  arrangeCards,
  zoomToRegion,
} from "@/components/utils/layout";
import { Board } from "@/model/board";
import { Rectangle, RelativeCoordinate } from "@/model/coordinates";
import { Team } from "@/model/session";
import { useArtStore } from "@/store/art";
import { useBoardStore } from "@/store/board";
import { useBoardsStore } from "@/store/boards";
import { useTeamStore } from "@/store/team";
import { boardScreenshotSaved } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

import { action, defineActions } from "./actions";
import { internalActions } from "./internalActions";
import { screenShot } from "./utils/screenshot";

export const boardActions = defineActions("board", {
  screenshot: action(
    (event?: KeyboardEvent) => {
      const board = useBoardStore().currentBoard();

      if (board.type === "objectives") {
        return;
      }

      trackEvent(boardScreenshotSaved(board.type));
      screenShot();
      event?.preventDefault();
    },
    {
      shortcut: namedKey("KeyS", { modifiers: ["altCtrl"] }),
    },
  ),
  arrange: action(
    (
      board: Board,
      cards: PositionedCard[],
      bounds: Rectangle<RelativeCoordinate>,
    ) => {
      arrangeCards(board, cards, bounds);
    },
  ),
  zoomToRegion: action((bounds: Rectangle<RelativeCoordinate>) =>
    zoomToRegion(bounds),
  ),
  toggleCardSelection: action((cardId: string) => {
    const board = useBoardStore().currentBoard();
    if (cardId in board.selected) {
      unselectCard(cardId);
    } else {
      selectCard(cardId);
    }
  }),
  clearCardSelection: action(() => {
    useBoardStore().setActiveCardId(null);
    const board = useBoardStore().currentBoard();
    internalActions.clearCardSelection(board);
  }),
  setVelocity: action(
    (boardId: string, iteration: number, velocity: number) => {
      useBoardsStore().setVelocity({ id: boardId, iteration, velocity });
      sender.setVelocity(boardId, iteration, velocity);
    },
  ),
  setCardSize: action((boardId: string, factor: number) =>
    setCardSize(useBoardsStore().boardById(boardId), factor),
  ),
  smallerCardSize: action(
    () => {
      const board = useBoardStore().currentBoard();
      setCardSize(board, board.cardSize.factor - 0.5);
    },
    { shortcut: namedKey("ArrowDown", { modifiers: ["altCtrl"] }) },
  ),
  biggerCardSize: action(
    () => {
      const board = useBoardStore().currentBoard();
      setCardSize(board, board.cardSize.factor + 0.5);
    },
    { shortcut: namedKey("ArrowUp", { modifiers: ["altCtrl"] }) },
  ),
  cardToFront: action((cardId: string, boardId?: string) => {
    if (useBoardsStore().cardToFront({ id: cardId, boardId })) {
      sender.frontCard(useBoardStore().currentBoard().id, cardId);
    }
  }),
  switchTeam: action((team: Team) => {
    useTeamStore().setTeam(team);
    if (team.artId) {
      useArtStore().setArt(useArtStore().artById(team.artId)!);
    }
    // team and/or art has changed -> switch to board of current type with the new team/art
    internalActions.switchBoard();
  }),
});

function selectCard(cardId: string) {
  useBoardStore().selectCard(cardId);
  sender.startAlterCard(useBoardStore().currentBoard().id, cardId);
}

function unselectCard(cardId: string) {
  useBoardStore().unselectCard(cardId);
  sender.stopAlterCard(useBoardStore().currentBoard().id, cardId);
}

function setCardSize(board: Board, factor: number) {
  const val = clamp(factor, 1, 8);

  if (useBoardsStore().setCardSize({ boardId: board.id, factor: val })) {
    sender.scaleBoard(board.id, val);
  }
}
