<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import IconButton from "@/components/ui/IconButton/IconButton.vue";
import { LinkableCard } from "@/model/link";

import CardRow from "./CardRow.vue";

const props = defineProps<{
  linkableCard: LinkableCard;
  resetButton?: boolean;
  showTeam?: boolean;
  showStatus?: boolean;
  showActions?: boolean;
}>();
const emit = defineEmits<{
  click: [event: Event];
  setLinkedState: [linked: boolean];
}>();

const rowLabel = computed(() => {
  const key =
    props.showActions && props.linkableCard.linked
      ? /*$t*/ "label.linkableCardRow.linked"
      : /*$t*/ "label.linkableCardRow";

  return useI18n().t(key, {
    type: props.linkableCard.type.name,
    text: props.linkableCard.text,
  });
});
</script>

<template>
  <div class="linkable-card-row" role="group" :aria-label="rowLabel">
    <CardRow
      :card="linkableCard"
      :show-team="showTeam"
      :show-status="showStatus"
    />
    <div v-if="showActions">
      <IconButton
        v-if="linkableCard.linked"
        variant="filled"
        color="primary"
        icon="action-menu/unlink"
        :aria-label="$t('linkableCardRow.removeLink', linkableCard.text)"
        tabindex="0"
        :tooltip="$t('linkModal.removeLink')"
        @click="emit('setLinkedState', false)"
      />
      <IconButton
        v-else
        icon="action-menu/link"
        tabindex="0"
        :tooltip="$t('linkModal.addLink')"
        :aria-label="$t('linkableCardRow.addLink', linkableCard.text)"
        @click="emit('setLinkedState', true)"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.linkable-card-row {
  display: flex;
  justify-content: space-between;
  gap: 1.125rem;
  width: 100%;
  align-items: center;
  cursor: pointer;
}
</style>
