import { LRUMap } from "lru_map";

import { SECONDS } from "@/model/Settings";
import { fontsLoaded } from "@/utils/dom/dom";

import { LocalStore, versioned } from "../LocalStore";

//TODO remove when all(?) users have run this
localStorage.removeItem("pipFontCache");
localStorage.removeItem("pipFontCacheVersion");

interface StoreEntry {
  key: string;
  value: FontInfo;
}

const store = new LocalStore<StoreEntry[]>("fontCache", {
  sedes: versioned(1),
  saveDelay: 5 * SECONDS,
});
const capacity = 10000;
const cache = loadCache();

// font might not have been loaded, so wait with font size calculation until ready
function calcPromise<EDIT extends boolean>(
  calc: () => FontData<EDIT>,
): Promise<FontData<EDIT>> {
  return fontsLoaded().then(calc);
}

interface FontInfo {
  raw?: RawFontData;
  text?: TextFontData;
}

export type FontData<EDIT extends boolean> = EDIT extends true
  ? RawFontData
  : TextFontData;

// font data for text with unprocessed links (used for edit mode)
export interface RawFontData {
  cache: boolean;
  size: number;
}

// font data for text with processed links (used for non-edit mode)
export interface TextFontData extends RawFontData {
  htmlLines: string[];
  textLines: string[];
}

export function fontSizeCache<EDIT extends boolean>(
  text: string,
  width: number,
  height: number,
  edit: EDIT,
  scale: boolean,
  font: string,
  calcFontData: () => FontData<EDIT>,
): Promise<FontData<EDIT>> {
  const ratio = Math.round((100 * width) / height);
  const key = `${ratio}/${font}/${scale ? "1" : "0"}/${text}`;
  const info = getInfo(key);
  const sub = edit ? "raw" : "text";
  const cached = info[sub] as FontData<EDIT>;

  if (cached && isFinite(ratio)) {
    return Promise.resolve(cached);
  }

  return calcPromise(() => {
    const fontData = calcFontData();
    if (!fontData.size) {
      fontData.size = edit ? 100 : 500;
      return fontData;
    }

    if (fontData.cache) {
      (info[sub] as FontData<EDIT>) = fontData;
      saveCache();
    }

    return fontData;
  });
}

function getInfo(key: string): FontInfo {
  let info = cache.get(key);
  if (!info) {
    info = {};
    cache.set(key, info);
  }
  return info;
}

function loadCache(): LRUMap<string, FontInfo> {
  const entries: Array<[string, FontInfo]> = (store.load() || []).map((it) => [
    it.key,
    it.value,
  ]);
  return new LRUMap(capacity, entries);
}

function saveCache() {
  store.save(cache.toJSON());
}

export function clearCache() {
  cache.clear();
  saveCache();
}
