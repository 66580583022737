<script setup lang="ts">
import { clamp } from "lodash-es";
import { computed, nextTick, ref } from "vue";

import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import BaseTooltip from "@/components/ui/BaseTooltip/BaseTooltip.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import { registerDrag } from "@/components/utils/Gestures";
import { maxZoom, minZoom } from "@/model/Settings";
import { centerCoord, windowCoord } from "@/model/coordinates";
import { useZoomStore } from "@/store/zoom";
import { appZoomOpened, appZoomZoomChanged } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

let zoomTimeout = 0;
const dragging = ref(false);
const active = ref(false);
const zoomIn = ref<HTMLButtonElement | null>(null);
const toggleAppZoom = ref<HTMLDivElement | null>(null);

const zoom = computed(
  () =>
    100 * (1 - (useZoomStore().dynamicFactor - minZoom) / (maxZoom - minZoom)),
);
const roundZoom = computed(() =>
  Math.round(100 * useZoomStore().dynamicFactor),
);

async function toggle() {
  if (!active.value) {
    trackEvent(appZoomOpened());
  }

  active.value = !active.value;
  if (active.value) {
    await nextTick();
    zoomIn.value?.focus();
  } else {
    toggleAppZoom.value?.focus();
  }
}

function close() {
  active.value = false;
  toggleAppZoom.value?.focus();
}

function zoomerPointerDown(e: PointerEvent) {
  registerDrag<{ max: number }>({}, e, {
    start(drag) {
      const p = drag.el.parentElement!;
      drag.start.y = -(
        p.offsetTop +
        p.parentElement!.offsetTop +
        p.parentElement!.parentElement!.offsetTop
      );
      drag.max = p.offsetHeight;
      startZoom();
      dragging.value = true;
      return true;
    },
    move(drag) {
      const pos = windowCoord(
        0,
        clamp(drag.pos.y - window.scrollY, 0, drag.max),
      );
      useZoomStore().setDynamicZoomFactor(
        maxZoom - (pos.y / drag.max) * (maxZoom - minZoom),
      );
      return pos;
    },
    stop() {
      stopZoom();
      dragging.value = false;
    },
  });
}

function updateLiveRegion() {
  const liveRegion = document.getElementById("zoomFactorLiveRegion");
  if (liveRegion) {
    liveRegion.textContent = `${roundZoom.value}%`;
  }
}

function appZoom(f?: number) {
  if (f) {
    const newFactor = clamp(useZoomStore().dynamicFactor * f, minZoom, maxZoom);
    if (newFactor !== useZoomStore().dynamicFactor) {
      if (!useZoomStore().zooming) {
        startZoom();
      }
      useZoomStore().setDynamicZoomFactor(newFactor);
      updateLiveRegion();
      zoomTimeout = window.setTimeout(() => appZoom(f), 10);
    } else if (useZoomStore().zooming) {
      stopZoom();
    }
  } else if (zoomTimeout) {
    stopZoom();
  }
}

function startZoom() {
  trackEvent(appZoomZoomChanged());
  useZoomStore().startZoom(centerCoord());
}

function stopZoom() {
  clearTimeout(zoomTimeout);
  zoomTimeout = 0;
  useZoomStore().endZoom();
}
</script>

<template>
  <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions-->
  <div id="app-zoom" class="zoom" :class="{ active }" @keydown.esc="close">
    <BaseTooltip position="bottom">
      <BaseButton
        ref="toggleAppZoom"
        class="main-toggle"
        variant="ghost"
        color="grey"
        :activated="active"
        :aria-label="$t('label.menuTop.appZoom', { value: roundZoom })"
        :aria-expanded="active"
        @click="toggle"
      >
        {{ $t("general.percent", { value: roundZoom }) }}
      </BaseButton>
      <template #content>{{ $t("appZoom.zoom") }}</template>
    </BaseTooltip>
    <transition name="fade">
      <div
        v-show="active"
        role="dialog"
        aria-modal="false"
        :aria-label="$t('appZoom.zoom')"
        class="zoom-controls"
      >
        <span aria-hidden="true" class="title">{{ $t("appZoom.zoom") }}</span>

        <IconButton
          ref="zoomIn"
          icon="general/plus"
          class="zoom-button"
          :aria-label="$t('label.zoomIn')"
          @pointerdown.stop="appZoom(1.05)"
          @keydown.enter.stop="appZoom(1.05)"
          @keydown.space.stop="appZoom(1.05)"
          @pointerout="appZoom()"
          @pointerup="appZoom()"
          @keyup.stop="appZoom()"
        />
        <div aria-hidden="true" class="zoomer">
          <div
            class="dot"
            :style="{ top: `${zoom}%` }"
            @pointerdown="zoomerPointerDown"
          />
        </div>
        <IconButton
          icon="general/minus"
          class="zoom-button"
          :aria-label="$t('label.zoomOut')"
          @pointerdown.stop="appZoom(0.95)"
          @keydown.enter.stop="appZoom(0.95)"
          @keydown.space.stop="appZoom(0.95)"
          @pointerout="appZoom()"
          @pointerup="appZoom()"
          @keyup.stop="appZoom()"
        />
      </div>
    </transition>
    <div
      id="zoomFactorLiveRegion"
      aria-live="polite"
      aria-atomic="true"
      class="visually-hidden"
    ></div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/variables";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/mixins/shadow";

#app-zoom.zoom {
  position: relative;
  display: flex;
  align-items: center;

  .main-toggle {
    font-weight: font.$weight-normal;
    font-size: font.$size-large;
    width: 4.5em;
  }

  .zoom-controls {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 10px auto auto;
    height: 227px;
    width: 56px;
    background-color: colors-old.$back-color;

    @include shadow.default;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 30px 0 10px;
    border-radius: 6px;

    > span.title {
      position: absolute;
      top: 10px;
      left: 0;
      right: 0;
      text-align: center;
      font-size: 12px;
      font-weight: font.$weight-bold;
      color: colors-old.$text-secondary-color;
    }

    .zoomer {
      position: relative;
      border: 2px solid colors-old.$divider-color;
      border-top: 0;
      height: 100%;
      border-radius: 40px;
      margin-bottom: 15px;
      margin-top: 10px;
    }

    .dot {
      position: absolute;
      left: -6px !important;
      right: 0;
      margin: auto;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background-color: colors-old.$menu-color;
    }

    :deep(.zoom-button) {
      padding: 7px;
    }
  }

  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
}
</style>
