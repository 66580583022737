<script setup lang="ts">
import { computed } from "vue";

import IconButton from "@/components/ui/IconButton/IconButton.vue";
import NavigableList from "@/components/ui/NavigableList/NavigableList.vue";
import { boardTitle } from "@/model/baseTypes";
import { Board } from "@/model/board";
import { useModalStore } from "@/store/modal";
import { useSelectionStore } from "@/store/selection";
import { translate } from "@/translations/i18n";

import BaseModal from "./BaseModal.vue";

const boards = computed(() => useSelectionStore().mirrorTargets);

function name(board: Board) {
  return translate(boardTitle(board));
}

function mirror(board: Board) {
  useSelectionStore().mirror(board);
  useModalStore().hide();
}
</script>

<template>
  <BaseModal
    class="mirror-modal"
    aria-labelledby="mirror-modal-title"
    :description="$t('label.mirrorModal.description')"
  >
    <template #header-title>
      <span id="mirror-modal-title" v-autofocus>
        {{ $t("mirrorModal.title") }}
      </span>
    </template>
    <div class="scrollable-list">
      <NavigableList :first-list="boards" @select="mirror">
        <template #row="{ item }">
          <div class="item">
            <span>{{ name(item) }}</span>
            <IconButton icon="action-menu/mirror" />
          </div>
        </template>
      </NavigableList>
    </div>
  </BaseModal>
</template>

<style lang="scss" scoped>
.mirror-modal {
  .item {
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
