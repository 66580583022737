import { defineStore } from "pinia";

import { captureMessage } from "@/error/sentry";

const total = 16;

export const useLoadingStore = defineStore("loading", {
  state: () => ({
    items: { started: new Array<string>(), loading: new Array<string>() },
    started: 0,
    finished: 0,
    ended: false,
  }),
  getters: {
    progress: (state) => ({
      started: state.started / total,
      finished: state.finished / total,
    }),
  },
  actions: {
    start() {
      this.items.started = [];
      this.items.loading = [];
      this.started = 0;
      this.finished = 0;
    },
    end() {
      this.ended = true;
      if (this.started !== total || this.finished !== total) {
        // TODO avoid sentry spam, remove when REN-13055 is fixed
        if (
          this.started === 17 &&
          this.finished === 17 &&
          this.items.started.filter((item) => item === "loading.setSession")
            .length === 2
        ) {
          return;
        }
        void captureMessage("loading items count not reached", {
          counts: {
            total,
            items: this.items,
            started: this.started,
            finished: this.finished,
          },
        });
      }
    },
    load<T>(item: string, action: Promise<T>): Promise<T> {
      if (this.ended) {
        return action;
      }
      this.started++;
      this.items.started.push(item);
      this.items.loading.push(item);
      return action.finally(() => {
        this.finished++;
        this.items.loading.splice(
          this.items.loading.findIndex((i) => i === item),
          1,
        );
      });
    },
  },
});
