<script setup lang="ts">
import { linkActions } from "@/action/linkActions";
import LinkableCardRow from "@/components/CardRow/LinkableCardRow.vue";
import ScreenReaderMessage from "@/components/a11y/ScreenReaderMessage.vue";
import NavigableList from "@/components/ui/NavigableList/NavigableList.vue";
import { Card } from "@/model/card";
import { LinkableCard } from "@/model/link";
import { useBoardStore } from "@/store/board";
import { useSelectionStore } from "@/store/selection";

const props = defineProps<{ cards: Card[]; linkableCards: LinkableCard[] }>();
const emit = defineEmits<{ "link-change": [] }>();

function toggleLink(targetCard: LinkableCard) {
  if (useBoardStore().areMultipleStickiesSelected) {
    useSelectionStore().addLinkingToHistory();
  }
  if (targetCard.linked) {
    targetCard.linked = false;
    props.cards.forEach((card) => {
      linkActions.remove(
        "modal",
        { id: card.id, toId: targetCard.id },
        "linking-modal",
      );
    });
  } else {
    targetCard.linked = true;
    props.cards.map((card) => {
      linkActions.add(
        "modal",
        { id: card.id, toId: targetCard.id },
        "linking-modal",
      );
    });
  }
  emit("link-change");
}
</script>

<template>
  <div class="sticky-link-stickies">
    <!-- Results count (screen reader only) -->
    <ScreenReaderMessage>
      {{ $t("searchResultsTs.results", { count: linkableCards.length }) }}
    </ScreenReaderMessage>
    <div class="scrollable-list">
      <NavigableList :first-list="linkableCards" @select="toggleLink">
        <template #row="{ item }">
          <LinkableCardRow :linkable-card="item" show-team show-actions />
        </template>
      </NavigableList>
    </div>
    <span v-if="!linkableCards.length" class="empty-placeholder">
      {{ $t("linkModal.noResult") }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/mixins/modal";
@use "@/styles/mixins/utils";

.sticky-link-stickies {
  .empty-placeholder {
    @include modal.empty-placeholder;
  }

  .scrollable-list {
    overflow-y: scroll;
    max-height: 45vh;

    @include utils.hide-scrollbar;
  }
}
</style>
