<script setup lang="ts">
import { toggleActions } from "@/action/toggleActions";
import PlanReadoutModal from "@/components/PlanReadout/PlanReadoutModal.vue";
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import DropdownMenu from "@/components/ui/DropdownMenu/DropdownMenu.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import { useModalStore } from "@/store/modal";

function openPlanReadoutModal() {
  useModalStore().open(PlanReadoutModal);
}

function handleTimerOpen() {
  toggleActions.showTimer("board", "topbar-menu");
}
</script>

<template>
  <DropdownMenu :offset="{ top: 14 }" is-aria-menu>
    <template #trigger="{ isOpen }">
      <IconButton
        id="facilitation-menu-trigger"
        data-dropdown-trigger
        icon="menu-top/facilitation"
        :activated="isOpen"
        :tooltip="$t('action.facilitation')"
        :aria-expanded="isOpen"
        aria-controls="facilitation-dropdown"
        data-testid="facilitation-button"
      />
    </template>
    <div id="facilitation-dropdown" v-keyboard-nav role="menu">
      <BaseButton
        variant="ghost"
        color="grey"
        icon-before="menu-top/plan-readout"
        class="menu-button list-item"
        full-width
        @click="openPlanReadoutModal"
      >
        {{ $t("planReadout.title") }}
      </BaseButton>
      <BaseButton
        variant="ghost"
        color="grey"
        :icon-before="toggleActions.showTimer.data.icon"
        class="menu-button list-item"
        full-width
        @click="handleTimerOpen"
      >
        {{ $t(toggleActions.showTimer.data.name) }}
      </BaseButton>
    </div>
  </DropdownMenu>
</template>

<style lang="scss" scoped>
@use "@/styles/font";

.menu-button {
  justify-content: flex-start;
  font-weight: font.$weight-normal;
}

.tooltip {
  display: flex;
}
</style>
