<script setup lang="ts">
import { computed } from "vue";

import { navigationActions } from "@/action/navigationActions";
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import BaseTooltip from "@/components/ui/BaseTooltip/BaseTooltip.vue";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { boardTypeName } from "@/model/baseTypes";
import { Art, Team } from "@/model/session";
import { useArtStore } from "@/store/art";
import { useBoardStore } from "@/store/board";
import { useTeamStore } from "@/store/team";
import { teamChanged } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

import BreadCrumbSelector from "./BreadCrumbSelector.vue";

const boardType = computed(() => useBoardStore().currentBoard().type);
const boardName = computed(() => boardTypeName(boardType.value));

const flexBoard = computed(() => {
  const board = useBoardStore().currentBoard();
  return board.type === "flex" ? board : undefined;
});

function selectFlex() {
  navigationActions.goToCanvas("mainMenu");
}

const teams = computed(() => useTeamStore().teamsInCurrentArt);
const currentTeam = computed(() => useTeamStore().currentTeam);

function selectTeam(team: Team) {
  trackEvent(teamChanged(boardType.value, "topbar-menu"));
  navigationActions.changeTeam("mainMenu", team);
}

const arts = computed(() => useArtStore().arts);
const currentArt = computed(() => useArtStore().currentArt);
const hasArtSelector = computed(() => currentArt.value.name);

function selectArt(art: Art) {
  navigationActions.changeArt("mainMenu", art);
}
</script>

<template>
  <div class="bread-crumb">
    <template v-if="flexBoard">
      <BaseTooltip class="tooltip">
        <template #content>
          {{ $t("flexModal.collaborationCanvas") }}
        </template>
        <BaseButton
          variant="ghost"
          color="grey"
          icon-before="board/canvas"
          class="select-button"
          @click="selectFlex"
        >
          {{ flexBoard.flexType.name }}
        </BaseButton>
      </BaseTooltip>
      <SvgIcon name="arrow/right" width="20" height="20" aria-hidden="true" />
      <h1>{{ flexBoard.name }}</h1>
    </template>

    <template v-else>
      <BreadCrumbSelector
        v-if="hasArtSelector"
        icon="action-menu/art"
        :title="$t('general.arts')"
        :groups="arts"
        :value="currentArt"
        @change="selectArt"
      />

      <BreadCrumbSelector
        icon="action-menu/team"
        :title="$t('general.teams')"
        :groups="teams"
        :value="currentTeam"
        @change="selectTeam"
      />

      <h1>{{ $t(boardName) }}</h1>
    </template>
  </div>
</template>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/mixins/utils";

.bread-crumb {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--text-secondary-color);

  h1 {
    @include utils.ellipsis;

    margin-left: 8px;
  }

  .select-button {
    font-weight: font.$weight_normal;
  }
}
</style>
