import { createPopper } from "@popperjs/core";
import { Ref, computed, nextTick, ref, watch } from "vue";

import { Card } from "@/model/card";
import variables from "@/model/variable.module.scss";
import { useBoardStore } from "@/store/board";
import { useDraggingStore } from "@/store/dragging";
import { useLinkStore } from "@/store/link";
import { useUserStore } from "@/store/user";
import { useZoomStore } from "@/store/zoom";
import { isElementInViewport } from "@/utils/dom/dom";

interface Props {
  referenceEl: Ref<HTMLDivElement | undefined>;
  popperEl: Ref<HTMLDivElement | undefined>;
  card: Card;
  isPinned: Ref<Readonly<boolean>>;
  disabled: Ref<Readonly<boolean>>;
}

export function useActionMenu({
  referenceEl,
  popperEl,
  card,
  disabled,
  isPinned,
}: Props) {
  const isStickyNoteActive = computed(
    () => useBoardStore().activeCardId === card.id,
  );
  const isStickyNoteDragged = computed(
    () => useDraggingStore().dragging[card.id],
  );
  const isReadOnly = computed(() => !useUserStore().isAllowed("edit"));
  const isOpen = computed(() => {
    return (
      isStickyNoteActive.value &&
      !useZoomStore().zooming &&
      !isStickyNoteDragged.value &&
      !isReadOnly.value &&
      !disabled.value &&
      !useLinkStore().linking.from
    );
  });

  const popper = ref<ReturnType<typeof createPopper> | null>(null);

  const size = parseInt(variables.stickySize.replace("px", ""));

  watch(isOpen, () => {
    // hide the action menu if the sticky note is not in the viewport
    if (isOpen.value && !isElementInViewport(referenceEl.value)) {
      useBoardStore().activeCardId = null;
      delete useBoardStore().currentBoard().selected[card.id];
      return;
    }

    if (!isOpen.value) {
      destroyPopper();
      return;
    }

    void newPopper();
  });

  // update the action menu position when the card type, or priority changes
  watch([() => card.type, () => card.priority], () => popper.value?.update());

  // update the action menu position when the sticky note is pinned
  watch(isPinned, () => {
    if (!popper.value || !referenceEl.value) return;

    const offset = calculateOffset(referenceEl.value, isPinned.value, size);
    void popper.value.setOptions({
      modifiers: [{ name: "offset", options: { offset } }],
    });
  });

  const newPopper = async () => {
    if (popper.value) return;

    await nextTick();

    if (!referenceEl.value || !popperEl.value) return;

    const offset = calculateOffset(referenceEl.value, isPinned.value, size);
    popper.value = createPopper(referenceEl.value, popperEl.value, {
      placement: "top",
      modifiers: [
        { name: "offset", options: { offset } },
        { name: "flip", enabled: false },
      ],
    });
  };
  const destroyPopper = () => {
    popper.value?.destroy();
    popper.value = null;
  };

  return { isOpen };
}

/**
 * Calculates the offset for the action menu of a sticky note.
 *
 * @param referenceEl - The reference element to which the action menu is positioned.
 * @param isPinned - A boolean indicating whether the sticky note is pinned.
 * @param size - The iniail size of the sticky note, 240px
 * @returns A tupple containing the x and y offsets for the action menu.
 */
function calculateOffset(
  referenceEl: Element,
  isPinned: boolean,
  size: number,
) {
  const multiplier = size / referenceEl.getBoundingClientRect().height;
  const offsetY = isPinned ? 11 : 5;

  return [0, offsetY / multiplier];
}
