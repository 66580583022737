<script setup lang="ts">
import { computed, ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";

import StickyLinkRow from "@/components/StickyNote/components/StickyListItem/StickyLinkRow.vue";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { useArtStore } from "@/store/art";
import { useCardStore } from "@/store/card";
import { useTeamStore } from "@/store/team";

import { GroupedLinkableItems, Level, SearchContext } from "../types";
import { groupedLinkableObjectives } from "./groupedLinkableObjectives";
import { groupedLinkableStickies } from "./groupedLinkableStickies";

const props = defineProps<{
  cardIds: string[];
  searchContext: SearchContext;
}>();

const linkableItems = ref<GroupedLinkableItems>({
  currentLevel: { level: "none", items: [] },
  otherLevels: {},
});

const noCurrentLevelItems = computed(
  () => linkableItems.value.currentLevel.items.length === 0,
);
const noOtherLevelsItems = computed(
  () => Object.keys(linkableItems.value.otherLevels).length === 0,
);

const noItems = computed(
  () =>
    noCurrentLevelItems.value &&
    noOtherLevelsItems.value &&
    props.searchContext.query.length === 0,
);

const noSearchResults = computed(
  () =>
    noCurrentLevelItems.value &&
    noOtherLevelsItems.value &&
    props.searchContext.query.length !== 0,
);

const cards = computed(
  () =>
    props.cardIds
      .map((id) => useCardStore().cards[id])
      .filter((card) => !!card), //cards might be loading
);

watchEffect(async () => {
  const groupedObjectives = groupedLinkableObjectives(
    cards.value,
    props.searchContext,
  );

  const groupedStickies = await groupedLinkableStickies(
    cards.value,
    props.searchContext,
  );

  linkableItems.value = mergeGroupedLinkableItems(
    groupedObjectives,
    groupedStickies,
  );
});

function mergeGroupedLinkableItems(
  group1: GroupedLinkableItems,
  group2: GroupedLinkableItems,
): GroupedLinkableItems {
  const otherLevels = { ...group1.otherLevels };

  Object.keys(group2.otherLevels).forEach((key) => {
    otherLevels[key] = otherLevels[key]
      ? {
          ...otherLevels[key],
          items: [...otherLevels[key].items, ...group2.otherLevels[key].items],
        }
      : group2.otherLevels[key];
  });

  return {
    currentLevel: {
      level: group1.currentLevel.level,
      items: [...group1.currentLevel.items, ...group2.currentLevel.items],
    },
    otherLevels,
  };
}

const headerByLevel = (level: Level, id: string) =>
  level === "team"
    ? useTeamStore().findTeam({ id })?.name
    : level === "art"
    ? useArtStore().artById(id)?.name
    : useI18n().t("linkModal.solutionTrain");

const subHeaderByLevel = (level: Level) =>
  level === "team"
    ? useI18n().t("linkModal.team")
    : level === "art"
    ? useI18n().t("linkModal.art")
    : "";
</script>

<template>
  <div v-if="noItems" class="empty">
    <SvgIcon
      class="empty-icon"
      name="search/sticky-note"
      width="20"
      height="20"
    />
    <div class="empty-text">{{ $t("linkModal.noItems") }}</div>
  </div>
  <div v-else-if="noSearchResults" class="empty">
    <SvgIcon
      class="empty-icon"
      name="search/search-results"
      width="20"
      height="20"
    />
    <div class="empty-text">{{ $t("linkModal.noSearchResults") }}</div>
  </div>
  <div v-else class="search-content">
    <div
      v-if="searchContext.query && !noCurrentLevelItems"
      class="search-results-header"
    >
      {{ $t("linkModal.searchResults") }}
    </div>
    <StickyLinkRow
      v-for="(link, id) in linkableItems.currentLevel.items"
      :key="id + 'currentLevel'"
      :target-card-ids="cardIds"
      :link="link"
    />
    <div
      v-if="searchContext.query && !noOtherLevelsItems"
      class="search-other-arts"
    >
      <div class="search-other-arts-divider-container">
        <SvgIcon
          class="search-other-arts-icon"
          name="search/search-results"
          width="20"
          height="20"
        />
        <div class="search-other-arts-text">
          {{ $t("linkModal.searchResultsInOtherARTs") }}
        </div>
      </div>
      <div
        v-for="(group, groupId) in linkableItems.otherLevels"
        :key="groupId"
        class="search-other-arts"
      >
        <div class="other-header-container">
          <div class="other-arts-header">
            {{ headerByLevel(group.level, groupId) }}
          </div>
          <div class="other-arts-subheader">
            {{ subHeaderByLevel(group.level) }}
          </div>
        </div>
        <StickyLinkRow
          v-for="(link, id) in group.items"
          :key="id + 'otherLevels'"
          :target-card-ids="cardIds"
          :link="link"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/mixins/typography";

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 100%;

  .empty-icon {
    color: colors-old.$placeholder-color;
  }

  .empty-text {
    @include typography.small("bold");

    color: colors-old.$placeholder-color;
  }
}

.search-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;

  .search-results-header {
    @include typography.medium("bold");

    margin-bottom: 8px;
  }

  .search-other-arts {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 20px;
    margin-top: 20px;

    .search-other-arts-divider-container {
      display: flex;
      gap: 10px;

      .search-other-arts-icon {
        color: colors-old.$text-secondary-color;
      }

      .search-other-arts-text {
        @include typography.medium("bold");

        color: colors-old.$text-secondary-color;
      }
    }

    .other-header-container {
      .other-arts-header {
        @include typography.medium("bold");

        margin-bottom: 4px;
      }

      .other-arts-subheader {
        @include typography.small("semi-bold");

        color: colors-old.$text-secondary-color;
      }
    }
  }
}
</style>
