<script lang="ts" setup>
import { cardKey } from "@/components/card/injectKeys";
import { injectStrict } from "@/utils/vue";

import CurrentAlmIcon from "../../CurrentAlmIcon.vue";
import ActionMenuItem from "./ActionMenuItem/ActionMenuItem.vue";

const card = injectStrict(cardKey);
</script>

<template>
  <ActionMenuItem
    v-if="card.almId"
    as="a"
    class="alm-menu-item"
    name="alm"
    :tooltip-text="card.almId"
    :aria-label="$t('label.actionMenu.almIssue')"
    :href="card.almIssueUrl"
    target="_blank"
  >
    <CurrentAlmIcon class="icon" width="20" height="20" />
  </ActionMenuItem>
</template>

<style lang="scss" scoped>
.alm-menu-item {
  .icon {
    :deep(path) {
      fill-opacity: 1;
    }
  }
}
</style>
