import { RouteLocation, RouteLocationNamedRaw, Router } from "vue-router";

import { BoardType } from "@/model/baseTypes";
import { Team } from "@/model/session";

export const currentTeam = "_";

export type PiVueRouter = Router & {
  navigatingTo?: RouteLocation;
  navigating: Promise<void>;
  syncPush: (f: () => RouteLocationNamedRaw) => ReturnType<Router["push"]>;
  syncReplace: (
    f: () => RouteLocationNamedRaw,
  ) => ReturnType<Router["replace"]>;
};

export type AppParams = {
  [param in "session" | "team" | "board" | "name" | "query"]: string;
};

export type LogoutReason =
  | "jwt-revoked"
  | "refresh-failed"
  | "connection-refused"
  | "user-invoked"
  | "unknown";

export type BoardTarget =
  | BoardType
  | { type: "flex"; name?: string }
  | { type: Exclude<BoardType, "flex">; team?: Team; artId?: string };

const queryProps = [
  "zoom",
  "scrollX",
  "scrollY",
  "feature",
  "category",
  "searchText",
  "searchFlag",
  "searchAssignee",
  "searchTeam",
  "searchArt",
  "searchIteration",
  "searchType",
  "searchLink",
  "searchStatusClass",
  "searchPos",
  "searchId",
  "searchDepLink",
] as const;

export type QueryImpl = Partial<{
  [key in (typeof queryProps)[number]]: string;
}>;

export function toQueryImpl(query: Iterable<[string, string]>): QueryImpl {
  return Object.fromEntries(
    [...query].filter(([key, _]) => queryProps.includes(key as any)),
  );
}

export interface Params {
  session?: string;
  team?: string;
  art?: string;
  board?: string;
  name?: string;
}

export interface Query {
  zoom?: string;
  scrollX?: string;
  scrollY?: string;
  search?: SearchQuery;
  category?: string;
}

export interface SearchQuery {
  text?: string;
  flag?: string;
  assignee?: string;
  team?: string;
  art?: string;
  iteration?: string;
  type?: string;
  depLink?: string;
  link?: string;
  statusClass?: string;
  pos?: string;
  id?: string;
}
