<script lang="ts" setup>
import BaseList from "@/components/ui/BaseList/BaseList.vue";
import BaseListItem from "@/components/ui/BaseList/components/BaseListItem/BaseListItem.vue";
import BaseListItems from "@/components/ui/BaseList/components/BaseListItems/BaseListItems.vue";
import BaseListTitle from "@/components/ui/BaseList/components/BaseListTitle/BaseListTitle.vue";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import type { BoardData } from "@/model/board";

type TeamBoard = BoardData<"team">;
export type SelectEvent =
  | { type: "team-board"; board: TeamBoard }
  | { type: "risk-board" };

defineProps<{ boards: TeamBoard[] }>();

const emit = defineEmits<{ select: [SelectEvent] }>();
</script>

<template>
  <BaseList role="dialog" aria-labelledby="board-selector-title">
    <BaseListTitle id="board-selector-title">
      {{ $t("action.moveSticky") }}
    </BaseListTitle>
    <BaseListItems aria-labelledby="board-selector-title">
      <BaseListItem @click="emit('select', { type: 'risk-board' })">
        <template #before>
          <SvgIcon name="board/team" width="20" height="20" />
        </template>
        {{ $t("board.risk") }}
      </BaseListItem>
      <BaseListItem
        v-for="board in boards"
        :key="board.id"
        @click="emit('select', { type: 'team-board', board })"
      >
        <template #before>
          <SvgIcon name="board/team" width="20" height="20" />
        </template>
        {{ $t("board.team.long", { team: board.team.name }) }}
      </BaseListItem>
    </BaseListItems>
  </BaseList>
</template>
