<script lang="ts" setup>
import { computed } from "vue";

import { boardKey } from "@/components/board/injectKeys";
import { cardKey } from "@/components/card/injectKeys";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { injectStrict } from "@/utils/vue";

import MirrorSticky from "../../MirrorSticky/MirrorSticky.vue";
import ActionMenuItem from "../components/ActionMenuItem/ActionMenuItem.vue";
import { isActionVisible } from "../utils";

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);

const isVisible = computed(() => {
  return isActionVisible("program", card, board.value);
});
</script>

<template>
  <ActionMenuItem
    v-if="isVisible"
    name="program"
    :tooltip-text="$t('cardAction.program')"
    :aria-label="$t('cardAction.program')"
    aria-controls="program-dropdown"
  >
    <SvgIcon
      name="action-menu/mirror-to-planning-board"
      width="20"
      height="20"
    />
    <template #dropdown>
      <MirrorSticky id="program-dropdown" trigger="action-menu" />
    </template>
  </ActionMenuItem>
</template>
