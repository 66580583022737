<script setup lang="ts">
import { ref } from "vue";

import TreeNode from "./components/TreeNode.vue";
import { TreeNodeData } from "./types";

const props = defineProps<{
  nodes: TreeNodeData[];
}>();

const emit = defineEmits<{ select: [node: TreeNodeData] }>();

const selectedNode = ref<string | null>(null);

const handleSelectNode = (node: TreeNodeData) => {
  selectedNode.value = node.id;
  emit("select", node);
};
</script>

<template>
  <div class="tree-view" role="tree" aria-label="Tree View">
    <TreeNode
      v-for="(node, index) in props.nodes"
      :key="node.id"
      :node="node"
      :selected-node="selectedNode"
      :indentation="0"
      :is-first-node="index === 0"
      :is-last-node="index === node.children.length - 1"
      @select-node="handleSelectNode"
    />
  </div>
</template>

<style lang="scss" scoped>
.tree-view {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
