<script setup lang="ts">
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";

const emit = defineEmits<{ close: []; delete: [] }>();
</script>

<template>
  <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions-->
  <div
    v-focus-trap
    role="dialog"
    aria-modal="true"
    @click="emit('close')"
    @keydown.esc.stop="emit('close')"
    @keydown.stop
  >
    <div class="delete-dialog">
      {{ $t("flexModal.deleteCategoryDialog") }}
      <div class="bottom">
        <button
          v-autofocus
          class="action line line-right item"
          @click="emit('close')"
        >
          {{ $t("general.cancel") }}
        </button>
        <button class="action item" @click="emit('delete')">
          <SvgIcon name="action-menu/delete" width="20" height="20" />
          {{ $t("flexBoardElem.delete") }}
        </button>
      </div>
    </div>
  </div>
</template>
