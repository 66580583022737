<script setup lang="ts">
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import BaseTooltip from "@/components/ui/BaseTooltip/BaseTooltip.vue";
import DropdownMenu from "@/components/ui/DropdownMenu/DropdownMenu.vue";
import SelectableList from "@/components/ui/SelectableList/SelectableList.vue";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { Icon } from "@/model/icon";
import { Art, Session, Team } from "@/model/session";

type BreadCrumbItem = Session | Art | Team;

defineProps<{
  icon: Icon;
  title: string;
  groups: BreadCrumbItem[];
  value: BreadCrumbItem;
}>();
const emit = defineEmits<{ change: [item: BreadCrumbItem] }>();
</script>

<template>
  <span class="bread-crumb-selector">
    <DropdownMenu
      :style="{ maxHeight: '300px' }"
      :offset="{ top: 12 }"
      width="sm"
      :is-aria-menu="true"
    >
      <template #trigger="{ isOpen }">
        <BaseTooltip class="tooltip">
          <template #content>
            {{ title }}
          </template>
          <BaseButton
            variant="ghost"
            color="grey"
            data-dropdown-trigger
            :icon-before="icon"
            :activated="isOpen"
            :aria-expanded="isOpen"
            aria-controls="group-dropdown-menu"
            aria-haspopup="menu"
          >
            <span class="text">{{ value.name }}</span>
          </BaseButton>
        </BaseTooltip>
      </template>
      <SelectableList
        id="group-dropdown-menu"
        v-keyboard-nav
        role="dialog"
        :header="title"
        :values="groups"
        :model-value="value"
        @update:model-value="emit('change', $event)"
      >
        <template #default="{ value }">
          {{ value.name }}
        </template>
      </SelectableList>
    </DropdownMenu>

    <SvgIcon name="arrow/right" width="20" height="20" aria-hidden="true" />
  </span>
</template>

<style lang="scss" scoped>
@use "@/styles/font";

.bread-crumb-selector {
  display: flex;
  align-items: center;
  overflow: hidden;

  .dropdown-menu {
    overflow: hidden;
  }

  .base-button {
    overflow: hidden;
    font-weight: font.$weight_normal;
  }

  .tooltip {
    display: flex;
  }

  & > svg {
    flex-shrink: 0;
  }
}
</style>
