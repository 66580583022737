<script setup lang="ts">
import { computed } from "vue";

import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import { Art } from "@/model/session";
import { navigateBack, navigateForward } from "@/router/navigation";
import { useArtStore } from "@/store/art";
import { useSessionStore } from "@/store/session";
import { useUserStore } from "@/store/user";
import { artBackButtonClicked, artSelected } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { neverResolve } from "@/utils/general";

import ChooseButton from "../components/ChooseButton.vue";
import HomeHeader from "../components/HomeHeader.vue";

const selectedSessionName = computed(
  () => useSessionStore().session.selected?.name,
);
const artsList = computed(() => useArtStore().arts);

function isUserArt(art: Art) {
  return useUserStore().technicalUser.teams.some(
    (team) => team.artId === art.id,
  );
}

function back() {
  trackEvent(artBackButtonClicked());
  void navigateBack();
}

function select(art: Art) {
  trackEvent(artSelected());

  useArtStore().setArt(art);
  void navigateForward();
  return neverResolve();
}
</script>

<template>
  <main
    v-autofocus
    class="home-overview art-page"
    tabindex="-1"
    aria-labelledby="art-page-subtitle"
  >
    <HomeHeader>
      <template #back>
        <BaseButton
          variant="ghost"
          color="grey"
          icon-before="arrow/left"
          @click="back"
        >
          {{ $t("page.backToSessions") }}
        </BaseButton>
      </template>
      <template #title>
        <h1 id="art-page-title">{{ selectedSessionName }}</h1>
      </template>
      <template #subtitle>
        <div id="art-page-subtitle">{{ $t("artPage.select") }}</div>
      </template>
    </HomeHeader>

    <hr class="separator" aria-hidden="true" />
    <div class="scrollable scrollable-relative scrollable-content">
      <ChooseButton
        v-for="art in artsList"
        :key="art.id"
        size="medium"
        :class="{ mark: isUserArt(art) }"
        @click="select(art)"
      >
        <div class="two-line-ellipsis-text">
          {{ art.name }}
        </div>
      </ChooseButton>
    </div>
  </main>
</template>

<style lang="scss" scoped>
@use "@/styles/team-art-list" as *;

.art-page {
  h1 {
    margin-top: 21px;
  }
}
</style>
