<script setup lang="ts">
import { Modifier, createPopper, detectOverflow } from "@popperjs/core";
import { computed, nextTick, provide, ref, watch } from "vue";

import { useNativeEvents } from "@/composables/useNativeEvents";
import { eventTarget } from "@/utils/dom/dom";

import BasePopup, { Props as BasePopupProps } from "../BasePopup/BasePopup.vue";
import { dropdownKey } from "./injectKeys";

interface BaseProps {
  offset?: { left?: number; top?: number };
  noFlip?: boolean; // avoid flipping the placement from bottom to top, but instead just move the contents to fit inside the window
  disableTeleport?: boolean;
  width?: BasePopupProps["width"];
  maxHeight?: BasePopupProps["maxHeight"];
  disabled?: boolean; // disables user interactions on the trigger
  readonly?: boolean; // disables user interactions, but without the 'disabled' styling
  isAriaMenu?: boolean; // adds menu-appropriate key listeners
}

interface WithExternalOpenState {
  isOpen: boolean; // manage the open state from outside
  stayOpenOnContentClick?: never; // don't close the dropdown when the user clicks on the content container
  stayOpenOnOutsideClick?: never; // don't close the dropdown when the user clicks outside the trigger and content container
}

interface WithInternalOpenState {
  isOpen?: never;
  stayOpenOnContentClick?: boolean;
  stayOpenOnOutsideClick?: boolean;
}

type PropsWithExternalOpenState = BaseProps & WithExternalOpenState;
type PropsWithInternalOpenState = BaseProps & WithInternalOpenState;
export type DropdownMenuProps =
  | PropsWithExternalOpenState
  | PropsWithInternalOpenState;

const props = withDefaults(defineProps<DropdownMenuProps>(), {
  isOpen: undefined,
  offset: () => ({ left: 0, top: 5 }),
  noFlip: false,
  stayOpenOnContentClick: false,
  stayOpenOnOutsideClick: false,
  disableTeleport: false,
  width: "auto",
  maxHeight: "lg",
  disabled: false,
});
const emit = defineEmits<{ close: []; open: [] }>();

const isOpenInternal = ref(false);
const triggerRef = ref<HTMLElement>();
const contentRef = ref<HTMLElement>();
const popper = ref<ReturnType<typeof createPopper> | null>(null);

const contentId = `dropdown-content_${Math.random().toString().substring(2)}`;

provide(dropdownKey, { close });

defineExpose({ close });

const isStateManagedExternally = computed(() => props.isOpen !== undefined);
const computedOpenState = computed(() => props.isOpen ?? isOpenInternal.value);
const { addEventListener, removeEventListeners } = useNativeEvents();

function close() {
  isOpenInternal.value = false;
}

const move: Modifier<"move", Record<string, never>> = {
  name: "move",
  enabled: true,
  phase: "main",
  requiresIfExists: ["offset"],
  fn({ state }) {
    const overflow = detectOverflow(state, {});
    if (overflow.bottom > 0 && state.modifiersData.popperOffsets) {
      state.modifiersData.popperOffsets.y -= overflow.bottom;
    }
  },
};

const handleKeyEvent = (event: KeyboardEvent) => {
  if (isStateManagedExternally.value) return;

  // close the dropdown when the user presses the escape key
  // (or when the user presses the tab key and the dropdown is an ARIA 'menu')
  if (event.key === "Escape" || (props.isAriaMenu && event.key === "Tab")) {
    isOpenInternal.value = false;
    event.stopPropagation();
  }
};

/**
 * Focuses the trigger element when the dropdown is closed.
 */
const focusOnTrigger = () => {
  const el = triggerRef.value?.querySelector("[data-dropdown-trigger]");
  (el as HTMLElement)?.focus();
};

// only triggered when the component manges its own open state
const handleOutsideClick = (event: MouseEvent) => {
  const target = eventTarget(event);
  if (
    !triggerRef.value?.contains(target) &&
    !contentRef.value?.contains(target) &&
    !target?.closest(".base-popup") //clicked on another popup, assume it's a child popup and don't close
  ) {
    isOpenInternal.value = false;
  }
};

function handleTriggerClick() {
  if (isStateManagedExternally.value) return;
  if (props.disabled || props.readonly) return;

  isOpenInternal.value = !isOpenInternal.value;
}

function handleContentClick() {
  if (isStateManagedExternally.value) return;

  if (!props.stayOpenOnContentClick) {
    isOpenInternal.value = false;
  }
}

watch(isOpenInternal, (val) => {
  if (!val) emit("close");
});

// create a popper when the dropdown is opened
watch(computedOpenState, async (state) => {
  if (!state && popper.value) {
    popper.value.destroy();
    popper.value = null;
    emit("close");
  }

  if (!state) {
    removeEventListeners();
    focusOnTrigger();
    return;
  }

  await nextTick();

  if (!props.stayOpenOnOutsideClick) {
    addEventListener(document, "pointerdown", handleOutsideClick, true);
  }

  // Handle both local and global key events (to ensure the dropdown is closed when
  // the user presses escape, even if the focus has left the dropdown)
  addEventListener(triggerRef.value, "keydown", handleKeyEvent);
  addEventListener(contentRef.value, "keydown", handleKeyEvent);
  addEventListener(document, "keyup", handleKeyEvent);

  emit("open");

  createPopper(
    triggerRef.value?.firstElementChild as HTMLElement,
    contentRef.value?.firstElementChild as HTMLElement,
    {
      // to not flicker search dropdowns when zooming, might break in other places?
      strategy: "fixed",
      placement: "bottom-start",
      modifiers: [
        {
          name: "offset",
          options: { offset: [props.offset.left, props.offset.top] },
        },
        ...(props.noFlip ? [{ name: "flip", enabled: false }, move] : []),
      ],
    },
  );
});
</script>

<template>
  <div
    class="dropdown-menu"
    :aria-owns="computedOpenState ? contentId : undefined"
  >
    <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions-->
    <div
      ref="triggerRef"
      :class="['trigger', { disabled: props.disabled }]"
      @click="handleTriggerClick"
    >
      <slot name="trigger" :is-open="isOpenInternal" />
    </div>
    <Teleport v-if="computedOpenState" :disabled="disableTeleport" to="body">
      <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions-->
      <div :id="contentId" ref="contentRef" @click="handleContentClick">
        <BasePopup
          v-scrollable-on-board
          class="content"
          :class="$attrs.class"
          :width="width"
          :max-height="maxHeight"
          tabindex="-1"
        >
          <!-- ^ tabindex of -1 to make popup focusable -->
          <slot />
        </BasePopup>
      </div>
    </Teleport>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/z-index";

.dropdown-menu {
  display: inline-block;

  .trigger {
    height: 100%;
    cursor: pointer;
    position: relative;

    &.disabled {
      cursor: not-allowed;
    }
  }
}

.content {
  z-index: z-index.$popup-menu;

  // TODO: remove this when the list-item component is deleted
  &:deep(.list-item) {
    border-radius: 4px;
  }
}
</style>
