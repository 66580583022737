<script setup lang="ts">
import { computed, markRaw, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

import { useBoardsStore } from "@/store/boards";
import { useModalStore } from "@/store/modal";

import ModalHeaderLegacy from "../ModalHeaderLegacy.vue";
import ModalLegacy from "../ModalLegacy.vue";
import MetricsModalCapacityAllocationBody from "./MetricsModalCapacityAllocationBody.vue";
import MetricsModalLoadCapacityBody from "./MetricsModalLoadCapacityBody.vue";
import ButtonGroup, { ButtonGroupButton } from "./components/ButtonGroup.vue";

export interface Tab {
  id: string;
  content: string;
  title: string;
  subtitle: string;
  component: any;
  isActive: boolean;
}

const props = withDefaults(
  defineProps<{ tab?: string; overrides?: { tabs?: Tab[] } }>(),
  {
    tab: "allocation",
    overrides: undefined,
  },
);

const { t } = useI18n();
const board = computed(() => useBoardsStore().boardByType("team"));
const width = ref(0);
const height = ref(0);
const tabs = ref(
  props.overrides?.tabs || [
    {
      id: "allocation",
      content: t("metricsModal.capacityAllocation"),
      title: t("action.teamMetrics"),
      subtitle: t("general.namedTeam", { name: board.value.team.name }),
      component: markRaw(MetricsModalCapacityAllocationBody),
      isActive: true,
    },
    {
      id: "load",
      content: t("metricsModal.loadVsCapacity"),
      title: t("action.teamMetrics"),
      subtitle: t("general.namedTeam", { name: board.value.team.name }),
      component: markRaw(MetricsModalLoadCapacityBody),
      isActive: false,
    },
  ],
);

onMounted(() => {
  width.value = document.documentElement.clientWidth * 0.75;
  height.value = document.documentElement.clientHeight * 0.85;
  tabChanged();
});

// TODO moving isActive out of this.tabs, we can get rid of the watcher (see PlanReadoutModel)
watch(() => props.tab, tabChanged);

const activeTab = computed(() => tabs.value.find((tab) => tab.isActive)!);
const activeTabComponent = computed(() => activeTab.value?.component);

function tabChanged() {
  tabs.value.forEach((tab) => (tab.isActive = tab.id === props.tab));
}

function changeTab(tabs: ButtonGroupButton[]) {
  const tab = tabs.find((tab) => tab.isActive)!;
  useModalStore().changeAttrs({ tab: tab.id });
}
</script>

<template>
  <ModalLegacy
    class="load-stat-modal"
    set-return-focus="#metrics-modal-trigger"
    :width="width"
    :height="height"
    :description="
      $t('label.metricsModal.team.description', { team: board.team.name })
    "
  >
    <ModalHeaderLegacy :title="activeTab.title">
      <h2>{{ activeTab.subtitle }}</h2>
      <template #action>
        <div>
          <ButtonGroup :buttons="tabs" @change="changeTab" />
        </div>
      </template>
    </ModalHeaderLegacy>
    <div class="head" />
    <component :is="activeTabComponent" />
  </ModalLegacy>
</template>

<style lang="scss" scoped>
.load-stat-modal {
  .head {
    margin-top: 0;
  }
}

:deep(.button-group) {
  flex-direction: column;
}
</style>
