<script setup lang="ts">
import { v4 as uuid } from "uuid";

import SearchInput from "@/components/SearchInput/SearchInput.vue";
import BaseList from "@/components/ui/BaseList/BaseList.vue";
import BaseListItems from "@/components/ui/BaseList/components/BaseListItems/BaseListItems.vue";
import BaseListTitle from "@/components/ui/BaseList/components/BaseListTitle/BaseListTitle.vue";

defineProps<{
  title: string;
  placeholder?: string;
  iconTitle: string;
  listLen?: number;
}>();

const id = uuid();

const model = defineModel<string>({ required: true });
</script>

<template>
  <BaseListTitle :id="id">{{ title }}</BaseListTitle>
  <div class="search-container">
    <SearchInput
      v-model="model"
      autofocus
      icon-position="after"
      size="small"
      :placeholder="placeholder"
      :icon-title="iconTitle"
      :results-count="listLen"
      @focusout.stop
    />
  </div>
  <BaseList
    v-keyboard-nav.soft-focus.soft-focus-initial
    class="select-list"
    :aria-labelledby="id"
    role="dialog"
  >
    <BaseListItems :aria-labelledby="id">
      <slot />
    </BaseListItems>

    <p v-if="!listLen" class="list-item static">
      {{ $t("searchResults.noResults") }}
    </p>
  </BaseList>
</template>

<style lang="scss" scoped>
.search-container {
  padding: 8px;
}

.select-list {
  max-height: 330px;

  .after svg {
    display: block;
  }
}
</style>
