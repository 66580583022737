<script setup lang="ts">
import { ref } from "vue";

import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";

defineProps<{ value: string }>();

const buttonRef = ref<HTMLElement | null>(null);
const focus = () => {
  buttonRef.value?.focus();
};
defineExpose({ focus });
</script>

<template>
  <BaseButton
    ref="buttonRef"
    variant="outlined"
    color="grey"
    class="dropdown-trigger"
    data-dropdown-trigger
    icon-after="arrow/down"
  >
    <span>{{ value }}</span>
  </BaseButton>
</template>

<style lang="scss">
.base-button.dropdown-trigger {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
}
</style>
