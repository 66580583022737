<script lang="ts" setup>
import { computed } from "vue";

import { boardKey } from "@/components/board/injectKeys";
import { cardKey, cardMetaKey } from "@/components/card/injectKeys";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { stickyNotePinned, stickyNoteUnpined } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { injectStrict } from "@/utils/vue";

import ActionMenuItem from "../ActionMenuItem/ActionMenuItem.vue";

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);
const cardMeta = injectStrict(cardMetaKey);

const isVisible = computed(() => !cardMeta?.isPinningDisabled);

const toggle = () => {
  const cardType = card.type.functionality;
  const boardType = board.value.type;

  if (cardMeta.isPinned) {
    cardMeta.unpin();
    trackEvent(stickyNoteUnpined(cardType, boardType, "action-menu"));
  } else {
    cardMeta.pin();
    trackEvent(stickyNotePinned(cardType, boardType, "action-menu"));
  }
};
</script>

<template>
  <ActionMenuItem
    v-if="isVisible"
    name="pin"
    :active="cardMeta.isPinned"
    :tooltip-text="
      cardMeta.isPinned ? $t('cardAction.unpin') : $t('cardAction.pin')
    "
    :aria-label="$t('label.actionMenu.pin')"
    @click="toggle"
  >
    <SvgIcon name="action-menu/pin" width="20" height="20" />
  </ActionMenuItem>
</template>
