import { SessionData } from "@/backend/Backend";
import { closeConnection } from "@/backend/Connection";
import { emptySelectList } from "@/model/session";
import { useBoardStore } from "@/store/board";
import { useBoardsStore } from "@/store/boards";
import { useCardStore } from "@/store/card";
import { useLicenseStore } from "@/store/license";
import { useLinkStore } from "@/store/link";
import { useSessionStore } from "@/store/session";
import { useStickyTypeStore } from "@/store/stickyType";
import { useTeamStore } from "@/store/team";
import { useZoomStore } from "@/store/zoom";

export function setSessionData(data: SessionData) {
  useStickyTypeStore().stickyTypes = data.stickyTypes;
  useStickyTypeStore().almStickyTypes = data.almStickyTypes;
  useCardStore().cards = {};
  useBoardsStore().resetBoards();
  if (data.boards) {
    for (const id in data.boards) {
      const board = data.boards[id];
      board.shapes.push(...useBoardStore().boardLocation(0, 0, board).shapes);
    }
    useBoardsStore().addBoards(data.boards);
  }
  useLinkStore().linkTypes = data.linkTypes || [];
  useLinkStore().setLinks(data.links || []);
}

export function clearSessionData() {
  void closeConnection();
  useZoomStore().setZoomFactor(1);
  useSessionStore().session = emptySelectList();
  useTeamStore().team = emptySelectList();
  useLicenseStore().license = {
    daysTillExpiration: null,
    usable: true,
    plan: "standard",
    tracking: false,
    type: "trial",
  };

  useStickyTypeStore().stickyTypes = [];
  useCardStore().cards = {};
  useBoardsStore().resetBoards();
  useLinkStore().linkTypes = [];
  useLinkStore().setLinks([]);
}
