<script lang="ts" setup>
import { computed } from "vue";

import { boardKey } from "@/components/board/injectKeys";
import { cardKey } from "@/components/card/injectKeys";
import StickyLinkModal from "@/components/modal/stickyLink/StickyLinkModal.vue";
import StickyLinkModalNg from "@/components/modal/stickyLinkNg/StickyLinkModal.vue";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { Card } from "@/model/card";
import { currentRoute } from "@/router/navigation";
import { useModalStore } from "@/store/modal";
import { stickyNoteLinkingModalOpened } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { isFeatureEnabled } from "@/utils/env/feature";
import { injectStrict } from "@/utils/vue";

import { isActionVisible } from "../../utils";
import ActionMenuItem from "../ActionMenuItem/ActionMenuItem.vue";

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);

const visible = computed(() => isActionVisible("link", card, board.value));

const showNgStickyLinkModal = computed(() =>
  isFeatureEnabled(currentRoute(), "sticky-link"),
);

const handleLinkModalOpen = (card: Card) => {
  const stickyType = card.type.functionality;
  trackEvent(
    stickyNoteLinkingModalOpened(stickyType, board.value.type, "action-menu"),
  );

  useModalStore().open(
    showNgStickyLinkModal.value ? StickyLinkModalNg : StickyLinkModal,
    {
      attrs: { cardIds: [card.id], "data-ignore-click": true },
    },
  );
};
</script>

<template>
  <ActionMenuItem
    v-if="visible"
    name="link"
    :tooltip-text="$t('cardAction.link')"
    :aria-label="$t('label.actionMenu.link')"
    @click="handleLinkModalOpen(card)"
  >
    <SvgIcon name="action-menu/link" width="20" height="20" />
  </ActionMenuItem>
</template>
