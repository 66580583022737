<script lang="ts" setup>
import { computed } from "vue";

import StickyLinkRow from "@/components/StickyNote/components/StickyListItem/StickyLinkRow.vue";
import { cardMetaKey } from "@/components/card/injectKeys";
import BaseList from "@/components/ui/BaseList/BaseList.vue";
import BaseListFooter from "@/components/ui/BaseList/components/BaseListFooter/BaseListFooter.vue";
import BaseListItems from "@/components/ui/BaseList/components/BaseListItems/BaseListItems.vue";
import BaseListTitle from "@/components/ui/BaseList/components/BaseListTitle/BaseListTitle.vue";
import { dropdownKey } from "@/components/ui/DropdownMenu/injectKeys";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { sortLinkableItems } from "@/components/utils/linkableItems";
import { LinkableItem } from "@/model/link";
import { injectStrict } from "@/utils/vue";

const props = defineProps<{ targetCardIds: string[]; links: LinkableItem[] }>();
const emit = defineEmits<{
  add: [];
}>();

const sortedLinks = computed(() => sortLinkableItems(props.links));

const dropdown = injectStrict(dropdownKey);
const cardMeta = injectStrict(cardMetaKey);

const handleAdd = () => {
  emit("add");
  dropdown.close();
};
</script>

<template>
  <BaseList class="sticky-links">
    <BaseListTitle v-autofocus>
      {{ $t("general.links") }}
    </BaseListTitle>
    <BaseListItems
      class="base-list-items"
      :static="true"
      :aria-label="$t('general.links')"
    >
      <div v-if="!sortedLinks.length" class="empty">
        <div class="empty-links-content">
          <SvgIcon name="action-menu/link" height="20" width="20" />
          <span>{{ $t("general.noLinkedStickies") }}</span>
        </div>
      </div>
      <template v-for="(link, id) in sortedLinks" v-else :key="id">
        <StickyLinkRow :target-card-ids="targetCardIds" :link="link" />
      </template>
    </BaseListItems>
    <BaseListFooter
      :label="$t('linkChip.addLinks')"
      :disabled="cardMeta.isReadonly"
      @click="handleAdd"
    />
  </BaseList>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.sticky-links {
  font-size: 12px;

  .base-list-items {
    display: flex;
    gap: 12px;
    padding: 12px;
  }

  .empty {
    color: colors-old.$placeholder-color;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    .empty-links-content {
      display: flex;
      align-items: center;
      height: 20px;
      white-space: nowrap;
      gap: 8px;
    }
  }
}
</style>
