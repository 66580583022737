import { ComputedRef, Ref, computed, onMounted, ref, watch } from "vue";

import { boardActions } from "@/action/boardActions";
import { cardActions } from "@/action/cardActions";
import StickyNoteTextEditable from "@/components/StickyNote/components/StickyNoteText/StickyNoteTextEditable.vue";
import { cardsInLocation } from "@/components/board/location/BoardLocation";
import { isKeyDown } from "@/components/utils/Shortcuts";
import { useDelayedAction } from "@/composables/utils/useDelayedAction";
import { byPosition, skipButtonId } from "@/directives/ownedCards";
import { sendStickyNoteAction } from "@/mixins/EventBusUser";
import { longClick } from "@/model/Settings";
import { Board, isBacklogBoard } from "@/model/board";
import { Card, CardMeta } from "@/model/card";
import { useBoardStore } from "@/store/board";
import { useDraggingStore } from "@/store/dragging";
import { useSelectionStore } from "@/store/selection";
import { eventTarget } from "@/utils/dom/dom";

export interface Props {
  el: Ref<HTMLDivElement | undefined>;
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  textInput: Ref<typeof StickyNoteTextEditable | undefined>;
  card: Card;
  board: Ref<Board>;
  cardMeta: CardMeta;
  disabled?: Ref<boolean>;
}

interface UseSelectReturn {
  isSelected: ComputedRef<Readonly<boolean>>;
  deactivateSticky: () => void;
  tabindex: number;
  wasActivatedByKeyboard: Ref<boolean>;
}

export function useSelect({
  el,
  textInput,
  board,
  card,
  cardMeta,
  disabled,
}: Props): UseSelectReturn {
  const wasDragged = ref(false);
  const wasDraggedFromPalette = ref(false);

  // Tracks whether the sticky note was activated by keyboard
  const wasActivatedByKeyboard = ref(false);

  // Props for managing cards' tab order within board sections (on non-backlog boards)
  const managedTabOrder = computed(() => !isBacklogBoard(board.value?.type));
  const nextTabTarget = ref<HTMLElement | null>(null);
  const previousTabTarget = ref<HTMLElement | null>(null);

  const boardStore = useBoardStore();
  const dragStore = useDraggingStore();

  const isSelected = computed(() => !!boardStore.board?.selected?.[card.id]);
  const isActive = computed(() => boardStore.activeCardId === card.id);

  // Used to cancel the selection of the sticky note when the user
  // holds the left mouse button pressed on the board for more then 500ms
  const wasLongPressed = ref(false);
  const [setWasLongPressed, cancelSetWasLongPressed] = useDelayedAction(() => {
    if (wasDragged.value) return;
    wasLongPressed.value = true;
  }, longClick);

  onMounted(() => {
    if (disabled?.value) return;

    // event listeners for selecting the sticky note
    el.value?.addEventListener("click", handleStickyNoteClick);
    el.value?.addEventListener("pointerdown", handlePointerDown);
    el.value?.addEventListener("pointerenter", handlePointerEnter);
    el.value?.addEventListener("keydown", handleKeyDown);
    el.value?.addEventListener("focusin", handleFocusIn);
  });

  // set wasDragged to true when the user drags the sticky note
  watch(
    () => dragStore.dragging[card.id],
    (drag) => {
      if (drag?.fromPalette) wasDraggedFromPalette.value = true;
      if (drag) wasDragged.value = true;
    },
  );

  const handleStickyNoteClick = (event: UIEvent, fromKeyboard?: boolean) => {
    // Track whether the sticky note was activated by keyboard, so focus
    // can be returned to the sticky note after deactivation if needed
    wasActivatedByKeyboard.value = !!fromKeyboard;
    // if the action was already executed, this is a noop
    cancelSetWasLongPressed();

    if (wasLongPressed.value) {
      wasLongPressed.value = false;
      return;
    }

    // select sticky notes with a single click when the user has the
    // single click select mode enabled
    const isSingleClickSelect = useSelectionStore().selecting === "click";
    if (isSingleClickSelect && !wasDragged.value) {
      boardStore.setActiveCardId(null);
      boardActions.toggleCardSelection("board", card.id);
      return;
    }

    // Multi select when the user clicks on the sticky note
    // while holding cmd on MacOS or ctrl on Windows or
    const isMultiSelect = isKeyDown("MetaLeft") || isKeyDown("MetaRight");
    if (isMultiSelect && boardStore.activeCardId !== card.id) {
      boardStore.setActiveCardId(null);
      boardActions.toggleCardSelection("mouse", card.id);
      return;
    }

    const target = eventTarget(event)!;

    // don't set the sticky note as active if the
    // user clicks on [data-ignore-click] element
    if (target.closest("[data-ignore-click]")) return;

    // If the button is disabled (eg. in overview modal), ignore click
    if (
      el.value?.getAttribute("aria-disabled") &&
      el.value?.getAttribute("aria-disabled") !== "false"
    ) {
      return;
    }

    if (
      isActive.value &&
      !document.activeElement?.querySelector(".sticky-note-text-input")
    ) {
      textInput.value?.focus();
      return;
    }

    if (isActive.value) return;

    // disable clicks when the spacebar is down
    if (isKeyDown("Space")) return;
    // // clicking was disabled from the outside
    if (disabled?.value) return;

    // Prevents the card from being selected when the user drags the card.
    // Ignore drag events from the sticky note palette
    if (wasDragged.value && !wasDraggedFromPalette.value) {
      wasDragged.value = false;
      wasDraggedFromPalette.value = false;
      return;
    }

    document.addEventListener("pointerdown", handleOutsideClick, true);

    textInput.value?.focus();
    cardActions.startAlter("internal", card.id);
    boardActions.cardToFront("card", card.id);

    boardActions.clearCardSelection("card");

    boardStore.selectCard(card.id);
    boardStore.setActiveCardId(card.id);
  };

  // Set the activeCardId to null when the user clicks outside the card
  const handleOutsideClick = (event: MouseEvent) => {
    const target = eventTarget(event)!;

    // the click came from the organize stickies menu at the bottom of the screen
    if (target.closest("[data-organize-stickies]")) {
      return;
    }

    // the click came from  sticky note
    if (target.closest(`[id="${card.id}"].sticky-note `)) {
      return;
    }

    // we ignore clicks from any html element that has data-ignore-click attribute so the sticky note
    // does not get deselected when we slick on an item which was teleported to the body
    if (target.closest("[data-ignore-click]")) {
      return;
    }

    // the click was outside of a sticky note
    boardStore.setActiveCardId(null);
    sendStickyNoteAction(card.id, { action: "shrink" });
    boardActions.clearCardSelection("mouse");

    document.removeEventListener("pointerdown", handleOutsideClick, true);
  };

  // used to deselect all selected cards when the user clicks on a sticky note
  // while not holding the cmd or ctrl keys
  const handlePointerDown = (event: PointerEvent) => {
    const target = eventTarget(event)!;

    // ignore clicks on the link drag icon
    const isLinkDrag = target.classList.contains("link-drag");
    if (isLinkDrag) return;

    setWasLongPressed();

    const isMultiSelect = isKeyDown("MetaLeft") || isKeyDown("MetaRight");
    const isSingleClickSelect = useSelectionStore().selecting === "click";
    const isOneOfSelected = !!boardStore.board?.selected[card.id];

    if (
      !isSingleClickSelect &&
      !isMultiSelect &&
      !isOneOfSelected &&
      target.closest(".sticky-note")
    ) {
      boardActions.clearCardSelection("card");
      boardStore.setActiveCardId(null);
    }
  };

  const handlePointerEnter = (e: PointerEvent) => {
    // Clicking on the inputText changes it from div to textarea.
    // This triggers a pointerenter event that should be ignored
    // relatedTarget is the div that was removed
    if (
      disabled?.value ||
      (e.relatedTarget as HTMLElement)?.isConnected === false
    ) {
      return;
    }
    document.addEventListener("keydown", handleDocumentKeyDown);

    const isShiftKeyDown = isKeyDown("ShiftLeft") || isKeyDown("ShiftRight");
    if (!boardStore.activeCardId && isShiftKeyDown) {
      boardActions.toggleCardSelection("mouse", card.id);
    }

    el.value?.addEventListener("pointerleave", handlePointerLeave, {
      once: true,
    });
  };

  const handlePointerLeave = () => {
    document.removeEventListener("keydown", handleDocumentKeyDown);
  };

  const handleDocumentKeyDown = () => {
    // select the sticky note when the user hover over it and then preses the shift key
    const isShiftKeyDown = isKeyDown("ShiftLeft") || isKeyDown("ShiftRight");
    if (!boardStore.activeCardId && isShiftKeyDown) {
      boardActions.toggleCardSelection("mouse", card.id);
    }
  };

  /**
   * Handle keyboard events on the sticky note
   * Escape: Deactivate sticky note
   * Enter: Activate sticky note (if not yet active)
   */
  const handleKeyDown = (e: KeyboardEvent) => {
    switch (e.key) {
      case "Enter":
        // Activate sticky (equivalent to clicking on it)
        if (e.target !== el.value) return;
        e.preventDefault();
        handleStickyNoteClick(e, true);
        break;
      case e.shiftKey && "Tab":
        if (isActive.value || !managedTabOrder.value) return;
        e.preventDefault();
        previousTabTarget.value?.focus();
        break;
      case "Tab":
        if (isActive.value || !managedTabOrder.value) return;
        e.preventDefault();
        nextTabTarget.value?.focus();
        break;
      case "Escape":
        deactivateSticky();
        break;
    }
  };

  /**
   * When the sticky note is focused, deactivate the previous sticky
   * so that its action menu closes
   */
  const handleFocusIn = () => {
    const lastCard = useBoardStore().activeCardId;
    const isMultiSelecting =
      useSelectionStore().selecting !== "no" ||
      isKeyDown("MetaLeft") ||
      isKeyDown("MetaRight");

    if (lastCard && lastCard !== card?.id && !isMultiSelecting) {
      useBoardStore().setActiveCardId(null);
      useBoardStore().unselectCard(lastCard);
      // TODO: If the user moves to a different sticky with Shift+Tab, the previous
      // sticky stays selected. This will be fixed when we implement tab-nav on stickies
    }

    // Calculate the next and previous stickies for tab navigation
    updateTabNavTargets();
  };

  /**
   * Deactivates the sticky note in two steps
   * 1. (if active) Deselects sticky and closes open menu(s)
   *    (calls stopAlter to persist changes)
   * 2. Blurs the sticky note (to remove focus ring)
   */
  const deactivateSticky = () => {
    if (isActive.value) {
      useBoardStore().unselectCard(card?.id);
      useBoardStore().setActiveCardId(null);
      useBoardStore().setEnlargedStickyNoteId(null);
      document.removeEventListener("pointerdown", handleOutsideClick, true);

      cardActions.stopAlter("internal", card?.id);

      if (wasActivatedByKeyboard.value) {
        el.value?.focus();
        wasActivatedByKeyboard.value = false;
      }
    } else {
      el.value?.blur();
    }
  };

  /**
   * Determine the next and previous stickies (within the board section) for tab navigation
   * (tab order doesn't loop, instead it focuses on the section's 'cards-skip-button' button at
   * the beginning and end of the section)
   */
  const updateTabNavTargets = () => {
    if (!board.value || !cardMeta || !managedTabOrder.value) return;

    const location = useBoardStore().boardLocation(cardMeta.pos);

    const cards = cardsInLocation(board.value, location);
    const sortedCards = cards.sort(byPosition);

    const currentCardIndex = sortedCards.findIndex(
      (c) => c.data.id === card.id,
    );

    const nextTargetId =
      sortedCards.length > currentCardIndex + 1
        ? sortedCards[currentCardIndex + 1].data.id
        : skipButtonId(location);

    const prevTargetId =
      currentCardIndex > 0
        ? sortedCards[currentCardIndex - 1].data.id
        : skipButtonId(location);

    nextTabTarget.value =
      document.getElementById(nextTargetId) || document.querySelector(".board");
    previousTabTarget.value =
      document.getElementById(prevTargetId) || document.querySelector(".board");
  };

  const tabindex = managedTabOrder.value ? -1 : 0;

  return { isSelected, deactivateSticky, tabindex, wasActivatedByKeyboard };
}
