<script setup lang="ts">
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import { onMounted, ref } from "vue";
import { TippyOptions, Tippy as VueTippy } from "vue-tippy";

import { tooltipDelayToAppear } from "./baseTooltip";

interface TooltipProps {
  position?: TippyOptions["placement"];
  appendTo?: TippyOptions["appendTo"];
  showOnCreate?: boolean;
  interactive?: boolean;
  delay?: number | [number, number];
  duration?: number | [number, number];
  light?: boolean;
  offset?: [number, number];
  triggerRole?: string;
}

const props = withDefaults(defineProps<TooltipProps>(), {
  appendTo: () => document.body,
  interactive: false,
  delay: () => [tooltipDelayToAppear, 50],
  duration: 150,
  showOnCreate: false,
  position: "top",
  light: false,
  offset: () => [0, 10],
  triggerRole: undefined,
});

const tooltipRef = ref<typeof VueTippy | null>(null);

onMounted(() => {
  // Apply a role to the tooltip's "trigger" element if one is given
  // (a 'role' passed to VueTippy is given to the tooltip itself)
  if (tooltipRef.value?.elem && props.triggerRole) {
    tooltipRef.value.elem.setAttribute("role", props.triggerRole);
  }
});
</script>

<template>
  <VueTippy
    ref="tooltipRef"
    tag="span"
    data-testid="tooltip"
    :theme="light ? 'light' : undefined"
    :placement="props.position"
    :delay="props.delay"
    :duration="props.duration"
    :interactive="props.interactive"
    :append-to="props.appendTo"
    :offset="props.offset"
    :show-on-create="props.showOnCreate"
  >
    <template #default>
      <slot></slot>
    </template>
    <template v-if="$slots.content" #content>
      <slot name="content"></slot>
    </template>
  </VueTippy>
</template>
