<script setup lang="ts">
import { isFunction } from "lodash-es";
import { computed } from "vue";

import { Action } from "@/action/actions";
import { boardActions } from "@/action/boardActions";
import { cardActions } from "@/action/cardActions";
import { selectionActions } from "@/action/selectionActions";
import { toggleActions } from "@/action/toggleActions";
import FluidBoard, { ContextActions } from "@/components/board/FluidBoard";
import SubMenu from "@/components/ui/DropdownMenu/SubMenu.vue";
import ListItem from "@/components/ui/ListItem/ListItem.vue";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { keyboardNavigation } from "@/directives/keyboardNavigation";
import { windowToRelative } from "@/math/coordinate-systems";
import { ExecutionMode } from "@/model/baseTypes";
import { WindowCoordinate } from "@/model/coordinates";
import { useBoardStore } from "@/store/board";
import { useUserStore } from "@/store/user";

export type BoardContextMenuMode = "iteration" | "backlog" | "mouse";

const props = defineProps<{
  board: FluidBoard;
  position: WindowCoordinate;
  mode: BoardContextMenuMode;
}>();

defineEmits<{ dummy: [] }>();

const vKeyboardNavSub = keyboardNavigation();

const selectedCount = computed(() => useBoardStore().selectedStickyIds.length);
const editable = computed(() => useUserStore().isAllowed("edit"));
const relativePos = computed(() => windowToRelative(props.position));
const context = computed(() => props.board.contextActions(relativePos.value));
const general = computed(() => ({
  newSticky: props.mode === "mouse" && editable.value,
  screenshot: props.mode === "mouse" || props.mode === "backlog",
  syncBacklog: editable.value && context.value.syncProgramBacklog,
  draw: editable.value && context.value.draw,
}));
const anyGeneral = computed(() => Object.values(general).some((value) => value));

function newSticky() {
  cardActions.add("contextMenu", { pos: props.position }, true, "right-click-on-board");
}

function screenshot() {
  boardActions.screenshot("contextMenu");
}

function execute(action: keyof ContextActions | Action<[ExecutionMode]>) {
  if (isFunction(action)) {
    action("contextMenu", "normal");
  } else {
    props.board.doContextAction(action, relativePos.value);
  }
}

const boardEdit = computed(() => useUserStore().isAllowed("editBoard"));
</script>

<template>
  <div v-keyboard-nav style="width: 300px">
    <template v-if="anyGeneral">
      <ListItem static class="title">
        {{ $t("contextMenu.general") }}
      </ListItem>
      <ListItem v-if="general.newSticky" @click="newSticky">
        <span>{{ $t("contextMenu.createNew") }}</span>
        <span class="btn">{{ cardActions.add.data.shortcut?.name() }}</span>
      </ListItem>
      <ListItem v-if="general.screenshot" @click="screenshot">
        <span>{{ $t("contextMenu.saveBoardImage") }}</span>
        <span class="btn">
          {{ boardActions.screenshot.data.shortcut?.name() }}
        </span>
      </ListItem>
      <ListItem v-if="general.syncBacklog" @click="execute('syncProgramBacklog')">
        {{ $t("contextMenu.synchronizeBacklog") }}
      </ListItem>
      <ListItem v-if="boardEdit && general.draw" @click="toggleActions.showDraw('contextMenu')">
        {{ $t("contextMenu.drawModeOn") }}
      </ListItem>
    </template>

    <template v-if="editable && selectedCount > 1 && context.selection">
      <SubMenu :offset="{ left: 250, top: -35 }" style="width: 100%">
        <template #trigger>
          <ListItem class="title">
            {{ $t("contextMenu.selectedStickyCount", { selectedCount }) }}
            <SvgIcon name="arrow/right" width="20" />
          </ListItem>
        </template>
        <div v-keyboard-nav-sub>
          <ListItem v-if="context.selection.stickyMove" @click="execute(selectionActions.alignHorizontal)">
            {{ $t(selectionActions.alignHorizontal.data.name) }}
          </ListItem>
          <ListItem v-if="context.selection.stickyMove" @click="execute(selectionActions.distributeHorizontal)">
            {{ $t(selectionActions.distributeHorizontal.data.name) }}
          </ListItem>
          <ListItem v-if="context.selection.stickyMove" @click="execute(selectionActions.alignVertical)">
            {{ $t(selectionActions.alignVertical.data.name) }}
          </ListItem>
          <ListItem v-if="context.selection.stickyMove" @click="execute(selectionActions.distributeVertical)">
            {{ $t(selectionActions.distributeVertical.data.name) }}
          </ListItem>
          <ListItem v-if="context.selection.stickyMove" @click="execute(selectionActions.lineup)">
            {{ $t(selectionActions.lineup.data.name) }}
          </ListItem>
          <ListItem v-if="context.selection.link" @click="execute(selectionActions.link)">
            {{ $t(selectionActions.link.data.name) }}
          </ListItem>
        </div>
      </SubMenu>
    </template>

    <template v-if="context.region">
      <ListItem static class="title">
        {{ context.region.name }}
      </ListItem>
      <ListItem v-if="context.region.overview" @click="execute('overview')">
        <span>{{ $t(toggleActions.showOverview.data.name) }}</span>
        <span class="btn">
          {{ toggleActions.showOverview.data.shortcut?.name() }}
        </span>
      </ListItem>
      <ListItem v-if="context.region.zoom" @click="execute('zoom')">
        {{ $t("contextMenu.zoomToRegion") }}
      </ListItem>
      <ListItem v-if="editable && context.region.sync" @click="execute('sync')">
        {{ $t("contextMenu.synchronizeIteration") }}
      </ListItem>
      <ListItem v-if="editable && context.region.arrange" @click="execute('arrange')">
        {{ $t("boardBase.arrangeStickies") }}
      </ListItem>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/context-menu" as *;
</style>
