<script lang="ts" setup>
import { computed } from "vue";

import { cardKey } from "@/components/card/injectKeys";
import StickyLinkModal from "@/components/modal/stickyLink/StickyLinkModal.vue";
import StickyLinkModalNg from "@/components/modal/stickyLinkNg/StickyLinkModal.vue";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { currentRoute } from "@/router/navigation";
import { useLinkStore } from "@/store/link";
import { useModalStore } from "@/store/modal";
import { isFeatureEnabled } from "@/utils/env/feature";
import { injectStrict } from "@/utils/vue";

import AttributeChip from "../../../AttributeChip/AttributeChip.vue";
import StickyLinks from "../StickyLinks/StickyLinks.vue";

const card = injectStrict(cardKey);

const links = computed(() => useLinkStore().getLinkableItems(card));

const showNgStickyLinkModal = computed(() =>
  isFeatureEnabled(currentRoute(), "sticky-link"),
);

const handleAddLinks = () => {
  return useModalStore().open(
    showNgStickyLinkModal.value ? StickyLinkModalNg : StickyLinkModal,
    {
      attrs: {
        cardIds: [card.id],
        "data-ignore-click": true,
        setReturnFocus: "#sticky-links-chip-trigger-" + card.id,
      },
    },
  );
};
</script>

<template>
  <AttributeChip
    :id="'sticky-links-chip-trigger-' + card.id"
    name="links-chip"
    :dropdown-props="{ width: links.length ? 'xlg' : 'md', maxHeight: 'lg' }"
    text-placeholder="0"
    :text="links.length ? links.length.toString() : undefined"
    :tooltip="$t('general.links')"
  >
    <template #icon>
      <SvgIcon name="thin/link" />
    </template>
    <StickyLinks
      :target-card-ids="[card.id]"
      :links="links"
      @add="handleAddLinks"
    />
  </AttributeChip>
</template>
