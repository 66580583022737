import CardFlag from "./CardFlag";
import { AlmSourceId, Color, RiskType } from "./baseTypes";
import { Board } from "./board";
import { RelativeCoordinate } from "./coordinates";
import { Link } from "./link";
import { MarkMode } from "./markMode";
import { LinkedObjective } from "./objective";
import { DependencyTeamFilter } from "./search";
import { Iteration, Team } from "./session";
import { AlmItemStatus, Status } from "./status";
import { StickyType } from "./stickyType";
import { AuthUser } from "./user";

export interface BoardCard {
  data: Card;
  meta: CardMeta;
}

export interface CardMeta {
  pos: RelativeCoordinate;
  zIndex: number;
  mark: MarkMode;
  editing: boolean;
  dragging: boolean;
  isHighlighted: boolean;
  isRelatedToHighlighted: boolean;
  shouldAnimate?: boolean;
  dependencyTeamFilter: DependencyTeamFilter | null;
}

/**
 * Model that represents a card component on the UI.
 */
export interface Card {
  id: string;
  groupId?: string | null;
  almId?: string; // the ID of the external ALM item
  almIssueUrl?: string;
  almSourceId: AlmSourceId | null;
  text: string;
  points: number;
  priority: number;
  teamId: string | null;
  precondTeam?: Team; // team that created the sticky
  dependTeam?: Team; // team that the sticky depends on
  iterationId: number | null;
  editor: AuthUser | null;
  links: Link[];
  type: StickyType;
  flagType: CardFlag;
  risk?: RiskType;
  status?: AlmItemStatus;
  transition?: string;
  objectives: LinkedObjective[];
  reactions?: Reactions;
  artId: string | null;
  assignee: AuthUser | null;
  reporter: AuthUser | null;
} //TODO subtypes depending on type.functionality

export interface BaseStickyInfo {
  id: string;
  text: string;
  type: StickyType;
  flag: CardFlag;
  iteration?: Iteration;
  team?: Team;
  artId?: string;
  almId?: string;
  status?: Status;
  points?: number;
  precondTeam?: Team;
  dependTeam?: Team;
}

export type Reactions = { [reaction in Reaction]: AuthUser[] };

export const reactions = ["hot", "love", "increment"] as const;

export type Reaction = (typeof reactions)[number];

const reactionLabels: { [key in Reaction]: string } = {
  hot: /*$t*/ "label.reaction.hot",
  increment: /*$t*/ "label.reaction.increment",
  love: /*$t*/ "label.reaction.love",
};

export function getReactionLabel(reaction: Reaction) {
  return reactionLabels[reaction];
}

/**
 * Get the color of a card based on the board type and the card's teamId.
 * On the backlog board, show the 'taken' color (altColor) if the card is assigned to a team.
 * On the solution backlog board, show the 'taken' color (altColor) if the card is assigned to an art.
 * Otherwise, show the default color (color).
 *
 * @returns The color of the card.
 */
export function cardColor(card: Card, board: Board): Color {
  if (board.type === "backlog" && card.type.origin === "backlog") {
    return card.teamId ? card.type.altColor : card.type.color;
  }

  if (
    board.type === "solution_backlog" &&
    card.type.origin === "solution_backlog"
  ) {
    return card.artId ? card.type.altColor : card.type.color;
  }

  return card.type.color;
}
