<script setup lang="ts">
import { type ComputedRef, computed, nextTick, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

import ComboBox from "@/components/ComboBox/ComboBox.vue";
import BaseListItem from "@/components/ui/BaseList/components/BaseListItem/BaseListItem.vue";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import type { Art, Team } from "@/model/session";
import { useArtStore } from "@/store/art";
import { useTeamStore } from "@/store/team";
import { i18n } from "@/translations/i18n";

const { t } = useI18n();

interface Props {
  selected?: Team;
  title?: string;
  showCurrentTeam?: boolean;
  showClear?: boolean;
  showOtherArts?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  title: i18n.global.t("action.team"),
  selected: undefined,
  showCurrentTeam: false,
  showClear: false,
  showOtherArts: false,
});

const emit = defineEmits<{ select: [Team | null] }>();

const showingArts = ref<boolean>(false);
const selectedArt = ref<Art | null>(null);
const searchVal = ref<string>("");
const artListItemRefs = ref<HTMLElement[]>([]);
const otherArtsButton = ref<HTMLElement | null>(null);

// Teams in the current ART (potentially excluding the current team)
const teamsInCurrentArt: ComputedRef<Team[]> = computed(() => {
  const currentTeam = useTeamStore().currentTeam;
  return useTeamStore()
    .teamsInArt(selectedArt.value?.id || "")
    .filter((team) => props.showCurrentTeam || team !== currentTeam);
});

// Teams to show (current list, filtered by the current search if applicable)
const teamsList = computed(() => filterBySearch(teamsInCurrentArt.value));

// All ARTs
const arts: ComputedRef<Art[]> = computed(() => useArtStore().arts || []);

// ARTs to show (filtered by current search if applicable)
const artsList = computed(() => filterBySearch(arts.value));

// Show the 'Other ARTs' button when there is >1 ART
const showArtNav = computed(() => props.showOtherArts && arts.value.length > 1);

// Search input placeholder depends on the context (Teams/ARTs)
const searchPlaceholder = computed(() => {
  return showArtNav.value && showingArts.value
    ? t("action.searchArts")
    : t("action.searchTeams");
});

// By default, show teams for the current ART
onMounted(() => (selectedArt.value = useArtStore().currentArt));

/**
 * Emit the selected team
 */
const selectTeam = (team: Team | null) => emit("select", team);

/**
 * Show teams for the selected ART
 */
const selectArt = async (art: Art) => {
  selectedArt.value = art;
  showingArts.value = false;
  searchVal.value = "";

  await nextTick();
  otherArtsButton.value?.focus();
};

/**
 * Show all ARTs the user can choose from
 */
const showArts = async () => {
  showingArts.value = true;
  searchVal.value = "";

  await nextTick();
  artListItemRefs.value[0]?.focus();
};

/**
 * Filter the given list by the search input (partial match on 'name' property)
 * @param list List of Teams or ARTs
 */
const filterBySearch = (list: Array<Team | Art>) => {
  const input = searchVal.value?.toLowerCase();

  return input
    ? list.filter((item) => item.name.toLowerCase().includes(input))
    : list;
};
</script>

<template>
  <ComboBox
    v-model="searchVal"
    :title="title"
    :placeholder="searchPlaceholder"
    :icon-title="$t('label.teamSelector.searchTitle')"
    :list-len="teamsList?.length"
  >
    <template v-if="!showingArts">
      <BaseListItem
        v-if="showArtNav"
        ref="otherArtsButton"
        class="list-item"
        @click.stop="showArts()"
      >
        <template #before>
          <SvgIcon name="arrow/left" width="20" height="20" />
        </template>
        {{ $t("programBoard.otherArts") }}
      </BaseListItem>

      <!-- Teams -->
      <BaseListItem
        v-if="showClear && selected"
        class="list-item"
        @click.stop="selectTeam(null)"
      >
        <template #before>
          <div style="width: 20px"></div>
        </template>
        {{ $t("general.noTeam") }}
      </BaseListItem>

      <BaseListItem
        v-for="team in teamsList"
        :key="team.id"
        class="list-item"
        :active="team.id === selected?.id"
        @click.stop="selectTeam(team)"
      >
        <template #before>
          <SvgIcon name="action-menu/team" width="20" height="20" />
        </template>
        {{ team.name }}
      </BaseListItem>
    </template>

    <!-- List of ARTs -->
    <template v-else>
      <BaseListItem
        v-for="art in artsList"
        :key="art.id"
        ref="artListItemRefs"
        class="list-item"
        @click.stop="selectArt(art)"
      >
        <template #before>
          <SvgIcon name="action-menu/art" width="20" height="20" />
        </template>
        {{ art.name }}
        <template #after>
          <SvgIcon name="arrow/right" width="20" height="20" />
        </template>
      </BaseListItem>
    </template>
  </ComboBox>
</template>
