<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import BaseTooltip from "@/components/ui/BaseTooltip/BaseTooltip.vue";
import FlashIcon from "@/components/ui/FlashIcon/FlashIcon.vue";
import { useEventBus } from "@/composables/useEventBus";
import { replaceQuery } from "@/router/navigation";
import type { SearchQuery } from "@/router/types";
import { useLinkStore } from "@/store/link";
import { useSearchMenuStore } from "@/store/searchMenu";

const active = ref(false);
const { onSearch } = useEventBus();

onMounted(() => {
  onSearch((query: SearchQuery) => (active.value = !!query.link));
});

const { t } = useI18n();

const show = computed(() => useLinkStore().hasRiskyLinks);
const linkTypeCount = computed(() => useLinkStore().riskyLinkCount);
const riskyLinkTooltipText = computed(() =>
  linkTypeCount.value.critical > 0 && linkTypeCount.value.risky === 0
    ? t("linkState.criticalLinks")
    : t("linkState.riskyLinks"),
);

function showRiskyLinks() {
  const criticalLink = linkTypeCount.value.critical ? "error" : "";
  const warnLink = linkTypeCount.value.risky ? "warn" : "";
  const riskyLinks = [criticalLink, warnLink].filter(Boolean).join(",");

  void replaceQuery({ search: { link: riskyLinks } });
  useSearchMenuStore().isSearchSidebarOpen = true;
}
</script>

<template>
  <BaseTooltip position="bottom" class="tooltip">
    <BaseButton
      v-show="show"
      id="risky-links"
      data-no-screenshot
      variant="ghost"
      :activated="active"
      :aria-label="riskyLinkTooltipText"
      @click="showRiskyLinks"
    >
      <FlashIcon
        v-if="linkTypeCount.critical"
        link-state="error"
        data-testid="critical-link-icon"
      />
      <FlashIcon
        v-if="linkTypeCount.risky"
        link-state="warn"
        data-testid="risky-link-icon"
      />
    </BaseButton>
    <template #content>
      {{ riskyLinkTooltipText }}
    </template>
  </BaseTooltip>
</template>

<style lang="scss">
@use "@/styles/variables";

#risky-links {
  display: flex;
  align-items: center;
  padding: 8px 10px;
  background-color: transparent;
  height: 40px;
  border-radius: variables.$common-border-radius;

  .tooltip {
    display: flex;
    align-items: center;
    height: 40px;
  }

  &:not(.active):hover {
    background-color: var(--light-background-color);
  }

  img {
    margin-right: 5px;
  }
}
</style>
