import { mapUser, mapUserTeams } from "@/backend/mapper/mapBackendData";
import { ServerAuthUser, ServerUserTeam } from "@/backend/serverModel";
import { MINUTES } from "@/model/Settings";
import { Team } from "@/model/session";
import { AuthUser } from "@/model/user";
import { SimpleCache } from "@/utils/SimpleCache";
import * as Environment from "@/utils/env/Environment";

import { createApiClient, modernInterceptor } from "./api.config";
import { addAlmMapping } from "./userAlmMapping.service";

const emptyListApi = modernInterceptor(
  createApiClient(Environment.authAPIUrl),
  { defaultValue: { results: [] } },
);

export type UserWithTeams = AuthUser & { teams: Team[] };

export async function searchUsers(query: string): Promise<UserWithTeams[]> {
  const res = await emptyListApi.get("/v1/users/search", { params: { query } });
  return await Promise.all(
    res.data.map((user: ServerAuthUser & { teams: ServerUserTeam[] }) =>
      addAlmMapping(mapUser(user), mapUserTeams(user.teams)),
    ),
  );
}

const teamUsers = new SimpleCache<AuthUser[]>({ timeout: 60 * MINUTES });

export async function usersInTeam(team: Team): Promise<AuthUser[]> {
  return await teamUsers.loadItem(team.id, () => getUsersInTeam(team));
}

async function getUsersInTeam(team: Team): Promise<AuthUser[]> {
  const res = await emptyListApi.get(`/teams/${team.id}/users`);
  return await Promise.all(
    res.data.results.map((user: ServerAuthUser) =>
      addAlmMapping(mapUser(user)),
    ),
  );
}

export async function getUserTeams(): Promise<Team[]> {
  const res = await emptyListApi.get("/v1/users/teams");
  return mapUserTeams(res.data.results);
}
