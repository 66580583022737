<script setup lang="ts">
import { computed, watch } from "vue";

import DropdownMenu from "@/components/ui/DropdownMenu/DropdownMenu.vue";
import DropdownTrigger from "@/components/ui/DropdownMenu/DropdownTrigger.vue";
import SelectNamedValue from "@/components/ui/SelectNamedValue/SelectNamedValue.vue";
import { DependencyTeamFilter, DependencyTeamItem } from "@/model/search";
import { useSearchMenuStore } from "@/store/searchMenu";

import { trackFilterOpen } from "../utils";

const store = useSearchMenuStore();

watch(
  () => store.dependencyFilterItems,
  (filters: DependencyTeamItem[]) => {
    if (
      !selection.value ||
      !filters.find((item) => item.id === selection.value.id)
    ) {
      selection.value = filters[0];
    }
  },
);

const selection = computed({
  get: () => store.selectedDependencyFilterItem,
  set: (value: { id: DependencyTeamFilter; name: string }) => {
    store.selectedDependencyTeamFilter = value.id;
  },
});
</script>

<template>
  <DropdownMenu
    class="menu-dropdown"
    width="sm"
    stay-open-on-content-click
    @open="trackFilterOpen('dependency-team')"
  >
    <template #trigger>
      <DropdownTrigger :value="selection.name" />
    </template>
    <SelectNamedValue
      v-model="selection"
      :values="store.dependencyFilterItems"
    />
  </DropdownMenu>
</template>

<style lang="scss" scoped>
.menu-dropdown {
  margin: 10px;

  .dropdown-trigger {
    width: 200px;
  }
}
</style>
