import { AxiosError } from "axios";

import { CancelError } from "@/error/CancelError";
import { createApiClient } from "@/services/api.config";
import { useStatisticsStore } from "@/store/statistics";
import { getConfig } from "@/utils/env/Environment";

import { BackendError } from "./BackendError";
import { RpcBackend } from "./RpcBackend";
import { translateRPCPath } from "./natsTopicMapper";
import { createCallContext, createCallHeaders } from "./requestHelper";
import { handleResponse } from "./responseHelper";

export class HttpRpcBackend implements RpcBackend {
  private sessionId!: string;
  private apiClient = createApiClient(getConfig().piserverAPIUrl);

  init(sessionId: string) {
    this.sessionId = sessionId;
  }

  async doCall<T>(
    path: string,
    args: unknown[] = [],
    kwargs?: object,
    timeout?: number,
  ): Promise<T> {
    const context = createCallContext(path, args, kwargs, timeout, 0);
    try {
      const res = await this.apiClient.get(
        `ch/rentouch/${path}/${args.join("/")}`,
        {
          params: kwargs,
          headers: createCallHeaders(context),
          timeout: context.requestTimeout,
        },
      );
      useStatisticsStore().current.calls.addOk();
      return handleResponse(res.data, context, path) as T;
      // FIXME: Simply casting the response is not a good idea because we are
      // basically removing all advantages of Typescript on the calling
      // functions.
      //
      // To conform to the signature of BackendSession we are still casting here,
      // but this should be changed to a parsing strategy where the format is
      // validated before being converted.
    } catch (err) {
      useStatisticsStore().current.calls.addFail(err);
      if (err instanceof CancelError) {
        throw err;
      }
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_BAD_RESPONSE && err.response?.data) {
          return handleResponse(err.response.data, context, path) as T;
        }
      }
      const message = (err as any).message || err;
      throw new BackendError(
        message,
        `HTTP Error while calling RPC method ${path}`,
        { request: context, response: { message } },
      );
    }
  }

  @translateRPCPath
  async sessionCall<T>(
    path: string,
    args: unknown[] = [],
    kwargs?: Record<string, unknown>,
  ) {
    const fullPath = `piplanning/6/room/session/${path}`;
    return await this.doCall<T>(fullPath, [this.sessionId, ...args], kwargs);
  }

  @translateRPCPath
  async boardCall<T>(
    path: string,
    args: unknown[],
    kwargs?: Record<string, unknown>,
  ) {
    const fullPath = `piplanning/6/room/board/${path}`;
    return await this.doCall<T>(fullPath, args, kwargs);
  }

  @translateRPCPath
  async flexBoardCall<T>(
    path: string,
    args: unknown[] = [],
    kwargs?: Record<string, unknown>,
  ) {
    const fullPath = `piplanning/6/room/session/${path}`;
    return await this.doCall<T>(fullPath, args, kwargs);
  }

  @translateRPCPath
  async roomCall<T>(path: string, args: unknown[] = []) {
    return await this.doCall<T>(`piplanning/6/room/${path}`, args);
  }

  @translateRPCPath
  async adminCall<T>(path: string, args: unknown[] = []) {
    return await this.doCall<T>(`piplanning/6/room/${path}`, args);
  }

  @translateRPCPath
  async almCall<T>(path: string, args: unknown[] = []) {
    return await this.doCall<T>(`piplanning/6/room/${path}`, args);
  }

  @translateRPCPath
  almToolCall<T>(
    _path: string,
    _args: unknown[],
    _timeout?: number,
  ): Promise<T> {
    throw new Error("unsupported http call to almsync");
  }

  @translateRPCPath
  async staticSessionCall<T>(
    path: string,
    args: unknown[] = [],
    kwargs?: Record<string, unknown>,
  ) {
    const fullPath = `piplanning/6/room/session/${path}`;
    return await this.doCall<T>(fullPath, args, kwargs);
  }

  @translateRPCPath
  async serverCall<T>(path: string) {
    return await this.doCall<T>(`piplanning/6/room/server/${path}`);
  }

  @translateRPCPath
  async statusCall<T>(path: string, args: unknown[] = []) {
    return await this.doCall<T>(`piplanning/6/room/status/${path}`, args);
  }

  userCall<T>(_path: string): Promise<T> {
    throw new Error("unsupported http call to almsync");
  }
}
