<script setup lang="ts">
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { FieldChange } from "@/model/change";
import { useTeamStore } from "@/store/team";

defineProps<{ field: FieldChange<string> }>();

function teamName(id?: string) {
  return id ? useTeamStore().findTeam({ id })!.name : "";
}
</script>

<template>
  <div class="change horizontal">
    <template v-if="field.old">
      <span class="change-value">
        <SvgIcon name="action-menu/team" width="20" height="20" />
        {{ teamName(field.old) }}
      </span>
      <SvgIcon
        name="arrow/right-thin"
        width="20"
        height="20"
        role="img"
        :aria-label="$t('label.activityPanel.changedTo')"
      />
    </template>
    <span class="change-value">
      <SvgIcon name="action-menu/team" width="20" height="20" />
      {{ teamName(field.new) }}
    </span>
  </div>
</template>
